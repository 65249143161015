import React, { useEffect, useState } from 'react';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import Button from '../components/buttons/Button';
import { IoIosArrowForward, IoIosShareAlt } from 'react-icons/io';
import { LuDownload } from 'react-icons/lu';
import { AiFillPicture } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { FaShower } from 'react-icons/fa';
import { IoBedSharp } from 'react-icons/io5';
import { PiCurrencyDollarBold } from 'react-icons/pi';
import { BiChevronRight } from 'react-icons/bi';
import TypeTextAnimation from '../components/animation/TypeAnimationText';
import AnimationDiv, { slideBottom, slideLeft } from '../components/animation/AnimationDiv';
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs';
import Footer from '../components/ui/sections/FooterSection/Footer';
import { Link, useParams } from 'react-router-dom';
import { getSinglePreConstruction } from '../api/GET';
import { Helmet } from 'react-helmet';


const soldData = [
    { id: 6, residence: '181 East 101st Street PH9', beds: 3, baths: 3, halfBaths: 0, price: 1295000, sqft: 972, status: 'SOLD' },
    { id: 7, residence: '181 E 101st Street Unit: 202', beds: 1, baths: 1, halfBaths: 0, price: 949000, sqft: 842, status: 'SOLD' },
    { id: 8, residence: '181 East 101st Street Unit: 803', beds: 2, baths: 2, halfBaths: 0, price: 949000, sqft: 726, status: 'SOLD' },
    { id: 9, residence: '181 East 101st Street PH1', beds: 4, baths: 4, halfBaths: 0, price: 899000, sqft: 650, status: 'SOLD' },
    { id: 10, residence: '181 East 101st Street 802', beds: 1, baths: 1, halfBaths: 0, price: 649000, sqft: 483, status: 'SOLD' },
];

const ResidenceTable = ({ title, data }) => (
    <div className="my-8 creato-f-b  text-theme_blue">
        <h2 className="text-3xl lg:text-[1.6vw] creato-f-b  mb-4">{title}</h2>
        <table className="w-full border-collapse lg:table hidden lg:text-[.9vw] ">
            <thead>
                <tr className="text-left  text-gray-600 border-b">
                    <th className="py-2 font-semibold">RESIDENCE</th>
                    <th className="py-2 font-semibold">BEDS</th>
                    <th className="py-2 font-semibold">BATHS</th>
                    <th className="py-2 font-semibold">PRICE</th>
                    <th className="py-2 font-semibold">SQFT</th>
                    <th className="py-2 font-semibold">STATUS</th>
                    <th className="py-2"></th>
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 && data.map((item) => (
                    <tr key={item.id} className="border-b last:border-b-0 hover:bg-gray-50 cursor-pointer ">
                        <td className="py-4 pl-2 ">{item?.street_number} {item?.street_name}, {item?.city}, {item?.state}</td>
                        <td className="py-4">{item?.bedrooms}</td>
                        <td className="py-4">{item?.bedrooms}</td>
                        <td className="py-4">${Number(item?.original_list_price).toLocaleString()}</td>
                        <td className="py-4">{item?.living_area} sqft</td>
                        <td className="py-4">
                            <span className={`px-2 py-1 text-xs font-semibold rounded ${item.standard_status === 'Active' ? 'bg-theme_blue text-white' : 'bg-gray-300 text-white'
                                }`}>
                                {item.standard_status}
                            </span>
                        </td>
                        <td className="py-4">
                            <BiChevronRight className="text-gray-400" />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="mb-8">
            <table className="w-full border-collapse text-theme_blue text-[12px] lg:hidden">
                <thead>
                    <tr className="lg:text-left text-sm text-center  border-b ">
                        <th className="py-2 font-semibold">RESIDENCE</th>
                        <th className="py-2 font-semibold">BEDS\BATHS</th>
                        <th className="py-2 font-semibold">PRICE\SQFT</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id} className="border-b last:border-b-0 cursor-pointer">
                            <td className="py-4 text-center  text-navy-700">{item?.street_number} {item?.street_name}, {item?.city}, {item?.state}</td>
                            <td className="py-4 text-center ">{`${item.bedrooms}\\${item.bedrooms}`}</td>
                            <td className="py-4 text-center ">{`$${Number(item?.original_list_price).toLocaleString()}\\${item.living_area} sqft`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

const SinglePreContruction = () => {
    const { id } = useParams();
    const [data, setdata] = useState({})
    const [availableProperties, setavailableProperties] = useState([])
    const [soldProperties, setsoldProperties] = useState([])
    const fetchData = async () => {
        const res = await getSinglePreConstruction(id)

        if (res?.data?.data) {

            setdata({ ...res?.data?.data, photos: JSON.parse(res?.data?.data?.photos), docs: JSON.parse(res?.data?.data?.docs), features: JSON.parse(res?.data?.data?.features) })
        }
    }
 
    useEffect(() => {
        fetchData()
    }, [])



    return (
        <>
 <Helmet>
                    <title>{`${data?.title || 'New Development'} - Luxlife Miami Real Estate LLC`}</title>
                </Helmet>
            <div className="relative w-full min-h-[500px]  md:h-[95vh] overflow-hidden">
                <div className='fixed top-0 z-[10000] w-full'>
                    <Header />
                </div>
                {/* Image with full width and object cover to maintain aspect ratio */}
                <img src={data?.photos ? `https://www.luxlifemiamirealestate.com/api/storage/app/public/${data?.photos[0]}` : sec5logo} alt="Hero Image" className=" w-full absolute h-full object-cover" />

                {/* Overlay with semi-transparent black background */}
                <div className="absolute inset-0 gradient-background "></div>

                {/* Centered text block */}
                <div className="absolute bottom-[10%] md:left-6 text-white px-4 md:px-8 w-full">
                    <div>
                        {/* Animated Heading */}
                        <p className="text-[6vw] md:text-[4vw]">
                            {data?.title}
                            <TypeTextAnimation text={[]} speed={75} />
                        </p>
                        {/* Animated Address */}
                        <p className="text-[3vw] md:text-[2vw] lg:text-[1.2vw] creato-f-m tracking-wide mx-auto">
                            {data?.address}
                            {/* <TypeTextAnimation text={['1133 SW 2nd Ave, Miami, FL 33130']} speed={75} /> */}
                        </p>
                    </div>
                    <div className="flex justify-center mx-auto md:justify-end items-center mt-14 mr-[2%] gap-2 lg:gap-4">
                        {/* Buttons */}
                        <a href={data?.docs ? `https://www.luxlifemiamirealestate.com/api/storage/app/public/${data.docs[0]}` : "#"} target='_blank'>
                            <Button
                                title={
                                    <div className="flex items-center gap-2">
                                        <span><LuDownload className="text-[16px] md:text-[20px]" /></span>
                                        <div>
                                            <p className="text-[9px]">
                                                <TypeTextAnimation text={['Download']} speed={75} />
                                            </p>
                                            <p className="text-[8px] lg:text-[12px]">
                                                <TypeTextAnimation text={['Floor Plans']} speed={75} />
                                            </p>
                                        </div>
                                    </div>
                                }
                                className={'bg-white px-2 lg:px-4 text-theme_blue rounded-xl py-3 creato-f-m tracking-wide btn2'}

                            />
                        </a>

                        <a href={data.docs ? `https://www.luxlifemiamirealestate.com/api/storage/app/public/${data.docs[0]}` : "#"} target='_blank'>
                            <Button
                                title={
                                    <div className="flex items-center gap-2">
                                        <span><LuDownload className="text-[16px] md:text-[20px]" /></span>
                                        <div>

                                            <p className="text-[9px]">
                                                <TypeTextAnimation text={['Download']} speed={75} />
                                            </p>

                                            <p className="text-[8px] lg:text-[12px]">
                                                <TypeTextAnimation text={['Brochure']} speed={75} />
                                            </p>
                                        </div>
                                    </div>
                                }
                                className={'bg-white px-2 lg:px-4 text-theme_blue rounded-xl py-3 creato-f-m tracking-wide btn2'}
                            />
                        </a>
                        <div className="flex gap-2 lg:gap-4">
                            {/* Icons */}
                            <div className="p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center bg-white text-black">
                                <AiFillPicture />
                            </div>
                            <div className="p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center bg-white text-black">
                                <FaLocationDot />
                            </div>
                            <div className="p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center bg-white text-black">
                                <IoIosShareAlt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="  mx-auto  rounded-t-xl relative">
                <div className=' md:mt-[-4%] w-full rounded-t-[70px] bg-white p-4 md:p-12'>

                    <div className=" container lg:max-w-[75%] mx-auto p-6 ">
                        {/* Header with property details */}
                        <AnimationDiv variants={slideBottom}
                            initial="hidden"
                            animate="visible" className="flex flex-col md:flex-row justify-between md:items-center mb-6  rounded-lg  border  text-theme_blue">
                            <div className="flex justify-between flex-1 items-center space-x-4">
                                <div className="flex-1 py-4 border-r p-4 items-center">
                                    <IoBedSharp className='text-[30px] mb-1' />
                                    <span className="creato-f-black text-[3vw] md:text-[2vw] ">{data?.bed}</span>
                                    <p className='creato-f-b text-[14px]'>BEDS</p>
                                </div>
                                <div className="flex-1 py-4 border-r p-4">
                                    <FaShower className='text-[30px] mb-1' />
                                    <span className=" creato-f-black text-[3vw] md:text-[2vw] ">{data?.bath}</span>
                                    <p className='creato-f-b text-[14px]'>BATHS</p>
                                </div>
                                <div className="flex-1 py-4 border-r p-4">
                                    <HiOutlineBuildingOffice2 className='text-[30px] mb-1' />
                                    <span className=" creato-f-black text-[3vw] md:text-[2vw] ">{data?.residence ? data?.residence : '-'} </span>
                                    <p className='creato-f-b text-[14px]'>Residences</p>
                                </div>
                            </div>
                            <div className='flex-1 py-4 border-r p-4 border-t md:border-t-none '>
                                <PiCurrencyDollarBold className='text-[30px] mb-1' />
                                <span className="creato-f-black text-[3vw] md:text-[2vw] ">{data?.price_range}</span>
                                <p className="creato-f-b text-[14px]">Price Range</p>
                            </div>
                        </AnimationDiv>

                        {/* Main content */}
                        <div className="flex flex-col justify-center md:flex-row gap-6 lg:gap-16 mt-16 text-theme_blue">
                            {/* Left column with image and title */}
                            <AnimationDiv variants={slideLeft}
                                initial="hidden"
                                animate="visible" className="md:w-1/2 md:max-w-[25.5vw] ">
                                <h1 className="creato-f-black text-[2.4] md:text-[1.6vw] mb-6">Timeless design, inspired by Miami.</h1>
                                <img
                                    src={data.photos ? ` https://www.luxlifemiamirealestate.com/api/storage/app/public/${data?.photos[0]}` : ''}
                                    alt="Mercedes-Benz Places Building"
                                    className="w-full rounded-lg shadow-md object-cover md:max-w-[25.5vw] h-[600px] lg:h-[30vw]"
                                />
                            </AnimationDiv>

                            {/* Right column with description and features */}
                            <AnimationDiv variants={slideLeft}
                                initial="hidden"
                                animate="visible" className="md:w-1/2 pt- creato-f-m mb-8 md:text-[1vw]">
                                <p className="">
                                    {data?.description}
                                </p>

                                {/* <p className="mb-4 mt-8">
                                    Inquire at www.miami.mercedesbenzplaces.com
                                </p> */}

                                <h3 className=" text-[25px] my-6">Features</h3>
                                <ul className="list-disc   gap-12 flex-wrap  grid sm:grid-cols-2  mb-4" >
                                    {data?.features && data?.features.map(v =>
                                        <li key={v} className=' max-w-[280px] flex-1'>{v}</li>

                                    )}
                                </ul>
                             <br />

                                <Link to={data?.website} target='_blank'>
                                    <Button title={<div className='flex items-center gap-4'><span>Visit Building Website</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_yellow px-8 text-white rounded-full py-3 creato-f-m  tracking-wide '} />
                                </Link>
                            </AnimationDiv>
                        </div>
                    </div>


                </div>
                <div className='bg-[#FEE8E8] mt-10'>
                    <div className='max-w-[75%] px-4 py-10 mx-auto'>
                        {data?.relatedListings && data?.relatedListings
                .filter(item => item?.standard_status === "Active").length > 0 && <ResidenceTable title="Available Residences" data={data?.relatedListings
                .filter(item => item?.standard_status === "Active")} />}
                        {data?.relatedListings && data?.relatedListings
                .filter(item => item?.standard_status !== "Active").length > 0 && <ResidenceTable title="Sold or Leased Residences" data={data?.relatedListings
                .filter(item => item?.standard_status !== "Active")} />}

                        {/* <div className='flex mt-12 justify-center gap-4 '>

                            <div className="p-4 rounded-full text-white hover:scale-[1.1] transition duration-300 cursor-pointer  bg-theme_blue"><FaChevronLeft className='text-[18px]' /></div>
                            <div className="p-4 rounded-full text-white hover:scale-[1.1] transition duration-300 cursor-pointer bg-theme_blue"><FaChevronRight className='text-[18px]' /></div>

                        </div> */}
                    </div>
                </div>
            </div >
            <section>
                <ContactUs className="mt-0" />
            </section>
            <Footer />
        </>
    );
};

export default SinglePreContruction;
