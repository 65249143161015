import React from 'react';
import { FcGoogle } from 'react-icons/fc'; // Google icon
import { FaApple } from 'react-icons/fa'; // Apple icon
import { GrMail } from 'react-icons/gr';
import GoogleLoginButton from './SocialButton';
import { jwtDecode } from 'jwt-decode';
import { API_BASE_URL } from '../../config';
import { useData } from '../../context/DataContext';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

const SocialLogin = ({ title, setTitle, handleClose }) => {
    const { setKeyData } = useData()

    const handleLoginSuccess = (credential) => {
        console.log('Received credential:', credential);
    
        try {
    
            // Prepare form data
            const formData = new FormData();
            formData.append('token', credential);
    
            // Send POST request to the API
            axios.post(`${API_BASE_URL}social/login`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                console.log('API response:', response.data);
    
                // Save user data in localStorage
                localStorage.setItem('user_data', JSON.stringify(response?.data?.data));
    
                // Perform additional actions after a successful login
                toast.success('Logged in successfully');
                setKeyData('isLogin', response?.data?.data); // Update the state or context
                handleClose()
            })
            .catch((error) => {
                console.error('Error during API request:', error.response?.data);
                toast.error(error.response?.data?.data?.error || 'Login failed');
            });
        } catch (error) {
            console.error('Error decoding token:', error);
        }
    };

    const handleLoginError = (error) => {
        console.error('Login failed', error);
    };
    return (
        <div className="flex flex-col space-y-4 mb-4">
            {/* Google Sign In Button */}
            {(title === 'Sign up' || title === 'Sign in') && <div style={{ width: '100%' }}>
                <GoogleLoginButton onSuccess={handleLoginSuccess} onError={handleLoginError} customButton={<button type='button' className="flex items-center justify-center hover:border-theme_yellow group border border-gray-300 rounded-lg px-4 py-2 hover:bg-theme_yellow duration-300 transition">
                    <FcGoogle className="w-8 h-8 mr-3" />
                    <span className="text-gray-700 font-medium group-hover:text-white">{title} with Google</span>
                </button>} />


                {/* Apple {title} Button */}
                {/* <button type='button' className="flex items-center justify-center hover:border-theme_yellow group border border-gray-300 rounded-lg px-4 py-2 hover:bg-theme_yellow duration-300 transition">
                    <FaApple className="w-8 h-8 mr-3" />
                    <span className="text-gray-700 font-medium group-hover:text-white">{title} with Apple</span>
                </button> */}
                </div>
                }
            {title === 'Sign up' && <button type='button' onClick={() => setTitle('Sign up with email')} className="flex items-center justify-center hover:border-theme_yellow group border border-gray-300 rounded-lg px-4 py-2 hover:bg-theme_yellow duration-300 transition">
                <span className='w-7 h-7 rounded-full text-white bg-theme_yellow p-1 mr-3 flex justify-center items-center'>  <GrMail className=" " /></span>
                <span className="text-gray-700 font-medium group-hover:text-white">{title} with Email</span>
            </button>}
            {title === 'Sign in' && <div className="flex items-center my-2 h-5 gap-2 "><div className='border w-full'></div><p className='text-theme_grey2'>OR</p><div className='border w-full'></div></div>
            }
        </div>
    );
};

export default SocialLogin;
