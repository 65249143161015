import React from 'react'
import emoji from '../../../../assets/images/thinking-face-emoji-.png'
import AnimationDiv from '../../../animation/AnimationDiv'

const Section2 = () => {
    return (
        <div>
            <div className='flex py-4 md:pb-[24px] bg-theme_yellow '>

                <div className='flex mx-auto justify-center gap-4 items-center'>
                    <AnimationDiv
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >
                        <div className='flex gap-1 md:gap-4 items-center'>
                            <p className='md:text-[26px] lg:text-[2.6vw] text-white  creato-f-r'>What are you looking for?
                            </p>
                            <img src={emoji} className='w-10 lg:w-24 ' />
                        </div>

                    </AnimationDiv>
                </div>
            </div>
            <div className='flex py-4 my-4 sm:mt-[45px]  sm:mb-[26px] bg-white '>
                <div className='flex flex-col md:flex-row mx-auto w-[80%] justify-between  md:gap-8 md:items-center sm:text-center md:text-start creato-f-m sm:leading-[55px]'>
                    <h2 className='text-[25px] leading-[30px] sm:leading-[55px] md:text-[34px] lg:text-[45px] text-theme_blue max-w-[650px]'>Discover Your Dream Home with  Luxlife Properties

                    </h2>
                    <p className='text-theme_yellow poppins-regular text-[13px] sm:text-[20px] leading-[25px] mt-[18px]'>Browse Our Extensive Collection of Premium Real Estate Options
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Section2