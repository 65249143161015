import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}


export const cityPolygons = {
    "Coral Gables": [
        [-80.3013, 25.637356],
        [-80.301267, 25.636341],
        [-80.3012454, 25.6355454],
        [-80.3012154, 25.6346868],
        [-80.3012044, 25.6342668],
        [-80.301191, 25.634156],
        [-80.301057, 25.634158],
        [-80.297842, 25.634162],
        [-80.297847, 25.633649],
        [-80.29303, 25.633674],
        [-80.292928, 25.63147],
        [-80.292888, 25.630602],
        [-80.292886, 25.630423],
        [-80.292884, 25.630196],
        [-80.29274, 25.630178],
        [-80.2925, 25.630193],
        [-80.291777, 25.630213],
        [-80.291738, 25.630209],
        [-80.291676, 25.630215],
        [-80.291607, 25.630215],
        [-80.29158, 25.63021],
        [-80.291567, 25.630094],
        [-80.291532, 25.630073],
        [-80.290872, 25.630107],
        [-80.290654, 25.630118],
        [-80.290327, 25.630125],
        [-80.289215, 25.630173],
        [-80.289186, 25.630206],
        [-80.289102, 25.630262],
        [-80.289045, 25.630261],
        [-80.289024, 25.630256],
        [-80.289023, 25.630196],
        [-80.288944, 25.630203],
        [-80.288235, 25.63022],
        [-80.287982, 25.630182],
        [-80.286849, 25.630204],
        [-80.282973, 25.630282],
        [-80.282873, 25.625182],
        [-80.283183, 25.622519],
        [-80.272099, 25.619954],
        [-80.249773, 25.615791],
        [-80.248647, 25.615581],
        [-80.226344, 25.611326],
        [-80.219331, 25.609988],
        [-80.218994, 25.611322],
        [-80.217254, 25.618198],
        [-80.215497, 25.625141],
        [-80.215445, 25.625382],
        [-80.213491, 25.634396],
        [-80.211777, 25.642306],
        [-80.20862, 25.656871],
        [-80.2046188, 25.673186],
        [-80.195626, 25.7098457],
        [-80.222271, 25.7095887],
        [-80.237623, 25.7095757],
        [-80.237687, 25.7095727],
        [-80.247757, 25.7095307],
        [-80.248446, 25.7095285],
        [-80.252809, 25.7093367],
        [-80.252912, 25.7093897],
        [-80.253636, 25.7093677],
        [-80.256727, 25.7092667],
        [-80.256832, 25.7092427],
        [-80.257099, 25.7091687],
        [-80.259564, 25.7090927],
        [-80.260948, 25.7090517],
        [-80.2609869, 25.7094939],
        [-80.2609969, 25.7097463],
        [-80.261036, 25.7104017],
        [-80.2610426, 25.7111804],
        [-80.2610852, 25.7115103],
        [-80.2610686, 25.7118386],
        [-80.2610713, 25.7119024],
        [-80.2610506, 25.7122372],
        [-80.2610996, 25.7126735],
        [-80.2611394, 25.7133903],
        [-80.2611894, 25.7141152],
        [-80.261185, 25.7149167],
        [-80.2611955, 25.7152895],
        [-80.261205, 25.7153937],
        [-80.2612056, 25.7154201],
        [-80.261212, 25.715511],
        [-80.261211, 25.7156427],
        [-80.2612248, 25.716514],
        [-80.261232, 25.7170753],
        [-80.2612489, 25.7173437],
        [-80.2612744, 25.7189998],
        [-80.2612967, 25.7199126],
        [-80.2613604, 25.7207366],
        [-80.2614093, 25.7214792],
        [-80.2614182, 25.7216688],
        [-80.2614496, 25.7222106],
        [-80.2614485, 25.7226121],
        [-80.2614709, 25.7229071],
        [-80.2614967, 25.7235532],
        [-80.2615237, 25.724362],
        [-80.2615495, 25.7248409],
        [-80.259488, 25.7249357],
        [-80.259287, 25.7247987],
        [-80.258502, 25.7249927],
        [-80.2586691, 25.7274145],
        [-80.2575787, 25.7274754],
        [-80.2558456, 25.7276217],
        [-80.2557725, 25.7281524],
        [-80.2558204, 25.7287746],
        [-80.2558273, 25.7295333],
        [-80.2558557, 25.7300758],
        [-80.2558894, 25.7306983],
        [-80.255894, 25.7309215],
        [-80.2559338, 25.7314272],
        [-80.255868, 25.7318937],
        [-80.25587, 25.7320207],
        [-80.255873, 25.7322307],
        [-80.2568826, 25.732179],
        [-80.2579282, 25.7321448],
        [-80.2579373, 25.7323486],
        [-80.257971, 25.7330997],
        [-80.2580546, 25.7349337],
        [-80.2581375, 25.7367831],
        [-80.2581671, 25.7374812],
        [-80.2582149, 25.7386254],
        [-80.2571997, 25.7386646],
        [-80.2562049, 25.7387043],
        [-80.2562174, 25.7390207],
        [-80.2562196, 25.7390979],
        [-80.2562505, 25.7398483],
        [-80.2562638, 25.7405562],
        [-80.2562723, 25.7409119],
        [-80.2562727, 25.7411226],
        [-80.2562781, 25.741252],
        [-80.2563234, 25.7423739],
        [-80.2543457, 25.7424343],
        [-80.2543808, 25.7429816],
        [-80.2543865, 25.7433517],
        [-80.254403, 25.7438313],
        [-80.2544485, 25.7450955],
        [-80.2545334, 25.7473798],
        [-80.2545699, 25.7481019],
        [-80.2545892, 25.7489357],
        [-80.2546435, 25.7515883],
        [-80.2546346, 25.7533852],
        [-80.2547209, 25.7560437],
        [-80.2547663, 25.7570559],
        [-80.2549283, 25.7570627],
        [-80.2549325, 25.757261],
        [-80.2549833, 25.7588186],
        [-80.255207, 25.7647807],
        [-80.25517, 25.7655737],
        [-80.255182, 25.7661027],
        [-80.255252, 25.7674827],
        [-80.255255, 25.7676807],
        [-80.255311, 25.7688777],
        [-80.25532, 25.7691757],
        [-80.255334, 25.7695437],
        [-80.255365, 25.7700357],
        [-80.2550774, 25.7700696],
        [-80.2544067, 25.7700856],
        [-80.254437, 25.7704592],
        [-80.254448, 25.771034],
        [-80.254486, 25.7714332],
        [-80.254151, 25.7714497],
        [-80.2541518, 25.7717717],
        [-80.2548447, 25.7717522],
        [-80.254832, 25.771377],
        [-80.255447, 25.7713657],
        [-80.255439, 25.7710047],
        [-80.255407, 25.7706497],
        [-80.258464, 25.7705667],
        [-80.258464, 25.7702377],
        [-80.258418, 25.7691327],
        [-80.258407, 25.7681637],
        [-80.259406, 25.7681357],
        [-80.25939, 25.7678187],
        [-80.259217, 25.7645827],
        [-80.25961, 25.7645627],
        [-80.260852, 25.7645197],
        [-80.261263, 25.7645057],
        [-80.261156, 25.762242],
        [-80.261109, 25.761237],
        [-80.2610257, 25.7594611],
        [-80.2610212, 25.759363],
        [-80.261004, 25.758997],
        [-80.2610023, 25.7589567],
        [-80.2610016, 25.7589395],
        [-80.260972, 25.758234],
        [-80.260974, 25.758147],
        [-80.260976, 25.758066],
        [-80.260945, 25.757184],
        [-80.260899, 25.756228],
        [-80.262946, 25.756202],
        [-80.262955, 25.756417],
        [-80.262978, 25.757158],
        [-80.26502, 25.757086],
        [-80.2670459, 25.7570696],
        [-80.269222, 25.756949],
        [-80.270835, 25.756894],
        [-80.271219, 25.756876],
        [-80.271231, 25.757228],
        [-80.271258, 25.757923],
        [-80.271275, 25.75861],
        [-80.271298, 25.759297],
        [-80.271323, 25.760003],
        [-80.271341, 25.760527],
        [-80.271349, 25.760676],
        [-80.2713684, 25.761256],
        [-80.27137, 25.761303],
        [-80.2713723, 25.7613717],
        [-80.2713722, 25.7614019],
        [-80.271386, 25.7619803],
        [-80.2713954, 25.7620679],
        [-80.271397, 25.762354],
        [-80.270373, 25.762394],
        [-80.2704379, 25.7641469],
        [-80.2707036, 25.7641286],
        [-80.271464, 25.7641797],
        [-80.272598, 25.7641267],
        [-80.273315, 25.7641037],
        [-80.273513, 25.7641047],
        [-80.274062, 25.7640807],
        [-80.274808, 25.7640437],
        [-80.2751773, 25.764032],
        [-80.276287, 25.7639967],
        [-80.2770566, 25.7639588],
        [-80.2773872, 25.763947],
        [-80.2775081, 25.7639427],
        [-80.2777649, 25.7639336],
        [-80.278288, 25.7639387],
        [-80.279259, 25.7638987],
        [-80.279662, 25.7638787],
        [-80.281751, 25.7637977],
        [-80.28228, 25.7637757],
        [-80.2827147, 25.7637563],
        [-80.283073, 25.7637417],
        [-80.283797, 25.7637197],
        [-80.2875093, 25.7635026],
        [-80.287828, 25.762777],
        [-80.287798, 25.762113],
        [-80.287774, 25.761349],
        [-80.287744, 25.760634],
        [-80.28771, 25.759953],
        [-80.287685, 25.759234],
        [-80.287664, 25.758695],
        [-80.2875443, 25.7565981],
        [-80.2875313, 25.7563002],
        [-80.2873612, 25.7523731],
        [-80.2872741, 25.7511064],
        [-80.2873108, 25.7504601],
        [-80.2871384, 25.7445617],
        [-80.2869849, 25.7420501],
        [-80.286844, 25.740135],
        [-80.286826, 25.7399],
        [-80.286753, 25.738555],
        [-80.286731, 25.7381],
        [-80.28665, 25.736396],
        [-80.286609, 25.735778],
        [-80.2865685, 25.735488],
        [-80.2865592, 25.7352426],
        [-80.286557, 25.7351847],
        [-80.2865476, 25.7348724],
        [-80.2865215, 25.7340346],
        [-80.2864472, 25.7323744],
        [-80.2864441, 25.7321579],
        [-80.286397, 25.73122],
        [-80.28639, 25.730859],
        [-80.286372, 25.730093],
        [-80.2862669, 25.7264237],
        [-80.2861842, 25.7247811],
        [-80.2861057, 25.7227124],
        [-80.2860353, 25.7208555],
        [-80.2860473, 25.7191357],
        [-80.2858493, 25.7167267],
        [-80.2858434, 25.7152521],
        [-80.2857539, 25.7117718],
        [-80.285616, 25.709912],
        [-80.2855901, 25.7095791],
        [-80.285569, 25.709331],
        [-80.285553, 25.7086],
        [-80.285515, 25.707511],
        [-80.2854401, 25.7065498],
        [-80.2852696, 25.7043635],
        [-80.279228, 25.704697],
        [-80.279238, 25.704925],
        [-80.279035, 25.704954],
        [-80.2772529, 25.70498],
        [-80.277208, 25.70478],
        [-80.2738335, 25.7049371],
        [-80.272053, 25.7049533],
        [-80.2720233, 25.7040982],
        [-80.272009, 25.703108],
        [-80.271045, 25.703133],
        [-80.271082, 25.7050151],
        [-80.2692021, 25.7051077],
        [-80.2690149, 25.7051265],
        [-80.267447, 25.705155],
        [-80.2669788, 25.705152],
        [-80.266898, 25.703561],
        [-80.266906, 25.703481],
        [-80.266915, 25.703386],
        [-80.266883, 25.702372],
        [-80.268311, 25.702315],
        [-80.268393, 25.70454],
        [-80.2688915, 25.7045237],
        [-80.2687305, 25.6976853],
        [-80.2686559, 25.695702],
        [-80.2687236, 25.6956981],
        [-80.2686203, 25.6931914],
        [-80.268572, 25.6922845],
        [-80.2693922, 25.6908023],
        [-80.2697883, 25.6900773],
        [-80.27259, 25.690061],
        [-80.275777, 25.68995],
        [-80.2759969, 25.6899423],
        [-80.2766838, 25.6899154],
        [-80.278674, 25.689838],
        [-80.2795589, 25.6898095],
        [-80.279749, 25.689797],
        [-80.280266, 25.689783],
        [-80.280843, 25.68976],
        [-80.284217, 25.689686],
        [-80.284475, 25.68968],
        [-80.2848822, 25.6896557],
        [-80.284778, 25.689295],
        [-80.284775, 25.68925],
        [-80.284756, 25.68899],
        [-80.284699, 25.688941],
        [-80.284691, 25.688785],
        [-80.284658, 25.688743],
        [-80.284642, 25.688624],
        [-80.284632, 25.688445],
        [-80.284605, 25.687955],
        [-80.284575, 25.687009],
        [-80.284556, 25.686549],
        [-80.284508, 25.685349],
        [-80.284504, 25.685056],
        [-80.2845215, 25.684841],
        [-80.2845024, 25.684573],
        [-80.2844931, 25.6844834],
        [-80.2844938, 25.6843785],
        [-80.2844908, 25.6841051],
        [-80.2844591, 25.6835316],
        [-80.2844594, 25.6834386],
        [-80.2844568, 25.683369],
        [-80.2844388, 25.6827218],
        [-80.2844305, 25.682365],
        [-80.284401, 25.682166],
        [-80.2844191, 25.681924],
        [-80.2844074, 25.6817565],
        [-80.284397, 25.681652],
        [-80.2844018, 25.6810558],
        [-80.2843723, 25.6808437],
        [-80.2844052, 25.6806927],
        [-80.2843888, 25.680592],
        [-80.284349, 25.680383],
        [-80.2843575, 25.6800504],
        [-80.2843551, 25.6797204],
        [-80.2843335, 25.6793841],
        [-80.284328, 25.679197],
        [-80.284324, 25.678901],
        [-80.2843295, 25.6786754],
        [-80.2843161, 25.6785594],
        [-80.2843168, 25.678332],
        [-80.2843225, 25.6782264],
        [-80.28428, 25.677403],
        [-80.2842744, 25.6769282],
        [-80.2842688, 25.6768446],
        [-80.2842709, 25.6766715],
        [-80.2842575, 25.676202],
        [-80.2842391, 25.6757318],
        [-80.284226, 25.675492],
        [-80.284209, 25.67542],
        [-80.284188, 25.675229],
        [-80.284181, 25.674783],
        [-80.284174, 25.674686],
        [-80.284164, 25.674556],
        [-80.284164, 25.674404],
        [-80.284143, 25.674009],
        [-80.28415, 25.673735],
        [-80.284139, 25.673663],
        [-80.284139, 25.673437],
        [-80.284117, 25.672769],
        [-80.284124, 25.672597],
        [-80.284102, 25.672544],
        [-80.284055, 25.67137],
        [-80.28406, 25.670647],
        [-80.284031, 25.670474],
        [-80.284012, 25.670392],
        [-80.283998, 25.670296],
        [-80.28399, 25.670119],
        [-80.2839855, 25.6700284],
        [-80.2839151, 25.6698583],
        [-80.2839346, 25.6697996],
        [-80.283878, 25.669789],
        [-80.283878, 25.669697],
        [-80.283791, 25.669649],
        [-80.28377, 25.669561],
        [-80.28381, 25.669517],
        [-80.283835, 25.6695],
        [-80.283828, 25.669482],
        [-80.283773, 25.669443],
        [-80.283705, 25.669302],
        [-80.283638, 25.669122],
        [-80.283565, 25.669027],
        [-80.283559, 25.668985],
        [-80.283537, 25.668968],
        [-80.283525, 25.668915],
        [-80.28349, 25.668894],
        [-80.28344, 25.668833],
        [-80.283434, 25.668799],
        [-80.283459, 25.668776],
        [-80.283458, 25.66874],
        [-80.28345, 25.668709],
        [-80.283378, 25.668682],
        [-80.283286, 25.668638],
        [-80.283098, 25.66853],
        [-80.282864, 25.668365],
        [-80.282707, 25.668246],
        [-80.282692, 25.668223],
        [-80.282654, 25.66816],
        [-80.282539, 25.668121],
        [-80.282421, 25.668049],
        [-80.282251, 25.667934],
        [-80.281941, 25.667713],
        [-80.281886, 25.667685],
        [-80.281404, 25.667356],
        [-80.280904, 25.666992],
        [-80.280711, 25.666828],
        [-80.280641, 25.666777],
        [-80.280441, 25.666651],
        [-80.280216, 25.666464],
        [-80.279939, 25.666286],
        [-80.279923, 25.66627],
        [-80.279916, 25.666253],
        [-80.279886, 25.666219],
        [-80.279773, 25.666146],
        [-80.279787, 25.666093],
        [-80.279844, 25.665916],
        [-80.279924, 25.665721],
        [-80.280026, 25.66551],
        [-80.280196, 25.665234],
        [-80.280344, 25.665037],
        [-80.28051, 25.66485],
        [-80.280691, 25.664675],
        [-80.280881, 25.664515],
        [-80.281093, 25.664367],
        [-80.281313, 25.664235],
        [-80.281465, 25.664157],
        [-80.28154, 25.664119],
        [-80.281782, 25.664018],
        [-80.282031, 25.663933],
        [-80.282194, 25.663888],
        [-80.282356, 25.663851],
        [-80.282614, 25.663805],
        [-80.282875, 25.663776],
        [-80.283934, 25.663756],
        [-80.283961, 25.664289],
        [-80.29006, 25.664139],
        [-80.290042, 25.663607],
        [-80.290022, 25.66262],
        [-80.290014, 25.661763],
        [-80.288718, 25.661787],
        [-80.288682, 25.660938],
        [-80.28807, 25.660938],
        [-80.2880432, 25.659992],
        [-80.2871442, 25.6599797],
        [-80.285353, 25.660018],
        [-80.2846418, 25.6600288],
        [-80.2839418, 25.6600429],
        [-80.283827, 25.660045],
        [-80.283806, 25.659177],
        [-80.283748, 25.656852],
        [-80.283792, 25.656391],
        [-80.2838836, 25.6561456],
        [-80.284162, 25.655325],
        [-80.284389, 25.654749],
        [-80.284501, 25.654509],
        [-80.284687, 25.654147],
        [-80.284871, 25.653834],
        [-80.285018, 25.653602],
        [-80.285176, 25.653383],
        [-80.2854, 25.653091],
        [-80.285652, 25.652789],
        [-80.2855662, 25.6526006],
        [-80.2836579, 25.6526187],
        [-80.283603, 25.651475],
        [-80.2835291, 25.6494632],
        [-80.2834013, 25.6484825],
        [-80.283457, 25.645725],
        [-80.2834361, 25.6452761],
        [-80.289235, 25.6451731],
        [-80.2896211, 25.6450806],
        [-80.2906108, 25.6451283],
        [-80.290586, 25.643557],
        [-80.290101, 25.643277],
        [-80.290043, 25.643238],
        [-80.28919, 25.642736],
        [-80.291943, 25.638662],
        [-80.296316, 25.638626],
        [-80.296353, 25.638527],
        [-80.296455, 25.638523],
        [-80.2971312, 25.6374913],
        [-80.3013, 25.637356]
    ],
    "Fisher Island": [
        [-80.149088, 25.763904],
        [-80.148821, 25.762374],
        [-80.145097, 25.759628],
        [-80.145035, 25.7596973],
        [-80.1445394, 25.7602649],
        [-80.1446019, 25.7619448],
        [-80.1431269, 25.7619909],
        [-80.1430442, 25.7598941],
        [-80.1439292, 25.7598676],
        [-80.1439832, 25.759807],
        [-80.144524, 25.759201],
        [-80.144189, 25.758961],
        [-80.142919, 25.758019],
        [-80.142686, 25.757852],
        [-80.142018, 25.757655],
        [-80.141981, 25.757689],
        [-80.141961, 25.757666],
        [-80.141729, 25.757474],
        [-80.141826, 25.757401],
        [-80.141949, 25.757436],
        [-80.141992, 25.757438],
        [-80.142005, 25.757392],
        [-80.142024, 25.757378],
        [-80.1420623, 25.7573376],
        [-80.142028, 25.757313],
        [-80.141324, 25.756793],
        [-80.141143, 25.75699],
        [-80.141099, 25.756993],
        [-80.14099, 25.756913],
        [-80.140989, 25.756874],
        [-80.141066, 25.756792],
        [-80.141137, 25.756845],
        [-80.141171, 25.756843],
        [-80.141242, 25.756767],
        [-80.1412366, 25.7567466],
        [-80.1403993, 25.7561262],
        [-80.140367, 25.756136],
        [-80.140246, 25.75627],
        [-80.140066, 25.756266],
        [-80.140072, 25.75616],
        [-80.140244, 25.756158],
        [-80.14031, 25.756076],
        [-80.140355, 25.755849],
        [-80.1403426, 25.755803],
        [-80.140258, 25.7557276],
        [-80.1401068, 25.7555861],
        [-80.140021, 25.755508],
        [-80.139977, 25.755471],
        [-80.1399727, 25.755457],
        [-80.139975, 25.755443],
        [-80.1399828, 25.7554303],
        [-80.139996, 25.755421],
        [-80.1400526, 25.7554017],
        [-80.140254, 25.755336],
        [-80.140632, 25.7552091],
        [-80.140766, 25.755163],
        [-80.1408473, 25.7551337],
        [-80.1408513, 25.7551303],
        [-80.1408533, 25.755127],
        [-80.1408539, 25.7551224],
        [-80.1408537, 25.7551184],
        [-80.1408524, 25.7551143],
        [-80.1408505, 25.7551106],
        [-80.140848, 25.7551076],
        [-80.1408424, 25.7551034],
        [-80.1408354, 25.7551005],
        [-80.1408282, 25.7550998],
        [-80.1408139, 25.7551007],
        [-80.140785, 25.755105],
        [-80.140735, 25.7551187],
        [-80.1405989, 25.7551665],
        [-80.140025, 25.755373],
        [-80.1399821, 25.7553881],
        [-80.1399424, 25.7553968],
        [-80.1399121, 25.7553924],
        [-80.1398885, 25.7553845],
        [-80.1397635, 25.7552836],
        [-80.1396816, 25.7552105],
        [-80.1396756, 25.755209],
        [-80.13967, 25.7552096],
        [-80.1396641, 25.7552121],
        [-80.1396596, 25.755216],
        [-80.139655, 25.7552199],
        [-80.1396521, 25.7552254],
        [-80.13965, 25.7552307],
        [-80.1396502, 25.755237],
        [-80.139775, 25.755365],
        [-80.1398296, 25.7554211],
        [-80.1398578, 25.7554522],
        [-80.13988, 25.755479],
        [-80.1398845, 25.7554944],
        [-80.1398824, 25.7555112],
        [-80.1397943, 25.7557165],
        [-80.1395377, 25.7561497],
        [-80.1394818, 25.7561648],
        [-80.139274, 25.756189],
        [-80.139185, 25.756199],
        [-80.13907, 25.756187],
        [-80.138838, 25.756191],
        [-80.138335, 25.756308],
        [-80.138022, 25.756391],
        [-80.13794, 25.756429],
        [-80.137848, 25.756417],
        [-80.137757, 25.756423],
        [-80.137671, 25.756471],
        [-80.137511, 25.75651],
        [-80.137306, 25.75656],
        [-80.137206, 25.756571],
        [-80.137099, 25.756567],
        [-80.1366015, 25.7564207],
        [-80.1362622, 25.7563311],
        [-80.1361058, 25.7562932],
        [-80.1360958, 25.7563063],
        [-80.1360908, 25.7563219],
        [-80.1360857, 25.7563382],
        [-80.1360861, 25.7563545],
        [-80.136528, 25.756481],
        [-80.136916, 25.756612],
        [-80.1369126, 25.756629],
        [-80.1369078, 25.7566479],
        [-80.1368969, 25.7566831],
        [-80.1368858, 25.7567219],
        [-80.136885, 25.7567319],
        [-80.1368854, 25.7567389],
        [-80.1368877, 25.7567464],
        [-80.1368895, 25.7567515],
        [-80.1368918, 25.7567553],
        [-80.1368955, 25.7567585],
        [-80.1369012, 25.7567606],
        [-80.1369075, 25.7567602],
        [-80.1369193, 25.756758],
        [-80.1369299, 25.7567553],
        [-80.1369396, 25.7567511],
        [-80.136999, 25.756632],
        [-80.1371081, 25.7566565],
        [-80.1372076, 25.7566922],
        [-80.1372322, 25.7567814],
        [-80.1372461, 25.7569503],
        [-80.137245, 25.757118],
        [-80.137189, 25.757314],
        [-80.137104, 25.75744],
        [-80.1370638, 25.7574839],
        [-80.1370017, 25.7574936],
        [-80.1369087, 25.7574732],
        [-80.1369176, 25.7574194],
        [-80.1369036, 25.7573776],
        [-80.136858, 25.757372],
        [-80.1368268, 25.7574596],
        [-80.136808, 25.757561],
        [-80.136813, 25.757585],
        [-80.136828, 25.757597],
        [-80.136854, 25.757589],
        [-80.136867, 25.757561],
        [-80.136906, 25.757527],
        [-80.136948, 25.757523],
        [-80.1370057, 25.7575563],
        [-80.1370373, 25.7575993],
        [-80.1370472, 25.7577384],
        [-80.1370282, 25.7579044],
        [-80.136949, 25.758135],
        [-80.1368713, 25.7582526],
        [-80.1367707, 25.7583172],
        [-80.136697, 25.7582943],
        [-80.1367076, 25.7582227],
        [-80.1366848, 25.7581871],
        [-80.1366224, 25.7582035],
        [-80.136583, 25.7583083],
        [-80.136551, 25.758458],
        [-80.1365815, 25.7584768],
        [-80.1366193, 25.7584637],
        [-80.1366391, 25.7584318],
        [-80.1366686, 25.7584673],
        [-80.1366818, 25.7585765],
        [-80.136617, 25.75886],
        [-80.1364828, 25.7591415],
        [-80.1363885, 25.7591826],
        [-80.1363289, 25.7591819],
        [-80.136356, 25.759115],
        [-80.1363434, 25.7590739],
        [-80.136302, 25.759056],
        [-80.136289, 25.759071],
        [-80.136259, 25.759131],
        [-80.13621, 25.759254],
        [-80.136092, 25.75941],
        [-80.136086, 25.759428],
        [-80.136094, 25.759465],
        [-80.13614, 25.759462],
        [-80.1362137, 25.7593959],
        [-80.1362372, 25.7594654],
        [-80.1362206, 25.7595959],
        [-80.1361371, 25.7597854],
        [-80.1360684, 25.7599615],
        [-80.1359864, 25.7600777],
        [-80.1358845, 25.7601272],
        [-80.135817, 25.760007],
        [-80.135768, 25.759993],
        [-80.135759, 25.76001],
        [-80.135761, 25.760036],
        [-80.1358465, 25.7601773],
        [-80.1358457, 25.760221],
        [-80.1358088, 25.7602526],
        [-80.1356233, 25.7605363],
        [-80.1354001, 25.7608274],
        [-80.135386, 25.760815],
        [-80.135356, 25.760808],
        [-80.135321, 25.760807],
        [-80.1352961, 25.7609522],
        [-80.1352645, 25.7610793],
        [-80.1351542, 25.7612008],
        [-80.1351137, 25.7612693],
        [-80.1349912, 25.7613407],
        [-80.134874, 25.7614559],
        [-80.134756, 25.761526],
        [-80.134756, 25.761499],
        [-80.134762, 25.761481],
        [-80.134781, 25.761455],
        [-80.134782, 25.761431],
        [-80.134741, 25.761426],
        [-80.134694, 25.761436],
        [-80.134688, 25.761481],
        [-80.134688, 25.761607],
        [-80.134653, 25.761645],
        [-80.1345409, 25.7617565],
        [-80.134401, 25.761918],
        [-80.1342775, 25.7619353],
        [-80.1342592, 25.7620176],
        [-80.1341951, 25.7620551],
        [-80.1341419, 25.7621071],
        [-80.1341039, 25.7620971],
        [-80.1340517, 25.7620986],
        [-80.1339949, 25.7621424],
        [-80.1338718, 25.7622391],
        [-80.1337179, 25.7623616],
        [-80.1333947, 25.7622319],
        [-80.1328959, 25.7620205],
        [-80.1322761, 25.7617519],
        [-80.129173, 25.7604747],
        [-80.1279908, 25.7599845],
        [-80.1279622, 25.7599836],
        [-80.1279349, 25.7599976],
        [-80.1279201, 25.7600198],
        [-80.1279219, 25.7600469],
        [-80.1291302, 25.7605583],
        [-80.132235, 25.7618163],
        [-80.132861, 25.7620827],
        [-80.133362, 25.7622959],
        [-80.1337075, 25.7624341],
        [-80.1360527, 25.7633948],
        [-80.1372704, 25.7639145],
        [-80.1383854, 25.7643814],
        [-80.1385239, 25.7641081],
        [-80.138629, 25.764149],
        [-80.13848, 25.764435],
        [-80.138486, 25.76448],
        [-80.1386062, 25.7645399],
        [-80.1386352, 25.764483],
        [-80.139171, 25.764454],
        [-80.1392753, 25.7642495],
        [-80.139333, 25.764136],
        [-80.139558, 25.764242],
        [-80.139575, 25.76425],
        [-80.13982, 25.764355],
        [-80.139878, 25.764387],
        [-80.139938, 25.76441],
        [-80.1400629, 25.7644396],
        [-80.1412105, 25.7644065],
        [-80.1411584, 25.7630582],
        [-80.1414095, 25.7630501],
        [-80.1416127, 25.7630378],
        [-80.141648, 25.764332],
        [-80.141653, 25.76435],
        [-80.141673, 25.764368],
        [-80.141702, 25.764378],
        [-80.1429589, 25.7643541],
        [-80.1440823, 25.764323],
        [-80.14414, 25.764303],
        [-80.144182, 25.764191],
        [-80.144167, 25.763801],
        [-80.144313, 25.7637986],
        [-80.1443227, 25.7641933],
        [-80.144426, 25.7643],
        [-80.148048, 25.764192],
        [-80.1484503, 25.764187],
        [-80.1488901, 25.7641696],
        [-80.148952, 25.76414],
        [-80.149, 25.764108],
        [-80.149059, 25.76404],
        [-80.149077, 25.764001],
        [-80.149087, 25.763958],
        [-80.149088, 25.763904]
    ],
    "Bay Harbour": [
        [
            -80.1355339258435,
            25.89332228409701
        ],
        [
            -80.13739516929682,
            25.892285751927503
        ],
        [
            -80.13952230467198,
            25.887501639348088
        ],
        [
            -80.13801558378142,
            25.884152645187754
        ],
        [
            -80.13429309687477,
            25.88383368840863
        ],
        [
            -80.13429309687477,
            25.885508201886125
        ],
        [
            -80.13447035815614,
            25.886465056067976
        ],
        [
            -80.1334954211088,
            25.886465056067976
        ],
        [
            -80.13376129577227,
            25.88383367893215
        ],
        [
            -80.12977291694423,
            25.880325097501668
        ],
        [
            -80.12862071861596,
            25.88199966073317
        ],
        [
            -80.12791167349091,
            25.885029762410156
        ],
        [
            -80.12862071861596,
            25.887023207949937
        ],
        [
            -80.13021607014703,
            25.890292385772128
        ],
        [
            -80.13110237655343,
            25.893800671014176
        ],
        [
            -80.13411581833503,
            25.894677726031745
        ],
        [
            -80.13358403449134,
            25.887023207949937
        ],
        [
            -80.13429307961638,
            25.886704258925178
        ],
        [
            -80.1355339258435,
            25.89332228409701
        ]
    ],
    "Brickell": [
        [
            -80.19911284629863,
            25.761833379198478
        ],
        [
            -80.1999422980948,
            25.75674648228967
        ],
        [
            -80.20077174989095,
            25.755679267476623
        ],
        [
            -80.20448453412145,
            25.752940042153014
        ],
        [
            -80.20306508536275,
            25.751227992314504
        ],
        [
            -80.2010914344369,
            25.74893111978071
        ],
        [
            -80.1989986732463,
            25.750770995499145
        ],
        [
            -80.19725215340786,
            25.752507158948802
        ],
        [
            -80.19455768865973,
            25.754101023239553
        ],
        [
            -80.19501441944121,
            25.754656236907238
        ],
        [
            -80.19174112431726,
            25.756407021103065
        ],
        [
            -80.19143517139513,
            25.75599545290196
        ],
        [
            -80.1902702976863,
            25.75680872093143
        ],
        [
            -80.18901130835224,
            25.75800932166399
        ],
        [
            -80.1891791735968,
            25.75955674635273
        ],
        [
            -80.18872494999422,
            25.76001919903456
        ],
        [
            -80.18841884278392,
            25.76554164053322
        ],
        [
            -80.18607119796799,
            25.765588315062836
        ],
        [
            -80.18514547051741,
            25.763856436868593
        ],
        [
            -80.18408396970631,
            25.76373637015363
        ],
        [
            -80.18283979201203,
            25.769516674179485
        ],
        [
            -80.18754989328248,
            25.769436624143825
        ],
        [
            -80.18617734804828,
            25.765941855719426
        ],
        [
            -80.18825097753879,
            25.76592408461553
        ],
        [
            -80.18797449360751,
            25.769303257725035
        ],
        [
            -80.18868545229053,
            25.769801235604746
        ],
        [
            -80.19050234670088,
            25.76974787973635
        ],
        [
            -80.19176627324741,
            25.77013025195122
        ],
        [
            -80.19358316765738,
            25.76953890645452
        ],
        [
            -80.19441261945333,
            25.7685318353851
        ],
        [
            -80.19696022139928,
            25.768526276321076
        ],
        [
            -80.19811552925796,
            25.769057045658204
        ],
        [
            -80.19927083711663,
            25.769587814995333
        ],
        [
            -80.19927083711663,
            25.767844889616455
        ],
        [
            -80.1990733485941,
            25.76553280625248
        ],
        [
            -80.19911284629863,
            25.761833379198478
        ]
    ],
    "Golden Beach": [
        [-80.125138, 25.957027],
        [-80.124555, 25.957049],
        [-80.1220823, 25.9571019],
        [-80.121726, 25.957103],
        [-80.121044, 25.957136],
        [-80.120633, 25.957131],
        [-80.119917, 25.957121],
        [-80.1186774, 25.9571263],
        [-80.118686, 25.957368],
        [-80.118677, 25.957643],
        [-80.118653, 25.958216],
        [-80.118607, 25.959041],
        [-80.118586, 25.959965],
        [-80.118637, 25.960656],
        [-80.118644, 25.961118],
        [-80.118637, 25.961878],
        [-80.118611, 25.963066],
        [-80.118604, 25.963726],
        [-80.118614, 25.964023],
        [-80.118612, 25.964188],
        [-80.118588, 25.964352],
        [-80.118564, 25.964846],
        [-80.118521, 25.965208],
        [-80.118489, 25.965536],
        [-80.118443, 25.965797],
        [-80.118405, 25.966225],
        [-80.118347, 25.966584],
        [-80.118294, 25.967044],
        [-80.118248, 25.96767],
        [-80.118163, 25.968201],
        [-80.118162, 25.968553],
        [-80.118205, 25.969036],
        [-80.118249, 25.969452],
        [-80.118251, 25.96976],
        [-80.118274, 25.970133],
        [-80.118265, 25.970331],
        [-80.118265, 25.970617],
        [-80.118225, 25.971144],
        [-80.118154, 25.971691],
        [-80.118113, 25.971953],
        [-80.118055, 25.972389],
        [-80.118009, 25.973158],
        [-80.118017, 25.973576],
        [-80.118067, 25.974168],
        [-80.118081, 25.974697],
        [-80.1180451, 25.9752061],
        [-80.118675, 25.9751873],
        [-80.1233062, 25.9750487],
        [-80.124275, 25.958434],
        [-80.1247422, 25.9586632],
        [-80.125138, 25.957027]
    ],
    "Pinecrest": [
        [-80.3341053, 25.6438567],
        [-80.3335862, 25.643861],
        [-80.3321603, 25.6439284],
        [-80.3304617, 25.6440237],
        [-80.3279416, 25.6440258],
        [-80.323906, 25.64412],
        [-80.323357, 25.644142],
        [-80.3213178, 25.6442374],
        [-80.320319, 25.644217],
        [-80.3186993, 25.6443044],
        [-80.3157381, 25.6443938],
        [-80.31473, 25.644376],
        [-80.313718, 25.644407],
        [-80.312714, 25.644434],
        [-80.311734, 25.644481],
        [-80.310669, 25.644499],
        [-80.309671, 25.644531],
        [-80.3091261, 25.6445496],
        [-80.3087561, 25.6445622],
        [-80.308243, 25.644577],
        [-80.307664, 25.644596],
        [-80.307254, 25.644606],
        [-80.306648, 25.644623],
        [-80.305366, 25.644657],
        [-80.3040479, 25.6447434],
        [-80.3033095, 25.6447882],
        [-80.3029574, 25.6448096],
        [-80.3015103, 25.6448238],
        [-80.2991691, 25.6448668],
        [-80.2954626, 25.6449642],
        [-80.2929205, 25.6450335],
        [-80.2924475, 25.6450476],
        [-80.2921809, 25.6451015],
        [-80.2919296, 25.6451705],
        [-80.291603, 25.645278],
        [-80.2910891, 25.645494],
        [-80.2909283, 25.6452646],
        [-80.2906108, 25.6451283],
        [-80.2896211, 25.6450806],
        [-80.289235, 25.6451731],
        [-80.2834361, 25.6452761],
        [-80.283457, 25.645725],
        [-80.2834013, 25.6484825],
        [-80.2835291, 25.6494632],
        [-80.283603, 25.651475],
        [-80.2836579, 25.6526187],
        [-80.2855662, 25.6526006],
        [-80.285652, 25.652789],
        [-80.2854, 25.653091],
        [-80.285176, 25.653383],
        [-80.285018, 25.653602],
        [-80.284871, 25.653834],
        [-80.284687, 25.654147],
        [-80.284501, 25.654509],
        [-80.284389, 25.654749],
        [-80.284162, 25.655325],
        [-80.2838836, 25.6561456],
        [-80.283792, 25.656391],
        [-80.283748, 25.656852],
        [-80.283806, 25.659177],
        [-80.283827, 25.660045],
        [-80.2839418, 25.6600429],
        [-80.2846418, 25.6600288],
        [-80.285353, 25.660018],
        [-80.2871442, 25.6599797],
        [-80.2880432, 25.659992],
        [-80.28807, 25.660938],
        [-80.288682, 25.660938],
        [-80.288718, 25.661787],
        [-80.290014, 25.661763],
        [-80.290022, 25.66262],
        [-80.290042, 25.663607],
        [-80.29006, 25.664139],
        [-80.283961, 25.664289],
        [-80.283934, 25.663756],
        [-80.282875, 25.663776],
        [-80.282614, 25.663805],
        [-80.282356, 25.663851],
        [-80.282194, 25.663888],
        [-80.282031, 25.663933],
        [-80.281782, 25.664018],
        [-80.28154, 25.664119],
        [-80.281465, 25.664157],
        [-80.281313, 25.664235],
        [-80.281093, 25.664367],
        [-80.280881, 25.664515],
        [-80.280691, 25.664675],
        [-80.28051, 25.66485],
        [-80.280344, 25.665037],
        [-80.280196, 25.665234],
        [-80.280026, 25.66551],
        [-80.279924, 25.665721],
        [-80.279844, 25.665916],
        [-80.279787, 25.666093],
        [-80.279773, 25.666146],
        [-80.279886, 25.666219],
        [-80.279916, 25.666253],
        [-80.279923, 25.66627],
        [-80.279939, 25.666286],
        [-80.280216, 25.666464],
        [-80.280441, 25.666651],
        [-80.280641, 25.666777],
        [-80.280711, 25.666828],
        [-80.280904, 25.666992],
        [-80.281404, 25.667356],
        [-80.281886, 25.667685],
        [-80.281941, 25.667713],
        [-80.282251, 25.667934],
        [-80.282421, 25.668049],
        [-80.282539, 25.668121],
        [-80.282654, 25.66816],
        [-80.282692, 25.668223],
        [-80.282707, 25.668246],
        [-80.282864, 25.668365],
        [-80.283098, 25.66853],
        [-80.283286, 25.668638],
        [-80.283378, 25.668682],
        [-80.28345, 25.668709],
        [-80.283458, 25.66874],
        [-80.283459, 25.668776],
        [-80.283434, 25.668799],
        [-80.28344, 25.668833],
        [-80.28349, 25.668894],
        [-80.283525, 25.668915],
        [-80.283537, 25.668968],
        [-80.283559, 25.668985],
        [-80.283565, 25.669027],
        [-80.283638, 25.669122],
        [-80.283705, 25.669302],
        [-80.283773, 25.669443],
        [-80.283828, 25.669482],
        [-80.283835, 25.6695],
        [-80.28381, 25.669517],
        [-80.28377, 25.669561],
        [-80.283791, 25.669649],
        [-80.283878, 25.669697],
        [-80.283878, 25.669789],
        [-80.2839346, 25.6697996],
        [-80.2839151, 25.6698583],
        [-80.2839855, 25.6700284],
        [-80.28399, 25.670119],
        [-80.283998, 25.670296],
        [-80.284012, 25.670392],
        [-80.284031, 25.670474],
        [-80.28406, 25.670647],
        [-80.284055, 25.67137],
        [-80.284102, 25.672544],
        [-80.284124, 25.672597],
        [-80.284117, 25.672769],
        [-80.284139, 25.673437],
        [-80.284139, 25.673663],
        [-80.28415, 25.673735],
        [-80.284143, 25.674009],
        [-80.284164, 25.674404],
        [-80.284164, 25.674556],
        [-80.284174, 25.674686],
        [-80.284181, 25.674783],
        [-80.284188, 25.675229],
        [-80.284209, 25.67542],
        [-80.284226, 25.675492],
        [-80.2842391, 25.6757318],
        [-80.2842575, 25.676202],
        [-80.2842709, 25.6766715],
        [-80.2842688, 25.6768446],
        [-80.2842744, 25.6769282],
        [-80.28428, 25.677403],
        [-80.2843225, 25.6782264],
        [-80.2843168, 25.678332],
        [-80.2843161, 25.6785594],
        [-80.2843295, 25.6786754],
        [-80.284324, 25.678901],
        [-80.284328, 25.679197],
        [-80.2843335, 25.6793841],
        [-80.2843551, 25.6797204],
        [-80.2843575, 25.6800504],
        [-80.284349, 25.680383],
        [-80.2843888, 25.680592],
        [-80.2844052, 25.6806927],
        [-80.2843723, 25.6808437],
        [-80.2844018, 25.6810558],
        [-80.284397, 25.681652],
        [-80.2844074, 25.6817565],
        [-80.2844191, 25.681924],
        [-80.284401, 25.682166],
        [-80.2844305, 25.682365],
        [-80.2844388, 25.6827218],
        [-80.2844568, 25.683369],
        [-80.2844594, 25.6834386],
        [-80.2844591, 25.6835316],
        [-80.2844908, 25.6841051],
        [-80.2844938, 25.6843785],
        [-80.2844931, 25.6844834],
        [-80.2845024, 25.684573],
        [-80.2845215, 25.684841],
        [-80.284504, 25.685056],
        [-80.284508, 25.685349],
        [-80.284556, 25.686549],
        [-80.284575, 25.687009],
        [-80.284605, 25.687955],
        [-80.284632, 25.688445],
        [-80.284642, 25.688624],
        [-80.284658, 25.688743],
        [-80.284691, 25.688785],
        [-80.284699, 25.688941],
        [-80.284756, 25.68899],
        [-80.284775, 25.68925],
        [-80.284778, 25.689295],
        [-80.2848822, 25.6896557],
        [-80.285292, 25.689651],
        [-80.285571, 25.689637],
        [-80.2868394, 25.6895758],
        [-80.2891958, 25.689437],
        [-80.29101, 25.68941],
        [-80.292848, 25.689373],
        [-80.292882, 25.69042],
        [-80.293255, 25.690446],
        [-80.293374, 25.690469],
        [-80.293499, 25.690481],
        [-80.294017, 25.690533],
        [-80.294771, 25.690564],
        [-80.294875, 25.690573],
        [-80.294974, 25.690581],
        [-80.295203, 25.690591],
        [-80.295663, 25.690634],
        [-80.29634, 25.69071],
        [-80.29697, 25.690768],
        [-80.297017, 25.690768],
        [-80.297249, 25.690813],
        [-80.29751, 25.690835],
        [-80.298075, 25.690883],
        [-80.299679, 25.691042],
        [-80.300404, 25.691126],
        [-80.301178, 25.691208],
        [-80.301869, 25.691282],
        [-80.302011, 25.691284],
        [-80.302465, 25.691304],
        [-80.303675, 25.691318],
        [-80.304476, 25.691289],
        [-80.304591, 25.691301],
        [-80.304814, 25.691375],
        [-80.304895, 25.691421],
        [-80.30508, 25.691558],
        [-80.305559, 25.691172],
        [-80.306946, 25.690089],
        [-80.308846, 25.688521],
        [-80.309988, 25.687556],
        [-80.311195, 25.686473],
        [-80.312747, 25.684929],
        [-80.3138357, 25.6837584],
        [-80.314859, 25.68249],
        [-80.315422, 25.681798],
        [-80.315738, 25.681347],
        [-80.316232, 25.680643],
        [-80.31705, 25.67948],
        [-80.318233, 25.677617],
        [-80.318723, 25.676791],
        [-80.318913, 25.676352],
        [-80.319262, 25.675771],
        [-80.320185, 25.6738666],
        [-80.321107, 25.671903],
        [-80.321269, 25.671543],
        [-80.321535, 25.670956],
        [-80.321563, 25.670894],
        [-80.321741, 25.670501],
        [-80.322783, 25.668274],
        [-80.323627, 25.666401],
        [-80.32517, 25.66309],
        [-80.327069, 25.658978],
        [-80.328395, 25.656237],
        [-80.3289045, 25.655154],
        [-80.330631, 25.651374],
        [-80.331549, 25.649448],
        [-80.332387, 25.647653],
        [-80.3326, 25.647129],
        [-80.33272, 25.64688],
        [-80.332879, 25.646571],
        [-80.333318, 25.645607],
        [-80.333374, 25.645483],
        [-80.3341053, 25.6438567]
    ],
    "South Miami": [
        [-80.3068434, 25.714553],
        [-80.3068014, 25.7126483],
        [-80.3066789, 25.7108866],
        [-80.3058589, 25.7109069],
        [-80.3058202, 25.7097748],
        [-80.3057811, 25.7090022],
        [-80.3057604, 25.7081824],
        [-80.3057171, 25.7072601],
        [-80.3056552, 25.7060536],
        [-80.3056037, 25.7046753],
        [-80.3054109, 25.7008359],
        [-80.305321, 25.7002695],
        [-80.3051894, 25.6976739],
        [-80.3051465, 25.6963527],
        [-80.2997395, 25.6965078],
        [-80.2988864, 25.696536],
        [-80.2989485, 25.6956067],
        [-80.2970452, 25.6956238],
        [-80.2970888, 25.6965624],
        [-80.2968806, 25.6965681],
        [-80.296518, 25.696571],
        [-80.2956341, 25.69659],
        [-80.2951129, 25.6966283],
        [-80.2947415, 25.6966447],
        [-80.2939967, 25.696656],
        [-80.29312, 25.696676],
        [-80.293107, 25.695796],
        [-80.293084, 25.694872],
        [-80.2930794, 25.6947556],
        [-80.2930344, 25.6936841],
        [-80.2930073, 25.6930335],
        [-80.292958, 25.692114],
        [-80.292934, 25.691475],
        [-80.292925, 25.691304],
        [-80.292889, 25.690831],
        [-80.292877, 25.690592],
        [-80.292882, 25.690506],
        [-80.292882, 25.69042],
        [-80.292848, 25.689373],
        [-80.29101, 25.68941],
        [-80.2891958, 25.689437],
        [-80.2868394, 25.6895758],
        [-80.285571, 25.689637],
        [-80.285292, 25.689651],
        [-80.2848822, 25.6896557],
        [-80.2848771, 25.689731],
        [-80.2848749, 25.6904289],
        [-80.284878, 25.690554],
        [-80.284922, 25.691305],
        [-80.2849226, 25.6913264],
        [-80.2849297, 25.691564],
        [-80.284948, 25.692095],
        [-80.2849556, 25.6928752],
        [-80.2849736, 25.6931723],
        [-80.284978, 25.693293],
        [-80.284975, 25.693659],
        [-80.2849916, 25.6942479],
        [-80.2850063, 25.6951081],
        [-80.2850728, 25.6970762],
        [-80.2852396, 25.6988099],
        [-80.285234, 25.699256],
        [-80.2853215, 25.7007466],
        [-80.2853819, 25.7018757],
        [-80.2854442, 25.702972],
        [-80.2852721, 25.702973],
        [-80.2852696, 25.7043635],
        [-80.2854401, 25.7065498],
        [-80.285515, 25.707511],
        [-80.285553, 25.7086],
        [-80.285569, 25.709331],
        [-80.285616, 25.709912],
        [-80.2857539, 25.7117718],
        [-80.2886573, 25.7116171],
        [-80.288751, 25.71293],
        [-80.288727, 25.713089],
        [-80.288737, 25.713392],
        [-80.2897206, 25.7133539],
        [-80.2897285, 25.7137343],
        [-80.2908246, 25.7136919],
        [-80.290895, 25.71509],
        [-80.290985, 25.716905],
        [-80.290028, 25.716942],
        [-80.289155, 25.716979],
        [-80.288394, 25.717013],
        [-80.287922, 25.717029],
        [-80.287938, 25.717396],
        [-80.287971, 25.718094],
        [-80.287989, 25.718666],
        [-80.2881109, 25.7188187],
        [-80.2901304, 25.7187555],
        [-80.2925909, 25.7186621],
        [-80.2931011, 25.718632],
        [-80.2942128, 25.7185848],
        [-80.29518, 25.7185556],
        [-80.295292, 25.721434],
        [-80.296268, 25.721402],
        [-80.296267, 25.722014],
        [-80.296264, 25.722326],
        [-80.2967649, 25.7223026],
        [-80.29743, 25.722279],
        [-80.297412, 25.721574],
        [-80.297361, 25.721489],
        [-80.297362, 25.721319],
        [-80.2973038, 25.7186403],
        [-80.2973166, 25.7184357],
        [-80.2982829, 25.7184194],
        [-80.2983653, 25.7203222],
        [-80.2993596, 25.7203012],
        [-80.299365, 25.722249],
        [-80.300416, 25.722224],
        [-80.300428, 25.722539],
        [-80.300476, 25.724064],
        [-80.299225, 25.72413],
        [-80.299276, 25.725007],
        [-80.299301, 25.726003],
        [-80.300129, 25.72596],
        [-80.300337, 25.725963],
        [-80.3005884, 25.7259515],
        [-80.3025719, 25.725878],
        [-80.302552, 25.725002],
        [-80.30253, 25.724401],
        [-80.302481, 25.723659],
        [-80.302464, 25.722981],
        [-80.302446, 25.722469],
        [-80.302434, 25.72213],
        [-80.302415, 25.721682],
        [-80.302397, 25.721221],
        [-80.3023794, 25.7206937],
        [-80.3023621, 25.7202291],
        [-80.3023259, 25.7193466],
        [-80.302278, 25.71833],
        [-80.302229, 25.717196],
        [-80.302216, 25.71689],
        [-80.30215, 25.715492],
        [-80.302116, 25.714667],
        [-80.306187, 25.714512],
        [-80.3068434, 25.714553],
        [-80.297462, 25.726068],
        [-80.297444, 25.725368],
        [-80.297422, 25.724679],
        [-80.297404, 25.724213],
        [-80.297179, 25.724227],
        [-80.2968774, 25.724228],
        [-80.296351, 25.724231],
        [-80.296348, 25.724702],
        [-80.296405, 25.725398],
        [-80.296434, 25.726004],
        [-80.296433, 25.7261148],
        [-80.297462, 25.726068],
        [-80.294979, 25.726169],
        [-80.294964, 25.725826],
        [-80.294794, 25.72583],
        [-80.294802, 25.726178],
        [-80.294872, 25.726174],
        [-80.294979, 25.726169],
        [-80.293067, 25.725533],
        [-80.293053, 25.72517],
        [-80.292308, 25.725201],
        [-80.292335, 25.725567],
        [-80.293067, 25.725533],
        [-80.292283, 25.724874],
        [-80.292272, 25.72455],
        [-80.291744, 25.724567],
        [-80.291763, 25.724885],
        [-80.292283, 25.724874],
        [-80.2905417, 25.7338843],
        [-80.2905313, 25.7331749],
        [-80.2905281, 25.7329701],
        [-80.2884655, 25.7330625],
        [-80.288465, 25.7331794],
        [-80.2884319, 25.7331842],
        [-80.288458, 25.732829],
        [-80.288107, 25.732838],
        [-80.288118, 25.733082],
        [-80.287203, 25.733118],
        [-80.287184, 25.732618],
        [-80.287327, 25.732613],
        [-80.287309, 25.73209],
        [-80.287619, 25.732085],
        [-80.287607, 25.731697],
        [-80.288059, 25.73168],
        [-80.288047, 25.73161],
        [-80.288038, 25.731535],
        [-80.288012, 25.731146],
        [-80.287109, 25.731186],
        [-80.287127, 25.731574],
        [-80.286971, 25.731574],
        [-80.286949, 25.731199],
        [-80.286397, 25.73122],
        [-80.2864441, 25.7321579],
        [-80.286834, 25.732143],
        [-80.286866, 25.732108],
        [-80.287008, 25.732108],
        [-80.287019, 25.732423],
        [-80.286802, 25.73241],
        [-80.286545, 25.732422],
        [-80.2864472, 25.7323744],
        [-80.2865215, 25.7340346],
        [-80.2884043, 25.7339684],
        [-80.2905417, 25.7338843],
        [-80.290223, 25.731657],
        [-80.290212, 25.731576],
        [-80.290003, 25.73159],
        [-80.290009, 25.731688],
        [-80.289999, 25.731882],
        [-80.290223, 25.731887],
        [-80.290223, 25.731657],
        [-80.289717, 25.732002],
        [-80.289709, 25.731568],
        [-80.28956, 25.731574],
        [-80.289572, 25.732007],
        [-80.289717, 25.732002],
        [-80.2885881, 25.7264172],
        [-80.288581, 25.726063],
        [-80.28822, 25.726049],
        [-80.2882, 25.725699],
        [-80.288185, 25.725018],
        [-80.288168, 25.724683],
        [-80.2861842, 25.7247811],
        [-80.2862669, 25.7264237],
        [-80.288241, 25.726426],
        [-80.2885881, 25.7264172],
        [-80.28811, 25.722704],
        [-80.288085, 25.722157],
        [-80.288044, 25.721229],
        [-80.288026, 25.720768],
        [-80.2860353, 25.7208555],
        [-80.2861057, 25.7227124],
        [-80.28811, 25.722704]

    ],
    "Miami Beach": [
        [-80.1699989, 25.7802286],
        [-80.163688, 25.7774037],
        [-80.153135, 25.7727937],
        [-80.152429, 25.7725047],
        [-80.151888, 25.7723547],
        [-80.147759, 25.7704137],
        [-80.145982, 25.7695797],
        [-80.14447, 25.7686587],
        [-80.139253, 25.7659467],
        [-80.13946, 25.7656977],
        [-80.139157, 25.7654597],
        [-80.141862, 25.7653677],
        [-80.149117, 25.7651227],
        [-80.150168, 25.7650867],
        [-80.150268, 25.7609867],
        [-80.138966, 25.753097],
        [-80.136067, 25.751077],
        [-80.135267, 25.750577],
        [-80.131367, 25.747677],
        [-80.123667, 25.762377],
        [-80.125967, 25.770476],
        [-80.124267, 25.780176],
        [-80.123867, 25.786676],
        [-80.122585, 25.792087],
        [-80.121642, 25.79606],
        [-80.119341, 25.803413],
        [-80.119285, 25.803595],
        [-80.118614, 25.805742],
        [-80.116769, 25.811635],
        [-80.115698, 25.815129],
        [-80.11448, 25.818481],
        [-80.110192, 25.818362],
        [-80.111374, 25.850165],
        [-80.114951, 25.850492],
        [-80.114979, 25.855852],
        [-80.115208, 25.872326],
        [-80.1193411, 25.8721844],
        [-80.1193679, 25.8721835],
        [-80.119614, 25.872175],
        [-80.1203945, 25.8725552],
        [-80.1215645, 25.8725108],
        [-80.12226, 25.872487],
        [-80.1230496, 25.8720786],
        [-80.123919, 25.872072],
        [-80.1243607, 25.8719868],
        [-80.124396, 25.87198],
        [-80.1244308, 25.8724643],
        [-80.124431, 25.872467],
        [-80.1255774, 25.872417],
        [-80.1257952, 25.8724075],
        [-80.12748, 25.872334],
        [-80.129152, 25.8722615],
        [-80.129325, 25.872254],
        [-80.129413, 25.872268],
        [-80.1294365, 25.872278],
        [-80.1294439, 25.8722811],
        [-80.129453, 25.872285],
        [-80.129472, 25.872303],
        [-80.129489, 25.872335],
        [-80.1295009, 25.872378],
        [-80.129515, 25.872429],
        [-80.1295263, 25.8726302],
        [-80.129531, 25.872715],
        [-80.1295313, 25.8727447],
        [-80.1295355, 25.8728377],
        [-80.1297074, 25.8728286],
        [-80.1296871, 25.8723824],
        [-80.1296849, 25.8723382],
        [-80.1297027, 25.8723106],
        [-80.1297254, 25.872287],
        [-80.1297686, 25.8722586],
        [-80.129811, 25.8722448],
        [-80.1306336, 25.8722066],
        [-80.13083, 25.872195],
        [-80.1308401, 25.8723079],
        [-80.1308963, 25.8723053],
        [-80.1308904, 25.8721929],
        [-80.1311836, 25.8721861],
        [-80.1312947, 25.872183],
        [-80.1314136, 25.8721884],
        [-80.1314787, 25.8721976],
        [-80.131579, 25.8722216],
        [-80.131799, 25.87196],
        [-80.133399, 25.871775],
        [-80.134419, 25.871726],
        [-80.1379315, 25.8724459],
        [-80.138207, 25.871563],
        [-80.145771, 25.871651],
        [-80.147468, 25.861573],
        [-80.147816, 25.859481],
        [-80.148968, 25.852573],
        [-80.148508, 25.852174],
        [-80.1454943, 25.8495065],
        [-80.143452, 25.847583],
        [-80.143168, 25.843374],
        [-80.151868, 25.842974],
        [-80.156068, 25.827074],
        [-80.159857, 25.813792],
        [-80.160175, 25.812277],
        [-80.16064, 25.810961],
        [-80.160865, 25.8105187],
        [-80.160864, 25.8101127],
        [-80.160868, 25.8087847],
        [-80.161406, 25.8069477],
        [-80.162037, 25.8047947],
        [-80.162983, 25.8015677],
        [-80.164268, 25.7971847],
        [-80.1654928, 25.7921303],
        [-80.1653855, 25.7909131],
        [-80.167476, 25.7862527],
        [-80.1699989, 25.7802286],
        [-80.149088, 25.763904],
        [-80.149087, 25.763958],
        [-80.149077, 25.764001],
        [-80.149059, 25.76404],
        [-80.149, 25.764108],
        [-80.148952, 25.76414],
        [-80.1488901, 25.7641696],
        [-80.1484503, 25.764187],
        [-80.148048, 25.764192],
        [-80.144426, 25.7643],
        [-80.1443227, 25.7641933],
        [-80.144313, 25.7637986],
        [-80.144167, 25.763801],
        [-80.144182, 25.764191],
        [-80.14414, 25.764303],
        [-80.1440823, 25.764323],
        [-80.1429589, 25.7643541],
        [-80.141702, 25.764378],
        [-80.141673, 25.764368],
        [-80.141653, 25.76435],
        [-80.141648, 25.764332],
        [-80.1416127, 25.7630378],
        [-80.1414095, 25.7630501],
        [-80.1411584, 25.7630582],
        [-80.1412105, 25.7644065],
        [-80.1400629, 25.7644396],
        [-80.139938, 25.76441],
        [-80.139878, 25.764387],
        [-80.13982, 25.764355],
        [-80.139575, 25.76425],
        [-80.139558, 25.764242],
        [-80.139333, 25.764136],
        [-80.1392753, 25.7642495],
        [-80.139171, 25.764454],
        [-80.1386352, 25.764483],
        [-80.1386062, 25.7645399],
        [-80.138486, 25.76448],
        [-80.13848, 25.764435],
        [-80.138629, 25.764149],
        [-80.1385239, 25.7641127],
        [-80.1383903, 25.7643814],
        [-80.1372753, 25.763919],
        [-80.1360552, 25.7633958],
        [-80.1337075, 25.7624341],
        [-80.133362, 25.7622959],
        [-80.132861, 25.7620827],
        [-80.132235, 25.7618163],
        [-80.1291302, 25.7605583],
        [-80.1279219, 25.7600469],
        [-80.1279201, 25.7600198],
        [-80.1279349, 25.7599976],
        [-80.1279622, 25.7599836],
        [-80.1279908, 25.7599845],
        [-80.129173, 25.7604747],
        [-80.1322761, 25.7617519],
        [-80.1328959, 25.7620205],
        [-80.1333947, 25.7622319],
        [-80.1337179, 25.7623616],
        [-80.1338718, 25.7622391],
        [-80.1339949, 25.7621424],
        [-80.1340517, 25.7620986],
        [-80.1341039, 25.7620971],
        [-80.1341419, 25.7621071],
        [-80.1341951, 25.7620551],
        [-80.1342592, 25.7620176],
        [-80.1342775, 25.7619353],
        [-80.134401, 25.761918],
        [-80.1345409, 25.7617565],
        [-80.134653, 25.761645],
        [-80.134688, 25.761607],
        [-80.134688, 25.761481],
        [-80.134694, 25.761436],
        [-80.134741, 25.761426],
        [-80.134782, 25.761431],
        [-80.134781, 25.761455],
        [-80.134762, 25.761481],
        [-80.134756, 25.761499],
        [-80.134756, 25.761526],
        [-80.134874, 25.7614559],
        [-80.1349912, 25.7613407],
        [-80.1351137, 25.7612693],
        [-80.1351542, 25.7612008],
        [-80.1352645, 25.7610793],
        [-80.1352961, 25.7609522],
        [-80.135321, 25.760807],
        [-80.135356, 25.760808],
        [-80.135386, 25.760815],
        [-80.1354001, 25.7608274],
        [-80.1356233, 25.7605363],
        [-80.1358088, 25.7602526],
        [-80.1358457, 25.760221],
        [-80.1358465, 25.7601773],
        [-80.135761, 25.760036],
        [-80.135759, 25.76001],
        [-80.135768, 25.759993],
        [-80.135817, 25.760007],
        [-80.1358845, 25.7601272],
        [-80.1359864, 25.7600777],
        [-80.1360684, 25.7599615],
        [-80.1361371, 25.7597854],
        [-80.1362206, 25.7595959],
        [-80.1362372, 25.7594654],
        [-80.1362137, 25.7593959],
        [-80.13614, 25.759462],
        [-80.136094, 25.759465],
        [-80.136086, 25.759428],
        [-80.136092, 25.75941],
        [-80.13621, 25.759254],
        [-80.136259, 25.759131],
        [-80.136289, 25.759071],
        [-80.136302, 25.759056],
        [-80.1363434, 25.7590739],
        [-80.136356, 25.759115],
        [-80.1363289, 25.7591819],
        [-80.1363885, 25.7591826],
        [-80.1364828, 25.7591415],
        [-80.136617, 25.75886],
        [-80.1366818, 25.7585765],
        [-80.1366686, 25.7584673],
        [-80.1366391, 25.7584318],
        [-80.1366193, 25.7584637],
        [-80.1365815, 25.7584768],
        [-80.136551, 25.758458],
        [-80.136583, 25.7583083],
        [-80.1366224, 25.7582035],
        [-80.1366848, 25.7581871],
        [-80.1367076, 25.7582227],
        [-80.136697, 25.7582943],
        [-80.1367707, 25.7583172],
        [-80.1368713, 25.7582526],
        [-80.136949, 25.758135],
        [-80.1370282, 25.7579044],
        [-80.1370472, 25.7577384],
        [-80.1370373, 25.7575993],
        [-80.1370057, 25.7575563],
        [-80.136948, 25.757523],
        [-80.136906, 25.757527],
        [-80.136867, 25.757561],
        [-80.136854, 25.757589],
        [-80.136828, 25.757597],
        [-80.136813, 25.757585],
        [-80.136808, 25.757561],
        [-80.1368268, 25.7574596],
        [-80.136858, 25.757372],
        [-80.1369036, 25.7573776],
        [-80.1369176, 25.7574194],
        [-80.1369087, 25.7574732],
        [-80.1370017, 25.7574936],
        [-80.1370638, 25.7574839],
        [-80.137104, 25.75744],
        [-80.137189, 25.757314],
        [-80.137245, 25.757118],
        [-80.1372461, 25.7569503],
        [-80.1372322, 25.7567814],
        [-80.1372076, 25.7566922],
        [-80.1371081, 25.7566565],
        [-80.136999, 25.756632],
        [-80.1369396, 25.7567511],
        [-80.1369299, 25.7567553],
        [-80.1369193, 25.756758],
        [-80.1369075, 25.7567602],
        [-80.1369012, 25.7567606],
        [-80.1368955, 25.7567585],
        [-80.1368918, 25.7567553],
        [-80.1368895, 25.7567515],
        [-80.1368877, 25.7567464],
        [-80.1368854, 25.7567389],
        [-80.136885, 25.7567319],
        [-80.1368858, 25.7567219],
        [-80.1368969, 25.7566831],
        [-80.1369078, 25.7566479],
        [-80.1369126, 25.756629],
        [-80.136916, 25.756612],
        [-80.136528, 25.756481],
        [-80.1360861, 25.7563545],
        [-80.1360857, 25.7563382],
        [-80.1360908, 25.7563219],
        [-80.1360958, 25.7563063],
        [-80.1361058, 25.7562932],
        [-80.1362622, 25.7563311],
        [-80.1366015, 25.7564207],
        [-80.137099, 25.756567],
        [-80.137206, 25.756571],
        [-80.137306, 25.75656],
        [-80.137511, 25.75651],
        [-80.137671, 25.756471],
        [-80.137757, 25.756423],
        [-80.137848, 25.756417],
        [-80.13794, 25.756429],
        [-80.138022, 25.756391],
        [-80.138335, 25.756308],
        [-80.138838, 25.756191],
        [-80.13907, 25.756187],
        [-80.139185, 25.756199],
        [-80.139274, 25.756189],
        [-80.1394818, 25.7561648],
        [-80.1395377, 25.7561497],
        [-80.1397943, 25.7557165],
        [-80.1398824, 25.7555112],
        [-80.1398845, 25.7554944],
        [-80.13988, 25.755479],
        [-80.1398578, 25.7554522],
        [-80.1398296, 25.7554211],
        [-80.139775, 25.755365],
        [-80.1396502, 25.755237],
        [-80.13965, 25.7552307],
        [-80.1396521, 25.7552254],
        [-80.139655, 25.7552199],
        [-80.1396596, 25.755216],
        [-80.1396641, 25.7552121],
        [-80.13967, 25.7552096],
        [-80.1396756, 25.755209],
        [-80.1396816, 25.7552105],
        [-80.1397635, 25.7552836],
        [-80.1398885, 25.7553845],
        [-80.1399121, 25.7553924],
        [-80.1399424, 25.7553968],
        [-80.1399821, 25.7553881],
        [-80.140025, 25.755373],
        [-80.1405989, 25.7551665],
        [-80.140735, 25.7551187],
        [-80.140785, 25.755105],
        [-80.1408139, 25.7551007],
        [-80.1408282, 25.7550998],
        [-80.1408354, 25.7551005],
        [-80.1408424, 25.7551034],
        [-80.140848, 25.7551076],
        [-80.1408505, 25.7551106],
        [-80.1408524, 25.7551143],
        [-80.1408537, 25.7551184],
        [-80.1408539, 25.7551224],
        [-80.1408533, 25.755127],
        [-80.1408513, 25.7551303],
        [-80.1408473, 25.7551337],
        [-80.140766, 25.755163],
        [-80.140632, 25.7552091],
        [-80.140254, 25.755336],
        [-80.1400526, 25.7554017],
        [-80.139996, 25.755421],
        [-80.1399828, 25.7554303],
        [-80.139975, 25.755443],
        [-80.1399727, 25.755457],
        [-80.139977, 25.755471],
        [-80.140021, 25.755508],
        [-80.1401068, 25.7555861],
        [-80.140258, 25.7557276],
        [-80.1403426, 25.755803],
        [-80.140355, 25.755849],
        [-80.14031, 25.756076],
        [-80.140244, 25.756158],
        [-80.140072, 25.75616],
        [-80.140066, 25.756266],
        [-80.140246, 25.75627],
        [-80.140367, 25.756136],
        [-80.1403993, 25.7561262],
        [-80.1412366, 25.7567466],
        [-80.141242, 25.756767],
        [-80.141171, 25.756843],
        [-80.141137, 25.756845],
        [-80.141066, 25.756792],
        [-80.140989, 25.756874],
        [-80.14099, 25.756913],
        [-80.141099, 25.756993],
        [-80.141143, 25.75699],
        [-80.141324, 25.756793],
        [-80.142028, 25.757313],
        [-80.1420623, 25.7573376],
        [-80.142024, 25.757378],
        [-80.142005, 25.757392],
        [-80.141992, 25.757438],
        [-80.141949, 25.757436],
        [-80.141826, 25.757401],
        [-80.141729, 25.757474],
        [-80.141961, 25.757666],
        [-80.141981, 25.757689],
        [-80.142018, 25.757655],
        [-80.142686, 25.757852],
        [-80.142919, 25.758019],
        [-80.144189, 25.758961],
        [-80.144524, 25.759201],
        [-80.1439832, 25.759807],
        [-80.1439292, 25.7598676],
        [-80.1430442, 25.7598941],
        [-80.1431269, 25.7619909],
        [-80.1446019, 25.7619448],
        [-80.1445394, 25.7602649],
        [-80.145035, 25.7596973],
        [-80.145097, 25.759628],
        [-80.148821, 25.762374],
        [-80.149088, 25.763904]
    ],
    "Bal Harbour": [
        [-80.1301751, 25.8951671],
        [-80.1300844, 25.8933742],
        [-80.1295701, 25.891721],
        [-80.1295006, 25.8905398],
        [-80.1285504, 25.8884161],
        [-80.128129, 25.8868671],
        [-80.126189, 25.886933],
        [-80.125406, 25.886976],
        [-80.124543, 25.887004],
        [-80.123714, 25.887041],
        [-80.12274, 25.887076],
        [-80.1221423, 25.8871062],
        [-80.12176, 25.887124],
        [-80.121235, 25.887165],
        [-80.12056, 25.88722],
        [-80.115945, 25.886768],
        [-80.11884, 25.895557],
        [-80.119102, 25.899231],
        [-80.119536, 25.899712],
        [-80.121194, 25.899646],
        [-80.121508, 25.899513],
        [-80.122474, 25.899475],
        [-80.122517, 25.899445],
        [-80.1236863, 25.8993844],
        [-80.1245, 25.899351],
        [-80.12457, 25.899348],
        [-80.124704, 25.8993429],
        [-80.125948, 25.899296],
        [-80.126986, 25.899247],
        [-80.127142, 25.899217],
        [-80.12735, 25.899131],
        [-80.127492, 25.899036],
        [-80.127513, 25.89902],
        [-80.129967, 25.898972],
        [-80.130167, 25.895472],
        [-80.1301751, 25.8951671]
    ],
    "Bay Point": [
        [
            -80.17836682449618,
            25.82219517531925
        ],
        [
            -80.18673119636667,
            25.82230197229515
        ],
        [
            -80.18765068405402,
            25.819952416569166
        ],
        [
            -80.18839220638331,
            25.815039558498754
        ],
        [
            -80.18901508513962,
            25.813624403967395
        ],
        [
            -80.18542611706728,
            25.813357391781764
        ],
        [
            -80.18527781260177,
            25.814745848570695
        ],
        [
            -80.18266765400413,
            25.816454696121994
        ],
        [
            -80.1802947825513,
            25.818457220469952
        ],
        [
            -80.17875241610709,
            25.82032621264338
        ],
        [
            -80.17839648538929,
            25.821340796041824
        ],
        [
            -80.17795157199215,
            25.82198158107731
        ],
        [
            -80.17836682449618,
            25.82219517531925
        ]
    ],
    "Coconut Grove": [
        [
            -80.20236871450723,
            25.748532096488134
        ],
        [
            -80.20935474766992,
            25.75001182127987
        ],
        [
            -80.22946971291132,
            25.742223882965618
        ],
        [
            -80.23724560760664,
            25.740091801255364
        ],
        [
            -80.23816564261877,
            25.7353464069702
        ],
        [
            -80.24631021902435,
            25.734983154658433
        ],
        [
            -80.2451253143434,
            25.728074843557074
        ],
        [
            -80.25367684620682,
            25.727186400278043
        ],
        [
            -80.2679702978921,
            25.71950818536716
        ],
        [
            -80.2662597316818,
            25.712515983003847
        ],
        [
            -80.27372408943222,
            25.712148376489736
        ],
        [
            -80.2460245091145,
            25.70781543739379
        ],
        [
            -80.24859256063522,
            25.711795210535882
        ],
        [
            -80.24838711651395,
            25.71401642138885
        ],
        [
            -80.2405802051523,
            25.722808370497106
        ],
        [
            -80.23862846380437,
            25.72502942622465
        ],
        [
            -80.23708766029246,
            25.723548728979935
        ],
        [
            -80.23534140292612,
            25.72234564310665
        ],
        [
            -80.23308152257799,
            25.722808370497106
        ],
        [
            -80.23513594774204,
            25.72401144969372
        ],
        [
            -80.23862845767002,
            25.725492138720867
        ],
        [
            -80.23790937503856,
            25.728360898883253
        ],
        [
            -80.23719034216857,
            25.727250423425815
        ],
        [
            -80.23564953877619,
            25.726047391565686
        ],
        [
            -80.23380055161999,
            25.727713123365106
        ],
        [
            -80.23236246413053,
            25.726047391565686
        ],
        [
            -80.22876722173841,
            25.725029427970853
        ],
        [
            -80.22599373875504,
            25.724103995735234
        ],
        [
            -80.22815089922979,
            25.725954849934496
        ],
        [
            -80.23143797336425,
            25.7288235927423
        ],
        [
            -80.23236244898914,
            25.731229593024977
        ],
        [
            -80.22794558014107,
            25.733543064915423
        ],
        [
            -80.22168011009269,
            25.736226503521664
        ],
        [
            -80.22106373665757,
            25.734468467283207
        ],
        [
            -80.21900937318756,
            25.73345063101584
        ],
        [
            -80.21603073632008,
            25.735763853783098
        ],
        [
            -80.21808515302048,
            25.737429331948164
        ],
        [
            -80.21962580458536,
            25.7358563953226
        ],
        [
            -80.2212692760247,
            25.73668913375279
        ],
        [
            -80.21993409124424,
            25.738447093482804
        ],
        [
            -80.21757155253542,
            25.738447088318495
        ],
        [
            -80.21315449214907,
            25.738447078335938
        ],
        [
            -80.21038001358643,
            25.74076028644552
        ],
        [
            -80.2097645970998,
            25.744738699788016
        ],
        [
            -80.20236871450723,
            25.748532096488134
        ]
    ],
    "Edgewater": [
        [
            -80.18525680806292,
            25.81124943681685
        ],
        [
            -80.19050049589856,
            25.811566665310494
        ],
        [
            -80.19517568107499,
            25.791860075615546
        ],
        [
            -80.18532637948914,
            25.791158989671615
        ],
        [
            -80.18532673216106,
            25.79568775794253
        ],
        [
            -80.18646030616918,
            25.796134230354966
        ],
        [
            -80.18681463036498,
            25.79740987331995
        ],
        [
            -80.1855392362598,
            25.79760130984414
        ],
        [
            -80.18511448998879,
            25.802958857907285
        ],
        [
            -80.18695689141668,
            25.803660117564178
        ],
        [
            -80.18525650989501,
            25.804425572171496
        ],
        [
            -80.18525680806292,
            25.81124943681685
        ]
    ],
    "Key Biscayne": [[-80.1787367, 25.6862601],
    [-80.1787192, 25.6861731],
    [-80.178683, 25.686109],
    [-80.1786294, 25.6860413],
    [-80.1785415, 25.6859772],
    [-80.1784534, 25.6859329],
    [-80.178409, 25.6859594],
    [-80.1783736, 25.6860245],
    [-80.1785435, 25.6861912],
    [-80.1785667, 25.6862462],
    [-80.1785704, 25.6863217],
    [-80.1785529, 25.6863846],
    [-80.1785256, 25.6864038],
    [-80.1784644, 25.6864498],
    [-80.1783813, 25.6864873],
    [-80.1781519, 25.6865284],
    [-80.1779022, 25.6865369],
    [-80.177853, 25.6865095],
    [-80.1778016, 25.6863978],
    [-80.1778459, 25.6863197],
    [-80.1777952, 25.6862117],
    [-80.1779789, 25.6860969],
    [-80.1780661, 25.6860063],
    [-80.1779588, 25.6858806],
    [-80.1779387, 25.6858939],
    [-80.1773218, 25.6862976],
    [-80.1772038, 25.686381],
    [-80.1771005, 25.686416],
    [-80.1765989, 25.6860293],
    [-80.1761993, 25.685715],
    [-80.1755515, 25.6852195],
    [-80.1745497, 25.6844351],
    [-80.1730458, 25.6832763],
    [-80.1730029, 25.6832316],
    [-80.1727528, 25.6829216],
    [-80.172777, 25.6829026],
    [-80.1722773, 25.6825257],
    [-80.171187, 25.6817462],
    [-80.1711146, 25.6817111],
    [-80.1710314, 25.6816942],
    [-80.170951, 25.681693],
    [-80.1700082, 25.6817969],
    [-80.1699747, 25.6818139],
    [-80.1699478, 25.6818501],
    [-80.1699258, 25.6819126],
    [-80.1699277, 25.6819625],
    [-80.169945, 25.6819975],
    [-80.1699794, 25.682036],
    [-80.1700477, 25.6820777],
    [-80.1700394, 25.6820933],
    [-80.1702255, 25.6821829],
    [-80.1702364, 25.6821672],
    [-80.1703234, 25.6822257],
    [-80.1703495, 25.6822626],
    [-80.1703703, 25.6823191],
    [-80.1703669, 25.6824228],
    [-80.1702257, 25.6827619],
    [-80.1702273, 25.6827886],
    [-80.1702368, 25.6828127],
    [-80.1702597, 25.6828332],
    [-80.1702875, 25.6828428],
    [-80.1704061, 25.6828437],
    [-80.1704052, 25.6828585],
    [-80.170521, 25.6828659],
    [-80.1705219, 25.6828502],
    [-80.1708374, 25.6828609],
    [-80.1708374, 25.6828807],
    [-80.1709095, 25.6828831],
    [-80.1709118, 25.6828664],
    [-80.1710362, 25.682879],
    [-80.1711318, 25.682912],
    [-80.171207, 25.6829999],
    [-80.1712233, 25.6830421],
    [-80.1712233, 25.6830933],
    [-80.1711989, 25.6831849],
    [-80.1711501, 25.6832436],
    [-80.1710932, 25.6832875],
    [-80.1709976, 25.6833077],
    [-80.1709324, 25.683304],
    [-80.1708818, 25.6832925],
    [-80.17077, 25.6831959],
    [-80.1706805, 25.6831245],
    [-80.1706359, 25.6831073],
    [-80.1704125, 25.6830831],
    [-80.1704084, 25.6831074],
    [-80.1702528, 25.6831273],
    [-80.1701606, 25.6831717],
    [-80.1700869, 25.6832316],
    [-80.1700636, 25.6832166],
    [-80.1699518, 25.6833526],
    [-80.1699796, 25.6833711],
    [-80.1698548, 25.6836524],
    [-80.1698467, 25.683803],
    [-80.1698573, 25.6838886],
    [-80.1700042, 25.6840594],
    [-80.1700467, 25.6840816],
    [-80.170038, 25.6840955],
    [-80.1704077, 25.6842424],
    [-80.1706145, 25.6843377],
    [-80.1706304, 25.6843536],
    [-80.1707047, 25.6843836],
    [-80.1707104, 25.6843724],
    [-80.1707351, 25.684385],
    [-80.1707218, 25.6844111],
    [-80.1707685, 25.6844308],
    [-80.1707828, 25.6844042],
    [-80.1708985, 25.6844592],
    [-80.171023, 25.6845727],
    [-80.1711192, 25.6845941],
    [-80.1711409, 25.684553],
    [-80.1711819, 25.6845312],
    [-80.1713631, 25.6844862],
    [-80.1725723, 25.6850843],
    [-80.1727842, 25.6852317],
    [-80.1730602, 25.6854366],
    [-80.1733313, 25.6856518],
    [-80.1733322, 25.6856959],
    [-80.1733801, 25.6857399],
    [-80.1734096, 25.6857125],
    [-80.1735673, 25.685847],
    [-80.1735405, 25.6858736],
    [-80.1735894, 25.6859168],
    [-80.173618, 25.6858911],
    [-80.1738143, 25.6860456],
    [-80.1737848, 25.6860763],
    [-80.1738245, 25.6861104],
    [-80.1738573, 25.6860774],
    [-80.174239, 25.6863742],
    [-80.1742272, 25.6863871],
    [-80.1742355, 25.6863934],
    [-80.1742174, 25.6864153],
    [-80.1742592, 25.6864447],
    [-80.1742768, 25.6864247],
    [-80.1743022, 25.6864435],
    [-80.1743133, 25.6864306],
    [-80.1745199, 25.6865932],
    [-80.1744987, 25.6866139],
    [-80.1745917, 25.6866904],
    [-80.1746128, 25.6866686],
    [-80.1746627, 25.6867045],
    [-80.1746535, 25.6867166],
    [-80.1747696, 25.6868071],
    [-80.1747813, 25.6867942],
    [-80.174956, 25.6869205],
    [-80.1749312, 25.6869451],
    [-80.1750223, 25.6870193],
    [-80.1750408, 25.6869964],
    [-80.1754611, 25.6873331],
    [-80.1755497, 25.6873573],
    [-80.1756301, 25.6873621],
    [-80.1757642, 25.6873355],
    [-80.1759144, 25.6872751],
    [-80.1760003, 25.68735],
    [-80.1763631, 25.6873653],
    [-80.1766172, 25.6872242],
    [-80.1768123, 25.6872774],
    [-80.1769668, 25.687162],
    [-80.1770359, 25.6870667],
    [-80.1769628, 25.6869696],
    [-80.1773218, 25.6867617],
    [-80.1774854, 25.6867254],
    [-80.177649, 25.6867085],
    [-80.178168, 25.6866916],
    [-80.1783142, 25.6866674],
    [-80.1784805, 25.6866154],
    [-80.1786146, 25.6865441],
    [-80.178683, 25.6864764],
    [-80.1787112, 25.686422],
    [-80.1787286, 25.6863447],
    [-80.1787367, 25.6862601],
    [-80.1762915, 25.7100565],
    [-80.1753996, 25.7087746],
    [-80.1741609, 25.708022],
    [-80.1732973, 25.7075437],
    [-80.172094, 25.7072758],
    [-80.1692414, 25.7066572],
    [-80.1683495, 25.7058344],
    [-80.1682221, 25.7053306],
    [-80.1678894, 25.7051329],
    [-80.1672712, 25.7024153],
    [-80.1671614, 25.7021223],
    [-80.1671574, 25.7019941],
    [-80.1671167, 25.7017999],
    [-80.1670029, 25.7017303],
    [-80.1669053, 25.7016241],
    [-80.1671005, 25.7015215],
    [-80.1671086, 25.7013457],
    [-80.1669216, 25.7011002],
    [-80.1670679, 25.7010086],
    [-80.1670842, 25.7007852],
    [-80.1673485, 25.7007486],
    [-80.16769, 25.7005544],
    [-80.1677005, 25.7005329],
    [-80.1676933, 25.7004711],
    [-80.1677072, 25.7003997],
    [-80.1677498, 25.7003588],
    [-80.1678122, 25.7003333],
    [-80.1678822, 25.7003412],
    [-80.1679511, 25.700361],
    [-80.1679879, 25.7003565],
    [-80.1681175, 25.7002108],
    [-80.1681744, 25.7001456],
    [-80.1682841, 25.7000223],
    [-80.1683398, 25.6999587],
    [-80.1684517, 25.699835],
    [-80.1685093, 25.6997714],
    [-80.1686573, 25.6996094],
    [-80.168798, 25.6994544],
    [-80.1688325, 25.6994164],
    [-80.1687139, 25.6993319],
    [-80.1687561, 25.6992879],
    [-80.1687918, 25.6992506],
    [-80.168905, 25.6993351],
    [-80.1689642, 25.6992731],
    [-80.1692483, 25.6989752],
    [-80.1692494, 25.6989419],
    [-80.1692541, 25.6984321],
    [-80.169248, 25.698216],
    [-80.1685731, 25.6982209],
    [-80.16852, 25.6981587],
    [-80.1684793, 25.6980869],
    [-80.1686378, 25.6979686],
    [-80.1687395, 25.6980152],
    [-80.1695765, 25.6979705],
    [-80.1701418, 25.6975277],
    [-80.1712818, 25.6966383],
    [-80.1721065, 25.6960032],
    [-80.1724745, 25.6956237],
    [-80.1727258, 25.6952665],
    [-80.1729028, 25.6948264],
    [-80.1730585, 25.6939366],
    [-80.17297, 25.6939174],
    [-80.1737691, 25.6924278],
    [-80.1740307, 25.6922173],
    [-80.1741832, 25.6921643],
    [-80.1746887, 25.6917164],
    [-80.1750019, 25.6919941],
    [-80.1753242, 25.6916941],
    [-80.175143, 25.6915347],
    [-80.1748481, 25.6915404],
    [-80.1753874, 25.6909102],
    [-80.175537, 25.690876],
    [-80.175537, 25.690707],
    [-80.1756962, 25.6903757],
    [-80.1757624, 25.6901869],
    [-80.1758466, 25.690054],
    [-80.1758635, 25.6896287],
    [-80.1759499, 25.689494],
    [-80.1760016, 25.6891934],
    [-80.1758706, 25.6889012],
    [-80.1758999, 25.6888554],
    [-80.1758706, 25.6887722],
    [-80.1758221, 25.6887576],
    [-80.1757271, 25.6884677],
    [-80.1754969, 25.6883739],
    [-80.1754401, 25.6883265],
    [-80.1754242, 25.6883181],
    [-80.1754079, 25.6883147],
    [-80.1753899, 25.6883198],
    [-80.1753736, 25.6883298],
    [-80.1753453, 25.6883547],
    [-80.1751583, 25.6886231],
    [-80.1751807, 25.688635],
    [-80.1750041, 25.688893],
    [-80.1749833, 25.6888795],
    [-80.1748537, 25.6890695],
    [-80.1749133, 25.6891004],
    [-80.1748917, 25.6891802],
    [-80.174841, 25.6892346],
    [-80.1747188, 25.6893022],
    [-80.1745543, 25.6893588],
    [-80.1744865, 25.6895655],
    [-80.1744575, 25.6895386],
    [-80.174451, 25.6895373],
    [-80.1744448, 25.6895359],
    [-80.1744349, 25.6895974],
    [-80.1744424, 25.6895995],
    [-80.17445, 25.6896017],
    [-80.174482, 25.689591],
    [-80.174451, 25.6897466],
    [-80.174458, 25.6897796],
    [-80.1744792, 25.6898123],
    [-80.1744542, 25.6901142],
    [-80.1744464, 25.6902083],
    [-80.1744742, 25.6902106],
    [-80.1744601, 25.6907132],
    [-80.1744499, 25.6907918],
    [-80.174417, 25.6908434],
    [-80.1743612, 25.6908763],
    [-80.1743433, 25.6909078],
    [-80.1743381, 25.6910729],
    [-80.1743508, 25.6911031],
    [-80.1744208, 25.69113],
    [-80.1744059, 25.6912025],
    [-80.1743493, 25.6912427],
    [-80.1743073, 25.6912578],
    [-80.1740107, 25.6912579],
    [-80.1739507, 25.6912645],
    [-80.1738137, 25.6912648],
    [-80.173781, 25.6912649],
    [-80.1737821, 25.6912531],
    [-80.1729776, 25.6912589],
    [-80.1730445, 25.6908868],
    [-80.1731052, 25.6907864],
    [-80.1740005, 25.6904707],
    [-80.1739672, 25.6893356],
    [-80.1732714, 25.6880006],
    [-80.173144, 25.687688],
    [-80.1731086, 25.6873053],
    [-80.1716363, 25.6860997],
    [-80.1711923, 25.6850729],
    [-80.1712247, 25.6850557],
    [-80.1711771, 25.6849441],
    [-80.1711466, 25.6849527],
    [-80.1710558, 25.6847736],
    [-80.1708356, 25.6846842],
    [-80.1697222, 25.6841703],
    [-80.1693466, 25.6838496],
    [-80.1689296, 25.6831668],
    [-80.1686526, 25.6828219],
    [-80.16822, 25.6825384],
    [-80.1676785, 25.6823502],
    [-80.1670993, 25.6823435],
    [-80.1668948, 25.6824394],
    [-80.1668635, 25.6826285],
    [-80.1670609, 25.6833943],
    [-80.1677819, 25.684307],
    [-80.1690307, 25.6850418],
    [-80.1689835, 25.6851579],
    [-80.1681252, 25.6851634],
    [-80.1673879, 25.6852652],
    [-80.1672827, 25.6852603],
    [-80.1671603, 25.6852545],
    [-80.165984, 25.6852512],
    [-80.1633757, 25.6853116],
    [-80.1631728, 25.6852739],
    [-80.1628295, 25.6849363],
    [-80.161542, 25.6840518],
    [-80.1611568, 25.6837628],
    [-80.1611451, 25.6835501],
    [-80.1611413, 25.68341],
    [-80.1610247, 25.6829653],
    [-80.1597835, 25.6830815],
    [-80.1594586, 25.6831684],
    [-80.1589015, 25.683532],
    [-80.1585393, 25.6832007],
    [-80.1583182, 25.6828437],
    [-80.1582152, 25.6827122],
    [-80.1582678, 25.6826447],
    [-80.1583783, 25.6825961],
    [-80.1585499, 25.6825652],
    [-80.158713, 25.6825343],
    [-80.1588933, 25.6825265],
    [-80.159022, 25.6825884],
    [-80.1591765, 25.6826345],
    [-80.1593825, 25.6826193],
    [-80.1597172, 25.6826193],
    [-80.1610991, 25.6824956],
    [-80.1637469, 25.6822041],
    [-80.1651197, 25.6820529],
    [-80.1652889, 25.6820342],
    [-80.1690556, 25.6817221],
    [-80.1693045, 25.6816757],
    [-80.1694247, 25.6816215],
    [-80.1695277, 25.6815442],
    [-80.1696135, 25.6814745],
    [-80.1697165, 25.6814359],
    [-80.1698023, 25.6814823],
    [-80.169871, 25.6815442],
    [-80.1700083, 25.6815364],
    [-80.1701972, 25.681521],
    [-80.170283, 25.6814745],
    [-80.1703001, 25.6813585],
    [-80.1702315, 25.6812812],
    [-80.1700684, 25.6811651],
    [-80.169871, 25.6810568],
    [-80.1696135, 25.6809331],
    [-80.1692959, 25.6807397],
    [-80.1690299, 25.6805308],
    [-80.1685921, 25.6802833],
    [-80.1684119, 25.6801673],
    [-80.1682402, 25.6800435],
    [-80.1680514, 25.6798579],
    [-80.1678929, 25.6796899],
    [-80.167733, 25.6794552],
    [-80.1676625, 25.6793517],
    [-80.1674592, 25.6790534],
    [-80.1672532, 25.6787827],
    [-80.1670729, 25.6785893],
    [-80.1664034, 25.6780091],
    [-80.1654263, 25.6771351],
    [-80.1652447, 25.6769726],
    [-80.165116, 25.6769416],
    [-80.1649872, 25.6770035],
    [-80.1648671, 25.6771273],
    [-80.1647641, 25.6772201],
    [-80.164475, 25.6774056],
    [-80.1641461, 25.6775218],
    [-80.1638285, 25.6775837],
    [-80.163468, 25.6775914],
    [-80.1632267, 25.6775939],
    [-80.162953, 25.677514],
    [-80.1626355, 25.6774058],
    [-80.1624209, 25.6772588],
    [-80.1622578, 25.6771041],
    [-80.1619231, 25.6768333],
    [-80.1613308, 25.6762145],
    [-80.1612879, 25.6760907],
    [-80.1612465, 25.6759587],
    [-80.1612308, 25.6757909],
    [-80.161252, 25.6756441],
    [-80.1613652, 25.6755183],
    [-80.1614832, 25.675454],
    [-80.1615519, 25.6754197],
    [-80.1616227, 25.6753945],
    [-80.1616994, 25.6753769],
    [-80.1617783, 25.6753708],
    [-80.1618635, 25.6753745],
    [-80.1619488, 25.6753868],
    [-80.1620271, 25.6754113],
    [-80.1621022, 25.6754434],
    [-80.1621628, 25.6754771],
    [-80.1622262, 25.6755362],
    [-80.162378, 25.675673],
    [-80.1625239, 25.6758277],
    [-80.1626434, 25.6759532],
    [-80.1627063, 25.6760034],
    [-80.162754, 25.6760315],
    [-80.1628329, 25.6760443],
    [-80.1629091, 25.6760151],
    [-80.1629788, 25.6760056],
    [-80.1630904, 25.6759979],
    [-80.1631934, 25.6760211],
    [-80.1632964, 25.6760675],
    [-80.1633908, 25.6761371],
    [-80.1635453, 25.6762996],
    [-80.1637513, 25.6765007],
    [-80.1638972, 25.6766477],
    [-80.1640345, 25.6767792],
    [-80.1641633, 25.6768256],
    [-80.1643177, 25.6768333],
    [-80.1644207, 25.6767869],
    [-80.1645495, 25.6766864],
    [-80.1646267, 25.6766013],
    [-80.1646525, 25.6765084],
    [-80.1646267, 25.6763924],
    [-80.1640774, 25.6758432],
    [-80.1633221, 25.675147],
    [-80.1628754, 25.6746926],
    [-80.1626097, 25.6743811],
    [-80.1625067, 25.6741259],
    [-80.1623866, 25.6737313],
    [-80.1620776, 25.6727334],
    [-80.1616827, 25.6715499],
    [-80.1613137, 25.6707221],
    [-80.1606099, 25.6691981],
    [-80.1600399, 25.6677395],
    [-80.1596829, 25.6666762],
    [-80.1593396, 25.6660186],
    [-80.1589533, 25.6656086],
    [-80.1587257, 25.6654841],
    [-80.1584836, 25.6653986],
    [-80.1581294, 25.6653533],
    [-80.1578274, 25.6653903],
    [-80.1575201, 25.6654769],
    [-80.1570479, 25.6656962],
    [-80.1564678, 25.6660594],
    [-80.1559452, 25.6664715],
    [-80.1558706, 25.666534],
    [-80.1557821, 25.6665485],
    [-80.1557231, 25.6665122],
    [-80.1555903, 25.6664518],
    [-80.1555286, 25.666453],
    [-80.1554978, 25.6665098],
    [-80.1555152, 25.6665388],
    [-80.1556265, 25.6665775],
    [-80.1556574, 25.6666474],
    [-80.1555916, 25.6668562],
    [-80.1551197, 25.667511],
    [-80.1541472, 25.668947],
    [-80.1537496, 25.6695374],
    [-80.1533216, 25.6702275],
    [-80.1531645, 25.6706139],
    [-80.1529051, 25.671197],
    [-80.1527962, 25.6714921],
    [-80.1527484, 25.6715648],
    [-80.1526996, 25.6718423],
    [-80.1528035, 25.6720974],
    [-80.1529323, 25.6725285],
    [-80.153406, 25.6744102],
    [-80.1535271, 25.6748911],
    [-80.1537774, 25.6756011],
    [-80.1538948, 25.6761286],
    [-80.1539217, 25.6762587],
    [-80.1540898, 25.6770693],
    [-80.1541527, 25.6777484],
    [-80.1553058, 25.6829331],
    [-80.1552631, 25.6836957],
    [-80.1556635, 25.6845927],
    [-80.1561555, 25.6861806],
    [-80.1564186, 25.688284],
    [-80.1566017, 25.6898511],
    [-80.1564758, 25.6918616],
    [-80.1564682, 25.6942331],
    [-80.1564277, 25.694532],
    [-80.1565208, 25.6950679],
    [-80.156448, 25.6955089],
    [-80.1563509, 25.6958953],
    [-80.1563387, 25.696216],
    [-80.1561608, 25.6967701],
    [-80.1559626, 25.6975137],
    [-80.1555904, 25.6985197],
    [-80.1552142, 25.6999048],
    [-80.1550992, 25.7001803],
    [-80.1548704, 25.7007285],
    [-80.1545589, 25.7011623],
    [-80.1536649, 25.7028389],
    [-80.1532685, 25.7032654],
    [-80.1528802, 25.7039943],
    [-80.1525566, 25.70449],
    [-80.1523179, 25.7047707],
    [-80.1522289, 25.7050331],
    [-80.1521885, 25.7055725],
    [-80.1521723, 25.7058058],
    [-80.152148, 25.7059808],
    [-80.1522006, 25.7062979],
    [-80.1520793, 25.706932],
    [-80.1520307, 25.7073475],
    [-80.1519215, 25.7077849],
    [-80.1518932, 25.7081129],
    [-80.1517597, 25.7087034],
    [-80.1517071, 25.7090095],
    [-80.1514604, 25.7094542],
    [-80.1514401, 25.7097056],
    [-80.1514765, 25.7102268],
    [-80.1513916, 25.7108974],
    [-80.1513835, 25.7113749],
    [-80.1513471, 25.711692],
    [-80.1513511, 25.7123261],
    [-80.1511327, 25.713055],
    [-80.1508496, 25.713875],
    [-80.1504328, 25.7146691],
    [-80.1496026, 25.7156668],
    [-80.1494306, 25.7159614],
    [-80.1493194, 25.7162376],
    [-80.1492872, 25.7165342],
    [-80.1492242, 25.7168126],
    [-80.1491514, 25.7170111],
    [-80.1487799, 25.7179505],
    [-80.1486577, 25.7182993],
    [-80.148594, 25.7184132],
    [-80.1484456, 25.7186531],
    [-80.1482769, 25.7189553],
    [-80.1481997, 25.7192117],
    [-80.1481407, 25.7194791],
    [-80.148035, 25.7198435],
    [-80.1479253, 25.7203087],
    [-80.1477911, 25.7209515],
    [-80.1477057, 25.7213061],
    [-80.1476267, 25.7216765],
    [-80.1475871, 25.7220044],
    [-80.1475553, 25.7224916],
    [-80.1475573, 25.7229861],
    [-80.1475919, 25.7232772],
    [-80.1476325, 25.7235849],
    [-80.1477057, 25.7239988],
    [-80.1477037, 25.7242167],
    [-80.1477198, 25.7244691],
    [-80.1477728, 25.7247002],
    [-80.147911, 25.7250041],
    [-80.1479632, 25.7254121],
    [-80.1479541, 25.7256669],
    [-80.1479844, 25.7258673],
    [-80.1480446, 25.7259191],
    [-80.1481773, 25.726053],
    [-80.1480941, 25.7261152],
    [-80.1481361, 25.7261935],
    [-80.1482393, 25.7262627],
    [-80.1484901, 25.7265397],
    [-80.1485022, 25.7266399],
    [-80.1485427, 25.726762],
    [-80.1488197, 25.726915],
    [-80.148931, 25.7268768],
    [-80.1490604, 25.7266836],
    [-80.1491373, 25.7267346],
    [-80.1491312, 25.7267747],
    [-80.1490442, 25.7268585],
    [-80.149022, 25.7269788],
    [-80.1490942, 25.7269902],
    [-80.1492263, 25.7270171],
    [-80.1493011, 25.7271592],
    [-80.1495195, 25.7271628],
    [-80.1497921, 25.7271345],
    [-80.1500495, 25.7273767],
    [-80.1501296, 25.7276241],
    [-80.1509477, 25.7279333],
    [-80.1516456, 25.7282425],
    [-80.1526124, 25.7287836],
    [-80.153297, 25.7289543],
    [-80.1540483, 25.7287166],
    [-80.154443, 25.7284796],
    [-80.1546375, 25.7283198],
    [-80.1552839, 25.7283198],
    [-80.1560654, 25.7284265],
    [-80.15641, 25.7286186],
    [-80.1564907, 25.7285768],
    [-80.1567372, 25.7283411],
    [-80.1567358, 25.7282969],
    [-80.1563211, 25.7278805],
    [-80.156642, 25.7275532],
    [-80.1571075, 25.7273392],
    [-80.157884, 25.7270892],
    [-80.1583033, 25.7268152],
    [-80.1580865, 25.7258312],
    [-80.1579845, 25.7256074],
    [-80.1579152, 25.7255285],
    [-80.1578695, 25.725508],
    [-80.157822, 25.7255026],
    [-80.1577074, 25.7255629],
    [-80.1574144, 25.7257146],
    [-80.1561481, 25.7268803],
    [-80.1557446, 25.7268809],
    [-80.1548245, 25.7260593],
    [-80.1548239, 25.7256998],
    [-80.1555424, 25.7250396],
    [-80.1560938, 25.7245314],
    [-80.1558987, 25.7243605],
    [-80.1559197, 25.7242837],
    [-80.1559255, 25.7242269],
    [-80.1559115, 25.7241743],
    [-80.1558496, 25.7241617],
    [-80.1557749, 25.724168],
    [-80.1557084, 25.7242059],
    [-80.1551826, 25.7237475],
    [-80.1551303, 25.7237629],
    [-80.1551157, 25.7237214],
    [-80.1550792, 25.7236656],
    [-80.1549911, 25.7235899],
    [-80.1549026, 25.7235139],
    [-80.1548177, 25.723441],
    [-80.1547216, 25.7233585],
    [-80.1546356, 25.7232846],
    [-80.15455, 25.7232111],
    [-80.1544653, 25.7231384],
    [-80.1545705, 25.7229495],
    [-80.1547583, 25.7227948],
    [-80.1553162, 25.7226257],
    [-80.1554771, 25.7226547],
    [-80.1557507, 25.7225387],
    [-80.1560529, 25.7214613],
    [-80.1563676, 25.721287],
    [-80.1565446, 25.7210212],
    [-80.1567407, 25.7211179],
    [-80.1570223, 25.720927],
    [-80.1570599, 25.7204534],
    [-80.1570631, 25.7203308],
    [-80.1571551, 25.7202814],
    [-80.1571161, 25.7201586],
    [-80.157071, 25.7200404],
    [-80.1570871, 25.7200276],
    [-80.1571126, 25.7200072],
    [-80.157163, 25.7200067],
    [-80.1572101, 25.7200208],
    [-80.1574922, 25.7203141],
    [-80.1575826, 25.720342],
    [-80.1576987, 25.7202495],
    [-80.1578283, 25.7201463],
    [-80.1580742, 25.719954],
    [-80.1581413, 25.7198588],
    [-80.158121, 25.7197452],
    [-80.157836, 25.7194352],
    [-80.1579386, 25.7193012],
    [-80.1581368, 25.7193459],
    [-80.159234, 25.7186125],
    [-80.1593566, 25.7184952],
    [-80.1593531, 25.7183996],
    [-80.1594079, 25.7183151],
    [-80.1595176, 25.71828],
    [-80.1596769, 25.7180185],
    [-80.1599069, 25.7178304],
    [-80.1599547, 25.7177204],
    [-80.1600839, 25.717722],
    [-80.1602149, 25.7176837],
    [-80.1603865, 25.7175737],
    [-80.1607776, 25.7172533],
    [-80.1611423, 25.7168223],
    [-80.1612891, 25.7165991],
    [-80.1613511, 25.7165959],
    [-80.1614484, 25.7164731],
    [-80.1614449, 25.7162898],
    [-80.1616165, 25.716285],
    [-80.1617772, 25.7161252],
    [-80.1621688, 25.7160768],
    [-80.1623244, 25.7162412],
    [-80.1625175, 25.7161348],
    [-80.1625765, 25.7159318],
    [-80.1624876, 25.71568],
    [-80.1626308, 25.7156007],
    [-80.1628534, 25.7155645],
    [-80.1633416, 25.7156418],
    [-80.1634703, 25.715521],
    [-80.1636125, 25.7154992],
    [-80.1637573, 25.7154799],
    [-80.1642118, 25.7148736],
    [-80.1641955, 25.7147124],
    [-80.1644761, 25.7142253],
    [-80.164159, 25.7140531],
    [-80.1640817, 25.7138846],
    [-80.1638256, 25.7138334],
    [-80.1637199, 25.7138956],
    [-80.1632145, 25.7137483],
    [-80.1630342, 25.7137547],
    [-80.1629, 25.7138756],
    [-80.1627498, 25.7139722],
    [-80.1624628, 25.7140157],
    [-80.1623475, 25.7139819],
    [-80.1622536, 25.7139311],
    [-80.1621785, 25.7138852],
    [-80.1621222, 25.7137958],
    [-80.1621571, 25.7137233],
    [-80.1622241, 25.7136701],
    [-80.1623797, 25.7137136],
    [-80.1625353, 25.7136846],
    [-80.1626506, 25.7135662],
    [-80.1626855, 25.7134092],
    [-80.1627853, 25.7133531],
    [-80.1628172, 25.7132766],
    [-80.1628384, 25.7131796],
    [-80.162941, 25.7131076],
    [-80.1630578, 25.7130789],
    [-80.1631315, 25.7128365],
    [-80.1629374, 25.7124895],
    [-80.1630118, 25.7124188],
    [-80.1631498, 25.7123933],
    [-80.1631839, 25.7123062],
    [-80.1631994, 25.7122179],
    [-80.1631251, 25.7120904],
    [-80.1632948, 25.7120647],
    [-80.163423, 25.711867],
    [-80.1633126, 25.711679],
    [-80.1630761, 25.7115988],
    [-80.1628809, 25.7115674],
    [-80.1626473, 25.711695],
    [-80.1623854, 25.7116567],
    [-80.1618714, 25.7117332],
    [-80.1617572, 25.7117906],
    [-80.1617218, 25.7118624],
    [-80.1617996, 25.7119469],
    [-80.161897, 25.7120234],
    [-80.1618545, 25.7120473],
    [-80.1615838, 25.7118624],
    [-80.1615006, 25.7118528],
    [-80.1614493, 25.7118911],
    [-80.161428, 25.7119373],
    [-80.1614316, 25.7119804],
    [-80.1614847, 25.7120457],
    [-80.1616191, 25.7121892],
    [-80.1615094, 25.7121398],
    [-80.161444, 25.7121334],
    [-80.1613909, 25.7121414],
    [-80.1613254, 25.7121254],
    [-80.1613183, 25.7120665],
    [-80.1612652, 25.7119915],
    [-80.1612221, 25.7119555],
    [-80.1611679, 25.7119102],
    [-80.1611237, 25.711848],
    [-80.1610635, 25.7118257],
    [-80.1610246, 25.7117412],
    [-80.1609927, 25.7116487],
    [-80.1609113, 25.7116152],
    [-80.1608688, 25.7115786],
    [-80.1608264, 25.7115196],
    [-80.1608529, 25.7114542],
    [-80.1609237, 25.7113904],
    [-80.1609785, 25.7112565],
    [-80.1609718, 25.7112278],
    [-80.1612164, 25.7111913],
    [-80.1613343, 25.7111217],
    [-80.1613384, 25.7110155],
    [-80.1614238, 25.7109679],
    [-80.1615539, 25.7109642],
    [-80.1617368, 25.710891],
    [-80.1617368, 25.7107957],
    [-80.1620499, 25.7106345],
    [-80.162371, 25.7105833],
    [-80.1625215, 25.7104844],
    [-80.1630179, 25.7105986],
    [-80.1637636, 25.710453],
    [-80.1645201, 25.7103612],
    [-80.16476, 25.710053],
    [-80.1654569, 25.7101232],
    [-80.166038, 25.7096879],
    [-80.1662968, 25.7093937],
    [-80.1664632, 25.7095436],
    [-80.166721, 25.709478],
    [-80.1667158, 25.7091549],
    [-80.1670239, 25.7091216],
    [-80.1673135, 25.7087885],
    [-80.1676883, 25.7089537],
    [-80.1699219, 25.7107606],
    [-80.1707005, 25.7109094],
    [-80.1711046, 25.7105406],
    [-80.1714217, 25.710471],
    [-80.1715518, 25.7100864],
    [-80.1718039, 25.7099838],
    [-80.1718959, 25.7101654],
    [-80.1725487, 25.7109165],
    [-80.1734597, 25.7108232],
    [-80.1733398, 25.710554],
    [-80.1737362, 25.7102478],
    [-80.1735875, 25.7098971],
    [-80.1738424, 25.7098014],
    [-80.1742697, 25.7099162],
    [-80.1751165, 25.7098843],
    [-80.176055, 25.7101358],
    [-80.1762915, 25.7100565]
    ],
    "Ponce Davis": [
        [
            -80.25782663750113,
            25.702254754815385
        ],
        [
            -80.26087125146331,
            25.705068460944545
        ],
        [
            -80.27008304346607,
            25.70464616210232
        ],
        [
            -80.2693023995688,
            25.70155118034478
        ],
        [
            -80.27289340298854,
            25.70141050653899
        ],
        [
            -80.27250324884066,
            25.69078827441949
        ],
        [
            -80.2695367100539,
            25.689451609385827
        ],
        [
            -80.26508688595793,
            25.692054575874394
        ],
        [
            -80.26196421682702,
            25.69557196854315
        ],
        [
            -80.25782663750113,
            25.702254754815385
        ]
    ],
    "Sunny Isles": [[-80.1319651, 25.9380433],
    [-80.1311068, 25.9337643],
    [-80.1309459, 25.9317719],
    [-80.130219, 25.930911],
    [-80.130336, 25.930271],
    [-80.130419, 25.929815],
    [-80.130472, 25.929528],
    [-80.130177, 25.928835],
    [-80.130871, 25.928379],
    [-80.130859, 25.928218],
    [-80.130859, 25.928005],
    [-80.130792, 25.927628],
    [-80.130792, 25.92718],
    [-80.130824, 25.92674],
    [-80.130587, 25.925013],
    [-80.130515, 25.924518],
    [-80.130441, 25.923958],
    [-80.130388, 25.92345],
    [-80.130007, 25.922843],
    [-80.129575, 25.922155],
    [-80.128884, 25.921052],
    [-80.128, 25.921042],
    [-80.125163, 25.920955],
    [-80.1224386, 25.9210309],
    [-80.1213478, 25.921393],
    [-80.120987, 25.922059],
    [-80.120958, 25.922366],
    [-80.120944, 25.92244],
    [-80.120897, 25.922692],
    [-80.120876, 25.92377],
    [-80.120841, 25.924165],
    [-80.120806, 25.925595],
    [-80.120777, 25.926101],
    [-80.12072, 25.926582],
    [-80.120716, 25.926641],
    [-80.120694, 25.926995],
    [-80.120675, 25.927316],
    [-80.120623, 25.928007],
    [-80.120606, 25.928154],
    [-80.120554, 25.928631],
    [-80.120476, 25.929055],
    [-80.120443, 25.92935],
    [-80.12033, 25.930106],
    [-80.120301, 25.930299],
    [-80.120295, 25.93053],
    [-80.120269, 25.931057],
    [-80.120241, 25.931356],
    [-80.120232, 25.931452],
    [-80.120166, 25.932837],
    [-80.120147, 25.933623],
    [-80.120118, 25.934058],
    [-80.120103, 25.934949],
    [-80.120096, 25.935043],
    [-80.120086, 25.93518],
    [-80.119985, 25.935967],
    [-80.119833, 25.93688],
    [-80.119782, 25.93724],
    [-80.119754, 25.937369],
    [-80.119721, 25.937605],
    [-80.119695, 25.937795],
    [-80.119608, 25.938716],
    [-80.119582, 25.93929],
    [-80.11958, 25.939343],
    [-80.119542, 25.939804],
    [-80.119529, 25.940133],
    [-80.119491, 25.940489],
    [-80.119479, 25.940786],
    [-80.119441, 25.94118],
    [-80.119424, 25.94151],
    [-80.119371, 25.94187],
    [-80.119357, 25.942266],
    [-80.119313, 25.942726],
    [-80.119224, 25.943293],
    [-80.119195, 25.943478],
    [-80.11916, 25.943773],
    [-80.119153, 25.943938],
    [-80.11908, 25.944462],
    [-80.119072, 25.944726],
    [-80.119044, 25.944956],
    [-80.118959, 25.94591],
    [-80.118932, 25.946437],
    [-80.118926, 25.946684],
    [-80.11892, 25.946932],
    [-80.118946, 25.947351],
    [-80.118953, 25.94746],
    [-80.118952, 25.947823],
    [-80.118919, 25.948581],
    [-80.118893, 25.949208],
    [-80.11887, 25.949571],
    [-80.118839, 25.950098],
    [-80.118792, 25.950459],
    [-80.118769, 25.951317],
    [-80.118743, 25.951812],
    [-80.11874, 25.952076],
    [-80.118804, 25.952534],
    [-80.118803, 25.952633],
    [-80.118842, 25.952994],
    [-80.118852, 25.953225],
    [-80.118834, 25.953819],
    [-80.118813, 25.954227],
    [-80.11878, 25.954456],
    [-80.118748, 25.954918],
    [-80.118717, 25.955676],
    [-80.118718, 25.956435],
    [-80.1186774, 25.9571263],
    [-80.119917, 25.957121],
    [-80.120633, 25.957131],
    [-80.121044, 25.957136],
    [-80.121726, 25.957103],
    [-80.1220823, 25.9571019],
    [-80.124555, 25.957049],
    [-80.125138, 25.957027],
    [-80.125875, 25.954126],
    [-80.126413, 25.952388],
    [-80.126511, 25.951936],
    [-80.128016, 25.950617],
    [-80.128315, 25.948982],
    [-80.127409, 25.947031],
    [-80.127869, 25.944515],
    [-80.12917, 25.942777],
    [-80.129674, 25.941562],
    [-80.131294, 25.939615],
    [-80.1315896, 25.9386029],
    [-80.1319651, 25.9380433]
    ],
    "Upper East Side": [
        [
            -80.1855111707265,
            25.814422550599005
        ],
        [
            -80.1792480934911,
            25.819254439058426
        ],
        [
            -80.1781616215925,
            25.822418261385906
        ],
        [
            -80.17963148971573,
            25.829205882536982
        ],
        [
            -80.18097353927715,
            25.831909304628482
        ],
        [
            -80.1801427473993,
            25.835590473732125
        ],
        [
            -80.1790563237817,
            25.838006192998833
        ],
        [
            -80.17439166715836,
            25.841686992782073
        ],
        [
            -80.17311354815261,
            25.84548285605463
        ],
        [
            -80.17266620281293,
            25.848128384369545
        ],
        [
            -80.18423302203838,
            25.847035495509957
        ],
        [
            -80.18410522268525,
            25.84007650296914
        ],
        [
            -80.18359371398535,
            25.8320243263896
        ],
        [
            -80.1867251237343,
            25.826790018173384
        ],
        [
            -80.18870652045729,
            25.81442273261365
        ],
        [
            -80.1855111707265,
            25.814422550599005
        ]
    ],
    "Surfside": [
        [
            -80.12764571308175,
            25.88662450306147
        ],
        [
            -80.12729119051944,
            25.88455131200014
        ],
        [
            -80.12702529859719,
            25.88311600455559
        ],
        [
            -80.12906380333187,
            25.880325079028395
        ],
        [
            -80.13030463230061,
            25.876656905170137
        ],
        [
            -80.13110230806657,
            25.87546073688972
        ],
        [
            -80.13252039831669,
            25.87394557301313
        ],
        [
            -80.13243176767577,
            25.8728291240364
        ],
        [
            -80.1309250467852,
            25.87195190672459
        ],
        [
            -80.12463227130014,
            25.872350642673865
        ],
        [
            -80.12188472144086,
            25.87179241196843
        ],
        [
            -80.11931443286248,
            25.871154430788863
        ],
        [
            -80.12090978439394,
            25.887102926599127
        ],
        [
            -80.12764571308175,
            25.88662450306147
        ]
    ],
    "Manalapan": [
        [-74.408085, 40.273791],
        [-74.407905, 40.273517],
        [-74.405932, 40.270507],
        [-74.405277, 40.269532],
        [-74.404557, 40.268413],
        [-74.403923, 40.267449],
        [-74.403037, 40.266089],
        [-74.402987, 40.266019],
        [-74.402938, 40.265985],
        [-74.402766, 40.265834],
        [-74.402639, 40.265691],
        [-74.4019015, 40.2648263],
        [-74.4018914, 40.2648144],
        [-74.4016962, 40.2645808],
        [-74.400975, 40.26374],
        [-74.399965, 40.262573],
        [-74.3986581, 40.2610711],
        [-74.398424, 40.260789],
        [-74.398062, 40.260371],
        [-74.3973018, 40.2594655],
        [-74.3971706, 40.2593111],
        [-74.397116, 40.259246],
        [-74.396987, 40.259061],
        [-74.396955, 40.259033],
        [-74.396676, 40.258698],
        [-74.396617, 40.258637],
        [-74.396533, 40.258556],
        [-74.396326, 40.258326],
        [-74.396256, 40.258245],
        [-74.396161, 40.258126],
        [-74.395932, 40.257861],
        [-74.395885, 40.257789],
        [-74.39572, 40.257581],
        [-74.395652, 40.257503],
        [-74.395392, 40.257163],
        [-74.395319, 40.257049],
        [-74.395262, 40.256973],
        [-74.394943, 40.256587],
        [-74.393441, 40.25482],
        [-74.392796, 40.254061],
        [-74.391605, 40.252751],
        [-74.391377, 40.252499],
        [-74.391293, 40.252393],
        [-74.391216, 40.252283],
        [-74.391126, 40.252128],
        [-74.391079, 40.252013],
        [-74.39102, 40.251814],
        [-74.3908998, 40.2512721],
        [-74.390778, 40.250694],
        [-74.390615, 40.249897],
        [-74.390543, 40.249613],
        [-74.390476, 40.24943],
        [-74.390404, 40.24929],
        [-74.389236, 40.247258],
        [-74.389122, 40.247083],
        [-74.388919, 40.246824],
        [-74.388491, 40.246319],
        [-74.388299, 40.24611],
        [-74.388139, 40.245962],
        [-74.387598, 40.245509],
        [-74.387328, 40.245284],
        [-74.386891, 40.244902],
        [-74.386342, 40.244376],
        [-74.386088, 40.244115],
        [-74.385808, 40.243808],
        [-74.385396, 40.243353],
        [-74.385018, 40.242975],
        [-74.384835, 40.2428075],
        [-74.38471, 40.242693],
        [-74.384553, 40.242568],
        [-74.384418, 40.242475],
        [-74.384204, 40.242342],
        [-74.383859, 40.24214],
        [-74.383744, 40.24206],
        [-74.383564, 40.241966],
        [-74.383221, 40.241761],
        [-74.382839, 40.241561],
        [-74.382692, 40.241498],
        [-74.382539, 40.241443],
        [-74.382356, 40.241387],
        [-74.380882, 40.240984],
        [-74.3807815, 40.2409546],
        [-74.38039, 40.24084],
        [-74.379957, 40.240702],
        [-74.379838, 40.240647],
        [-74.379727, 40.240583],
        [-74.379616, 40.240504],
        [-74.379507, 40.240408],
        [-74.3793559, 40.2402698],
        [-74.378991, 40.239886],
        [-74.378517, 40.239394],
        [-74.377826, 40.23865],
        [-74.3776177, 40.2384196],
        [-74.377222, 40.237982],
        [-74.376714, 40.237425],
        [-74.376599, 40.237314],
        [-74.376477, 40.237208],
        [-74.376277, 40.237054],
        [-74.3754656, 40.2364915],
        [-74.375075, 40.236217],
        [-74.374221, 40.23562],
        [-74.374101, 40.235546],
        [-74.373749, 40.235287],
        [-74.37353, 40.235131],
        [-74.373166, 40.234886],
        [-74.371788, 40.233926],
        [-74.3716793, 40.2338515],
        [-74.370579, 40.233098],
        [-74.370457, 40.233007],
        [-74.370275, 40.232871],
        [-74.36967, 40.232457],
        [-74.369555, 40.232372],
        [-74.369171, 40.2321058],
        [-74.367234, 40.230763],
        [-74.3671551, 40.2307082],
        [-74.3665847, 40.2303119],
        [-74.366533, 40.230262],
        [-74.3664, 40.230152],
        [-74.3663225, 40.2300783],
        [-74.366277, 40.230035],
        [-74.364728, 40.228276],
        [-74.364345, 40.227816],
        [-74.3642724, 40.2277294],
        [-74.363646, 40.226982],
        [-74.362665, 40.225837],
        [-74.362479, 40.225594],
        [-74.362403, 40.22546],
        [-74.362311, 40.225267],
        [-74.362229, 40.224999],
        [-74.362193, 40.224806],
        [-74.362122, 40.224161],
        [-74.362047, 40.223382],
        [-74.361983, 40.222833],
        [-74.3619588, 40.2226279],
        [-74.361785, 40.221153],
        [-74.3617719, 40.2210544],
        [-74.361651, 40.220141],
        [-74.3616033, 40.2198878],
        [-74.361582, 40.219775],
        [-74.36145, 40.219362],
        [-74.361414, 40.219281],
        [-74.361391, 40.219229],
        [-74.361344, 40.219151],
        [-74.36129, 40.219076],
        [-74.361127, 40.218898],
        [-74.360783, 40.218549],
        [-74.359774, 40.2175786],
        [-74.359713, 40.21752],
        [-74.35931, 40.21714],
        [-74.358393, 40.216277],
        [-74.357133, 40.215112],
        [-74.356839, 40.21484],
        [-74.356744, 40.214746],
        [-74.35659, 40.214562],
        [-74.356409, 40.214279],
        [-74.355809, 40.213174],
        [-74.354299, 40.210324],
        [-74.354143, 40.210024],
        [-74.354055, 40.209876],
        [-74.35385, 40.209503],
        [-74.353641, 40.209136],
        [-74.353526, 40.208899],
        [-74.353382, 40.209011],
        [-74.353014, 40.209243],
        [-74.352219, 40.209759],
        [-74.350925, 40.210596],
        [-74.34982, 40.211321],
        [-74.348056, 40.212449],
        [-74.347524, 40.21279],
        [-74.346102, 40.213702],
        [-74.345229, 40.214264],
        [-74.344288, 40.214861],
        [-74.344022, 40.215022],
        [-74.342884, 40.215755],
        [-74.342563, 40.215953],
        [-74.341804, 40.216448],
        [-74.341628, 40.216542],
        [-74.341453, 40.216672],
        [-74.3406613, 40.2171773],
        [-74.3406, 40.217221],
        [-74.339452, 40.217959],
        [-74.3389125, 40.218309],
        [-74.336702, 40.219743],
        [-74.33492, 40.220898],
        [-74.334112, 40.221424],
        [-74.333131, 40.222044],
        [-74.332724, 40.222306],
        [-74.33271, 40.222347],
        [-74.329096, 40.230434],
        [-74.325233, 40.237556],
        [-74.3251, 40.237805],
        [-74.325362, 40.238438],
        [-74.325474, 40.238735],
        [-74.324909, 40.240036],
        [-74.324218, 40.240162],
        [-74.32093, 40.246709],
        [-74.320695, 40.247447],
        [-74.3204861, 40.2480356],
        [-74.320437, 40.248174],
        [-74.31965, 40.250431],
        [-74.319163, 40.25172],
        [-74.31977, 40.251802],
        [-74.320002, 40.251834],
        [-74.319907, 40.252115],
        [-74.319832, 40.252281],
        [-74.317989, 40.256347],
        [-74.317754, 40.256815],
        [-74.315783, 40.260963],
        [-74.315391, 40.26166],
        [-74.314953, 40.262233],
        [-74.312285, 40.265727],
        [-74.312279, 40.265739],
        [-74.312206, 40.265864],
        [-74.312192, 40.265876],
        [-74.311925, 40.266227],
        [-74.308716, 40.270422],
        [-74.308678, 40.270473],
        [-74.308545, 40.27065],
        [-74.306796, 40.272677],
        [-74.301993, 40.278883],
        [-74.301966, 40.278914],
        [-74.30187, 40.279035],
        [-74.301376, 40.279635],
        [-74.300795, 40.280344],
        [-74.295939, 40.286855],
        [-74.295774, 40.287052],
        [-74.295275, 40.287648],
        [-74.295126, 40.287827],
        [-74.294749, 40.288278],
        [-74.291747, 40.291871],
        [-74.290956, 40.292819],
        [-74.290227, 40.293699],
        [-74.290211, 40.293722],
        [-74.290101, 40.293889],
        [-74.290051, 40.294086],
        [-74.289621, 40.295271],
        [-74.288808, 40.297488],
        [-74.288261, 40.298952],
        [-74.286674, 40.302814],
        [-74.285011, 40.307042],
        [-74.284654, 40.307909],
        [-74.284526, 40.308234],
        [-74.28375, 40.31011],
        [-74.283697, 40.310238],
        [-74.282726, 40.312633],
        [-74.282468, 40.313272],
        [-74.284976, 40.314493],
        [-74.286632, 40.31533],
        [-74.288236, 40.316161],
        [-74.288855, 40.316628],
        [-74.289498, 40.317047],
        [-74.289967, 40.31733],
        [-74.290467, 40.317681],
        [-74.292695, 40.31924],
        [-74.293813, 40.320106],
        [-74.29619, 40.321806],
        [-74.296532, 40.322078],
        [-74.296866, 40.322065],
        [-74.296892, 40.322065],
        [-74.296999, 40.322167],
        [-74.30082, 40.325988],
        [-74.301532, 40.326495],
        [-74.302235, 40.326997],
        [-74.304336, 40.328401],
        [-74.305305, 40.32904],
        [-74.305529, 40.329187],
        [-74.308508, 40.33115],
        [-74.311598, 40.333202],
        [-74.31286, 40.33404],
        [-74.314373, 40.335033],
        [-74.317965, 40.337391],
        [-74.319077, 40.338121],
        [-74.323076, 40.340768],
        [-74.323969, 40.341619],
        [-74.32402, 40.341689],
        [-74.32427, 40.341854],
        [-74.324582, 40.342068],
        [-74.326779, 40.34407],
        [-74.327404, 40.344579],
        [-74.328078, 40.344069],
        [-74.328442, 40.343782],
        [-74.329586, 40.342868],
        [-74.331678, 40.34121],
        [-74.332033, 40.340926],
        [-74.332833, 40.340288],
        [-74.337271, 40.3369555],
        [-74.3418817, 40.3333644],
        [-74.3510881, 40.3262434],
        [-74.352544, 40.325048],
        [-74.3562653, 40.3221131],
        [-74.3580361, 40.3206739],
        [-74.3593429, 40.3196673],
        [-74.3627412, 40.3169895],
        [-74.3654594, 40.3148989],
        [-74.3702198, 40.3113026],
        [-74.3760488, 40.3065986],
        [-74.3776286, 40.305069],
        [-74.378125, 40.304484],
        [-74.378294, 40.30435],
        [-74.37859, 40.304116],
        [-74.379247, 40.303451],
        [-74.379846, 40.302874],
        [-74.381032, 40.301638],
        [-74.3811506, 40.3015402],
        [-74.381967, 40.300731],
        [-74.382212, 40.300481],
        [-74.382775, 40.299907],
        [-74.383967, 40.29872],
        [-74.385271, 40.297386],
        [-74.385301, 40.297355],
        [-74.38648, 40.296154],
        [-74.387268, 40.295361],
        [-74.387604, 40.295023],
        [-74.389415, 40.29321],
        [-74.389619, 40.292999],
        [-74.39085, 40.291726],
        [-74.391864, 40.290737],
        [-74.392298, 40.290295],
        [-74.392356, 40.290236],
        [-74.392982, 40.289599],
        [-74.393073, 40.289506],
        [-74.393242, 40.289334],
        [-74.393279, 40.289296],
        [-74.393303, 40.289272],
        [-74.393325, 40.289249],
        [-74.393586, 40.288984],
        [-74.394034, 40.288528],
        [-74.39409, 40.288482],
        [-74.394112, 40.288463],
        [-74.394434, 40.287759],
        [-74.394463, 40.287628],
        [-74.394387, 40.287541],
        [-74.394143, 40.287262],
        [-74.393744, 40.286714],
        [-74.393624, 40.286042],
        [-74.393711, 40.285306],
        [-74.393744, 40.285129],
        [-74.394183, 40.284276],
        [-74.394248, 40.283385],
        [-74.394102, 40.283353],
        [-74.394052, 40.28324],
        [-74.394044, 40.283222],
        [-74.394023, 40.283162],
        [-74.394087, 40.283112],
        [-74.394109, 40.283035],
        [-74.394119, 40.283013],
        [-74.394132, 40.282987],
        [-74.394144, 40.282964],
        [-74.394127, 40.282949],
        [-74.394072, 40.282898],
        [-74.393964, 40.282843],
        [-74.393885, 40.282816],
        [-74.3938, 40.282772],
        [-74.393791, 40.282736],
        [-74.393778, 40.282685],
        [-74.393777, 40.282586],
        [-74.393799, 40.282514],
        [-74.393655, 40.282301],
        [-74.393572, 40.282217],
        [-74.393532, 40.282177],
        [-74.393454, 40.282098],
        [-74.39323, 40.281933],
        [-74.393167, 40.28186],
        [-74.392943, 40.281605],
        [-74.392915, 40.281555],
        [-74.39292, 40.281532],
        [-74.392949, 40.281391],
        [-74.392942, 40.281204],
        [-74.392949, 40.281105],
        [-74.392984, 40.280985],
        [-74.393034, 40.280875],
        [-74.393077, 40.280743],
        [-74.393076, 40.280726],
        [-74.393065, 40.28058],
        [-74.393046, 40.280458],
        [-74.393067, 40.280372],
        [-74.393089, 40.280277],
        [-74.393097, 40.280244],
        [-74.393124, 40.280218],
        [-74.393204, 40.280138],
        [-74.393267, 40.28009],
        [-74.393372, 40.280026],
        [-74.393403, 40.280007],
        [-74.393442, 40.27994],
        [-74.393454, 40.279919],
        [-74.393449, 40.27983],
        [-74.393445, 40.279771],
        [-74.393472, 40.279697],
        [-74.393517, 40.279574],
        [-74.393595, 40.279502],
        [-74.393824, 40.279409],
        [-74.393878, 40.279406],
        [-74.394132, 40.279392],
        [-74.394328, 40.279336],
        [-74.394365, 40.279326],
        [-74.39459, 40.279226],
        [-74.394753, 40.279089],
        [-74.394799, 40.279036],
        [-74.394911, 40.278908],
        [-74.395042, 40.278796],
        [-74.395182, 40.278677],
        [-74.395268, 40.278638],
        [-74.395328, 40.278636],
        [-74.39539, 40.278633],
        [-74.395662, 40.278682],
        [-74.395725, 40.278708],
        [-74.395978, 40.278809],
        [-74.396042, 40.278835],
        [-74.396114, 40.278879],
        [-74.396229, 40.278911],
        [-74.396586, 40.278675],
        [-74.396772, 40.278603],
        [-74.397189, 40.278427],
        [-74.397619, 40.278245],
        [-74.397994, 40.278087],
        [-74.398216, 40.277993],
        [-74.398353, 40.277933],
        [-74.399413, 40.277468],
        [-74.400963, 40.276809],
        [-74.402039, 40.276362],
        [-74.403244, 40.275861],
        [-74.405218, 40.275014],
        [-74.40689, 40.27432],
        [-74.407122, 40.274223],
        [-74.407156, 40.274209],
        [-74.407262, 40.274161],
        [-74.407546, 40.274033],
        [-74.407914, 40.273868],
        [-74.40802, 40.27382],
        [-74.408085, 40.273791],
        [-74.371631, 40.298764],
        [-74.371366, 40.298874],
        [-74.368487, 40.300031],
        [-74.367963, 40.300242],
        [-74.36689, 40.300673],
        [-74.3668571, 40.3006862],
        [-74.365951, 40.30105],
        [-74.364167, 40.301767],
        [-74.362671, 40.302371],
        [-74.361393, 40.30288],
        [-74.361348, 40.302897],
        [-74.358113, 40.304174],
        [-74.357658, 40.304333],
        [-74.357532, 40.304377],
        [-74.357411, 40.304316],
        [-74.356426, 40.303548],
        [-74.355047, 40.302488],
        [-74.354993, 40.302434],
        [-74.354911, 40.302351],
        [-74.354854, 40.302082],
        [-74.354514, 40.300665],
        [-74.35451, 40.300647],
        [-74.354034, 40.298666],
        [-74.353784, 40.297625],
        [-74.353591, 40.296858],
        [-74.353424, 40.296192],
        [-74.353389, 40.296055],
        [-74.353082, 40.294833],
        [-74.353089, 40.294812],
        [-74.353169, 40.294576],
        [-74.354491, 40.291582],
        [-74.354506, 40.291547],
        [-74.35474, 40.291017],
        [-74.355446, 40.289153],
        [-74.356161, 40.289464],
        [-74.357303, 40.290014],
        [-74.36168, 40.292078],
        [-74.362967, 40.29268],
        [-74.366528, 40.294346],
        [-74.367273, 40.2947],
        [-74.3689004, 40.2955293],
        [-74.37048, 40.2962801],
        [-74.370705, 40.296318],
        [-74.370776, 40.296494],
        [-74.371519, 40.298476],
        [-74.371568, 40.2986],
        [-74.371616, 40.298725],
        [-74.371631, 40.298764]
    ],
    "Venetian Islands": [
        [
            -80.15372744419538,
            25.791466544235035
        ],
        [
            -80.15446640378421,
            25.794543734558488
        ],
        [
            -80.15622143280771,
            25.79446056830278
        ],
        [
            -80.15622143280771,
            25.791300207348016
        ],
        [
            -80.1585306815232,
            25.791300207348016
        ],
        [
            -80.15841083315607,
            25.79544927281688
        ],
        [
            -80.1609971917173,
            25.79544927281688
        ],
        [
            -80.16016586218001,
            25.790791908214644
        ],
        [
            -80.16219800104925,
            25.79120775177816
        ],
        [
            -80.16182852125462,
            25.794950278217215
        ],
        [
            -80.16423013991852,
            25.794950278217215
        ],
        [
            -80.16423013991852,
            25.791041414528067
        ],
        [
            -80.16616990883934,
            25.791041414528067
        ],
        [
            -80.166895233375,
            25.79178992705171
        ],
        [
            -80.17095951111354,
            25.791457253950867
        ],
        [
            -80.17105188106243,
            25.79070873606136
        ],
        [
            -80.17216032044549,
            25.790459229048665
        ],
        [
            -80.17216032044549,
            25.791207748512463
        ],
        [
            -80.17936513670156,
            25.791041323330404
        ],
        [
            -80.1797346284568,
            25.78971065200811
        ],
        [
            -80.17502378885251,
            25.78937802592425
        ],
        [
            -80.17179084065128,
            25.789461195745204
        ],
        [
            -80.17077477121666,
            25.789710704858805
        ],
        [
            -80.16735708311812,
            25.78937802592425
        ],
        [
            -80.16550968414573,
            25.789877043975636
        ],
        [
            -80.16495546445421,
            25.790542398111228
        ],
        [
            -80.1641241349169,
            25.788463154042304
        ],
        [
            -80.16172251625298,
            25.788047300853464
        ],
        [
            -80.16144540640718,
            25.790459229048665
        ],
        [
            -80.15978274733217,
            25.790459229048665
        ],
        [
            -80.16006865021461,
            25.786217532010312
        ],
        [
            -80.15794414139647,
            25.786217532010312
        ],
        [
            -80.1576670315507,
            25.790459231560547
        ],
        [
            -80.15618911237296,
            25.790625569627366
        ],
        [
            -80.15582977967618,
            25.787465111117697
        ],
        [
            -80.15333579106384,
            25.788629502088156
        ],
        [
            -80.15372744419538,
            25.791466544235035
        ]
    ],
    "North Bay Road": [
        [
            -80.14069803292146,
            25.79575897427783
        ],
        [
            -80.13970171673772,
            25.796778951207344
        ],
        [
            -80.13860032774733,
            25.79799941031463
        ],
        [
            -80.13860032774733,
            25.800287737270864
        ],
        [
            -80.13910866112741,
            25.805169353777785
        ],
        [
            -80.13817671659717,
            25.807076180606913
        ],
        [
            -80.13614338307659,
            25.809745686614406
        ],
        [
            -80.13860032774733,
            25.813025283077437
        ],
        [
            -80.13758366098683,
            25.816381055234316
        ],
        [
            -80.13656699422675,
            25.819202880954762
        ],
        [
            -80.13631282753673,
            25.82248221555608
        ],
        [
            -80.13309338279588,
            25.825761459334544
        ],
        [
            -80.12978921582476,
            25.829803192971838
        ],
        [
            -80.12919616021442,
            25.833387256403043
        ],
        [
            -80.1313142159653,
            25.83719997068792
        ],
        [
            -80.13139893819518,
            25.840631308508762
        ],
        [
            -80.12801004899418,
            25.84391004944453
        ],
        [
            -80.12580727101343,
            25.84642623085564
        ],
        [
            -80.12706156043832,
            25.847419044877768
        ],
        [
            -80.13190208195238,
            25.844068022555803
        ],
        [
            -80.13324683605262,
            25.838002950552436
        ],
        [
            -80.13092006140941,
            25.83467127474806
        ],
        [
            -80.1304970114745,
            25.832196254856783
        ],
        [
            -80.1316075175538,
            25.829721183206047
        ],
        [
            -80.13303531108546,
            25.828769218789688
        ],
        [
            -80.13409293592294,
            25.826103677695173
        ],
        [
            -80.13652547305028,
            25.824152082764726
        ],
        [
            -80.13790038533782,
            25.821581457618095
        ],
        [
            -80.13716004805526,
            25.819772594316674
        ],
        [
            -80.13827055413506,
            25.818630140102854
        ],
        [
            -80.13895496392384,
            25.816682112298807
        ],
        [
            -80.13998292769176,
            25.81539464126959
        ],
        [
            -80.1403404803064,
            25.81281965722623
        ],
        [
            -80.138642105386,
            25.81201496323277
        ],
        [
            -80.1373459771574,
            25.80948014142477
        ],
        [
            -80.14083211515175,
            25.806301478702338
        ],
        [
            -80.140787421075,
            25.8047724713212
        ],
        [
            -80.14002762176855,
            25.80457128467107
        ],
        [
            -80.13984884546103,
            25.799219594387438
        ],
        [
            -80.14047456253714,
            25.798575765738292
        ],
        [
            -80.1409661973825,
            25.79861600513128
        ],
        [
            -80.1409661973825,
            25.797449057186213
        ],
        [
            -80.14154722038141,
            25.796161377155954
        ],
        [
            -80.14069803292146,
            25.79575897427783
        ]
    ],
    "Indian Creek Village": [
        [
            -80.14093898564629,
            25.884282737968604
        ],
        [
            -80.1414760600471,
            25.882914400067236
        ],
        [
            -80.141933223905,
            25.880837292389657
        ],
        [
            -80.14241324595572,
            25.875325582066836
        ],
        [
            -80.142298954991,
            25.873454009994887
        ],
        [
            -80.14031029220958,
            25.872178855995642
        ],
        [
            -80.13594437736731,
            25.87281643471529
        ],
        [
            -80.1326527975912,
            25.874955383350013
        ],
        [
            -80.13150988794649,
            25.876395038509514
        ],
        [
            -80.13073270938827,
            25.87894524171874
        ],
        [
            -80.1301727771695,
            25.879799482021184
        ],
        [
            -80.13060708245919,
            25.880560413689096
        ],
        [
            -80.13307576729137,
            25.88150642987503
        ],
        [
            -80.13380722946388,
            25.88138303688946
        ],
        [
            -80.13645877983933,
            25.88154756084161
        ],
        [
            -80.13705309285477,
            25.883192787759313
        ],
        [
            -80.13812742792044,
            25.883501265253955
        ],
        [
            -80.13876745732158,
            25.883809741943523
        ],
        [
            -80.13965892684404,
            25.884282737968604
        ],
        [
            -80.14011609070191,
            25.884097652793443
        ],
        [
            -80.14093898564629,
            25.884282737968604
        ]
    ],
    "La Gorce & Allison Islands": [
        [
            -80.12589535481972,
            25.846748719844456
        ],
        [
            -80.12780278571488,
            25.84639867606822
        ],
        [
            -80.12948084413905,
            25.84421183616844
        ],
        [
            -80.13279188418585,
            25.841928317348703
        ],
        [
            -80.1326773444769,
            25.840240977378585
        ],
        [
            -80.13329664880777,
            25.83828233432054
        ],
        [
            -80.13041481450773,
            25.833537669166958
        ],
        [
            -80.13064275480953,
            25.830720623307542
        ],
        [
            -80.13239314209392,
            25.82862898601077
        ],
        [
            -80.13415385293386,
            25.826992053854127
        ],
        [
            -80.13181974159288,
            25.82423971708795
        ],
        [
            -80.13047014662094,
            25.823858763046445
        ],
        [
            -80.12824574536667,
            25.823924803396068
        ],
        [
            -80.12712370738646,
            25.82338473312464
        ],
        [
            -80.12667051124585,
            25.823432562582468
        ],
        [
            -80.12582959857251,
            25.825305997007263
        ],
        [
            -80.1257949664833,
            25.826055701282968
        ],
        [
            -80.12569497282351,
            25.82643408482916
        ],
        [
            -80.12478970064143,
            25.82664287451334
        ],
        [
            -80.12471837015747,
            25.827710840619076
        ],
        [
            -80.12438826915809,
            25.829198376317855
        ],
        [
            -80.12441117561382,
            25.830414773555702
        ],
        [
            -80.12378410135616,
            25.83434126843342
        ],
        [
            -80.12371825200819,
            25.83579351650456
        ],
        [
            -80.12365240266023,
            25.837245764575698
        ],
        [
            -80.12452916599466,
            25.84041381780885
        ],
        [
            -80.12521377050135,
            25.84252443075774
        ],
        [
            -80.12550068183339,
            25.843665174495467
        ],
        [
            -80.125036076048,
            25.843629249985554
        ],
        [
            -80.12426043410352,
            25.84382146323827
        ],
        [
            -80.12347263539866,
            25.84165236931591
        ],
        [
            -80.12287107277267,
            25.841622082108188
        ],
        [
            -80.12244833061607,
            25.842550970321952
        ],
        [
            -80.12286207726206,
            25.84531033353511
        ],
        [
            -80.12420064661725,
            25.850764350777727
        ],
        [
            -80.12528359629763,
            25.850538887257677
        ],
        [
            -80.12543688009139,
            25.8498174176722
        ],
        [
            -80.12435251560785,
            25.84425004336995
        ],
        [
            -80.12505584697051,
            25.844243570050203
        ],
        [
            -80.12589535481972,
            25.846748719844456
        ]
    ],
    "Pinetree Drive": [
        [
            -80.12490113335012,
            25.843770969616614
        ],
        [
            -80.12310169737937,
            25.839280024963948
        ],
        [
            -80.1228729514834,
            25.83423158851941
        ],
        [
            -80.12347343451341,
            25.833056373411655
        ],
        [
            -80.12370086780402,
            25.829060032559738
        ],
        [
            -80.12336652227403,
            25.82631507263849
        ],
        [
            -80.1233603259838,
            25.824992956457187
        ],
        [
            -80.12399814740164,
            25.82353984011565
        ],
        [
            -80.12444627223678,
            25.821709034229972
        ],
        [
            -80.12417523332883,
            25.818747700006774
        ],
        [
            -80.1253165257514,
            25.818975379018724
        ],
        [
            -80.12519526095706,
            25.819833778253226
        ],
        [
            -80.12539688812798,
            25.82032242811823
        ],
        [
            -80.12571168503146,
            25.821852877700593
        ],
        [
            -80.12559195412909,
            25.822691948941397
        ],
        [
            -80.12499122446444,
            25.82425084251834
        ],
        [
            -80.12461096110974,
            25.825394111194413
        ],
        [
            -80.1247901571356,
            25.82683458583392
        ],
        [
            -80.12459897870824,
            25.827872372290116
        ],
        [
            -80.12421715504678,
            25.828579886408377
        ],
        [
            -80.12431388427532,
            25.82960279138554
        ],
        [
            -80.12416677430447,
            25.832342395000822
        ],
        [
            -80.12405571038968,
            25.833204750571582
        ],
        [
            -80.12377829408302,
            25.8340626897202
        ],
        [
            -80.12374392286421,
            25.837931897355574
        ],
        [
            -80.12410727806193,
            25.839845432242925
        ],
        [
            -80.12446909873647,
            25.84128670888532
        ],
        [
            -80.12548655021924,
            25.843704793924573
        ],
        [
            -80.12490113335012,
            25.843770969616614
        ]
    ],
    "Palm Island": [
        [
            -80.15663453547356,
            25.778514255034708
        ],
        [
            -80.16669301246544,
            25.78287815974751
        ],
        [
            -80.16743791290567,
            25.78298962178384
        ],
        [
            -80.16801060228768,
            25.782634265748626
        ],
        [
            -80.16826555812528,
            25.781827195480503
        ],
        [
            -80.16766071677844,
            25.78109563010254
        ],
        [
            -80.15750009450143,
            25.776795639207137
        ],
        [
            -80.15663861826533,
            25.777107381459146
        ],
        [
            -80.15634914536666,
            25.777648472517953
        ],
        [
            -80.15642802998238,
            25.778145939704004
        ],
        [
            -80.15663453547356,
            25.778514255034708
        ]
    ],
    "Hibiscus Island": [
        [
            -80.15578615752166,
            25.782122269513508
        ],
        [
            -80.16429488515622,
            25.785890050982744
        ],
        [
            -80.16504548826023,
            25.78574050235592
        ],
        [
            -80.16548275900321,
            25.78539569076314
        ],
        [
            -80.16564922029563,
            25.784659869090788
        ],
        [
            -80.16517139730584,
            25.78402632709944
        ],
        [
            -80.15665171654953,
            25.780403653685937
        ],
        [
            -80.15579024031344,
            25.780715395937946
        ],
        [
            -80.15550076741476,
            25.781256486996753
        ],
        [
            -80.15557965203048,
            25.781753954182804
        ],
        [
            -80.15578615752166,
            25.782122269513508
        ]
    ],
    "Aventura": [
        [-80.1503189, 25.9377162],
        [-80.1498072, 25.9377297],
        [-80.1496301, 25.9382652],
        [-80.1486994, 25.9376718],
        [-80.1482166, 25.9376791],
        [-80.147986, 25.9336171],
        [-80.1478331, 25.9310916],
        [-80.1404248, 25.9382121],
        [-80.1328181, 25.9384147],
        [-80.13227, 25.938288],
        [-80.1319651, 25.9380433],
        [-80.1315896, 25.9386029],
        [-80.131294, 25.939615],
        [-80.129674, 25.941562],
        [-80.12917, 25.942777],
        [-80.127869, 25.944515],
        [-80.127409, 25.947031],
        [-80.128315, 25.948982],
        [-80.128016, 25.950617],
        [-80.126511, 25.951936],
        [-80.126413, 25.952388],
        [-80.125875, 25.954126],
        [-80.125138, 25.957027],
        [-80.1247422, 25.9586632],
        [-80.124275, 25.958434],
        [-80.1233062, 25.9750487],
        [-80.1233248, 25.9750484],
        [-80.123726, 25.9750409],
        [-80.1237998, 25.9743826],
        [-80.1339291, 25.9741728],
        [-80.1339345, 25.9747624],
        [-80.1480611, 25.9743725],
        [-80.1469348, 25.9508299],
        [-80.1470485, 25.9487852],
        [-80.1503189, 25.9377162]
    ]
}