import React, { useState, useEffect } from 'react';
import { Form, Input, Spin } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { API_BASE_URL } from '../../config';
import logo from '../../assets/images/sec4_logo.svg';

const ForgotPassword = ({ setTitle, data }) => {
    const [currentStep, setCurrentStep] = useState('email');
    const [isSpinning, setIsSpinning] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);

    // Handle email submission
    const handleEmailSubmit = async (values) => {
        setIsSpinning(true);
        try {
            const formData = new FormData();
            formData.append('email', values.email);

            await axios.post(`${API_BASE_URL}forgot_password/sendCode`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setEmail(values.email);
            setCurrentStep('resetPassword');
            toast.success('OTP sent to your email');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Something went wrong');
        } finally {
            setIsSpinning(false);
        }
    };

    // Handle combined OTP and password reset
    const handleCombinedSubmit = async (values) => {
        if (otp.length !== 6) {
            toast.error('Please enter a valid 6-digit OTP');
            return;
        }

        setIsSpinning(true);
        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('otp', otp);
            formData.append('password', values.password);
            formData.append('password_confirmation', values.confirm_password);

            await axios.post(`${API_BASE_URL}forgot_password/verifyCode`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success('Password reset successfully');
            setTitle('Sign in');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Password reset failed');
        } finally {
            setIsSpinning(false);
        }
    };

    // Handle OTP resend
    const handleResendOTP = async () => {
        setCanResend(false);
        setTimer(60);
        try {
            const formData = new FormData();
            formData.append('email', email);

            await axios.post(`${API_BASE_URL}resend-otp`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success('OTP resent successfully');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to resend OTP');
        }
    };

    // Render email form
    const renderEmailForm = () => (
        <Form
            name="forgotPassword"
            onFinish={handleEmailSubmit}
            layout="vertical"
            style={{ maxWidth: 500 }}
            labelAlign={'left'}
            autoComplete="off"
            className='mx-auto '
        >
        
                        <div className='max-w-[600px] px-2 lg:px-10 mx-auto  rounded-lg  pb-4'>
                            <div className='mt-4  my-4 text-center text-theme_yellow'>
                                <img src={logo} alt="" className='mx-auto' />
                              <h2 className='mt-4 creato-f-b text-[32px]'>Forget Password</h2>
                            </div>
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email!' }
                ]}
            >
                <Input className="p-3 placeholder:text-gray-700" placeholder="Enter your email" />
            </Form.Item>
            <Form.Item>
                <Spin spinning={isSpinning}>
                    <div className="flex justify-center mt-4">
                        <button type="submit" className='w-full btn-primary py-3 bg-theme_yellow text-white rounded-lg text-nowrap px-14 font-extrabold tracking-widest creato-f-l'>
                            Send OTP
                        </button>
                    </div>
                </Spin>
            </Form.Item>
            {!data?.forgetPassword  && <div className="text-center">
                <p className="text-theme_yellow cursor-pointer" onClick={() => setTitle('Sign in')}>
                    Back to Sign in
                </p>
            </div>}
            </div>

        </Form>
    );

    // Render combined OTP and password reset form
    const renderCombinedForm = () => (
        <Form
            name="resetPassword"
            onFinish={handleCombinedSubmit}
            layout="vertical"
        >
            <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>
            <div className="mb-10">
                <p className="mb-4 text-center">Please enter the OTP sent to your email</p>
                <div className="flex justify-center mb-4">
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType="text"
                        renderInput={(props) => (
                            <input
                                {...props}
                                className="w-12 h-12 mx-2 text-center border border-gray-300 rounded focus:outline-none focus:border-theme_yellow"
                                style={{
                                    width: "3rem",
                                    height: "3rem",
                                    margin: "0 0.5rem",
                                    fontSize: "1.2rem",
                                    borderRadius: "4px",
                                    border: "1px solid #bd1e2e9c"
                                }}
                            />
                        )}
                    />
                </div>
                {/* <div className="text-center mb-6">
                    <span>{timer > 0 ? `Resend OTP in ${timer}s` : ''}</span>
                    <button
                        type="button"
                        className="text-theme_yellow ml-2"
                        disabled={!canResend}
                        onClick={handleResendOTP}
                    >
                        Resend OTP
                    </button>
                </div> */}
            </div>

            <Form.Item
                name="password"
                rules={[
                    { required: true, message: 'Please input your new password!' },
                    { min: 6, message: 'Password must be at least 6 characters!' }
                ]}
            >
                <Input.Password className="p-3 placeholder:text-gray-700" placeholder="New Password" />
            </Form.Item>
            <Form.Item
                name="confirm_password"
                dependencies={['password']}
                rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Passwords do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password className="p-3 placeholder:text-gray-700" placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
                <Spin spinning={isSpinning}>
                    <div className="flex justify-center mt-1">
                        <button
                            type="submit"
                            className='w-full btn-primary py-3 bg-theme_yellow text-white rounded-lg text-nowrap px-14 font-extrabold tracking-widest creato-f-l'
                            disabled={otp.length !== 6}
                        >
                            Reset Password
                        </button>
                    </div>
                </Spin>
            </Form.Item>
        </Form>
    );

    return (
        <div className="max-w-[600px] px-2 lg:px-10 mx-auto rounded-lg pb-4">
            {currentStep === 'email' ? renderEmailForm() : renderCombinedForm()}
        </div>
    );
};

export default ForgotPassword;