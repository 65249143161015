import React from 'react';
import { Image } from 'antd';
const ImageViewer = ({ images, intent }) => {
    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 gap-2 md:gap-4">
                <Image.PreviewGroup
                    preview={{
                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    }}
                >
                    {images.length > 0 && (
                        <div className="md:col-span-4 row-span-2 relative">
                            <Image src={images[0]} alt="Main image" className="w-full h-full object-cover rounded-lg" />
                            <div className="absolute top-2 right-2 hidden md:flex bg-yellow-500 text-white px-2 py-1 text-sm font-bold rounded">
                                {intent ? intent : 'FOR SALE'}
                            </div>
                        </div>
                    )}

                    <div className='md:grid md:grid-cols-2 col-span-3 gap-4 flex overflow-x-auto '>
                        {images.splice(1, 4).map((image, index) => (
                            <div key={index}>
                                <Image src={image} alt={`Image ${index + 2}`} className="w-full h-full object-cover rounded-lg" />
                            </div>
                        ))}
                    </div>
                    {images.slice(5).map((image, index) => (
                        <div key={index}>
                            <Image src={image} alt={`Image ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
                        </div>
                    ))}
                </Image.PreviewGroup>
            </div>
        </div>
    );
};

export default ImageViewer;
