import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';
import { RxCaretSort } from 'react-icons/rx';

// Define dropdown items
const items = [
    {
        key: '1',
        label: 'Newest',
        value:'sortBy=OnMarketDate&order=desc'
    },
    {
        key: '2',
        label: 'Oldest',
        value:'sortBy=OnMarketDate&order=asc'
    },
    {
        key: '3',
        label: 'Most Expensive',
        value:'sortBy=OriginalListPrice&order=desc'
    },
];

const SortDropdown = ({setSortedData}) => {
    // State to track the selected item
    const [selectedLabel, setSelectedLabel] = useState(items.find(item => item.key === '1').label);

    // Handle menu item click
    const handleMenuClick = ({ key }) => {
        const selectedItem = items.find(item => item.key === key);
        if (selectedItem) {
            setSelectedLabel(selectedItem.label);
            setSortedData(selectedItem.value)
        }
    };

    return (
        <Dropdown
            menu={{
                items,
                onClick: handleMenuClick, // Function to handle click event
                selectable: true,
                defaultSelectedKeys: ['1'], // Default selected item key
            }}
            trigger={['click']}
        >
            <Typography.Link>
                <Space className='text-gray-400 creato-f-l' >
                    {selectedLabel} {/* Display the currently selected value */}
                    <RxCaretSort className='text-[24px] ml-1' />
                </Space>
            </Typography.Link>
        </Dropdown>
    );
};

export default SortDropdown;
