import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import uploadImg from '../../../assets/images/upload-img.png';
import Button from '../../buttons/Button';

const { Dragger } = Upload;

const Uploader = ({ fileList, setFileList }) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    const ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/webp', 'image/heic'];
    const MAX_FILES = 10;

    const props = {
        name: 'file',
        multiple: true,
        fileList,
        onChange(info) {
            const { file, fileList: newFileList } = info;

            // Remove excess files if limit exceeded
            if (newFileList.length > MAX_FILES) {
                message.error(`You can only upload up to ${MAX_FILES} files.`);
                return;
            }

            // Display server responses for file status
            if (file.status === 'done') {
                message.success(`${file.name} file uploaded successfully.`);
            } else if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }

            // Update the file list state
            setFileList(newFileList);
        },
        onRemove(file) {
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
        },
        beforeUpload(file) {
            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
                message.error(`${file.name} exceeds the maximum size of 10MB.`);
                return false;
            }

            // Validate file format
            if (!ALLOWED_FORMATS.includes(file.type)) {
                message.error(`${file.name} has an unsupported file format.`);
                return false;
            }

            // Prevent actual upload (since no server endpoint is provided)
            return false;
        },
        className: 'uploader-properties',
    };

    return (
        <Dragger {...props}>
            <div className="flex justify-center">
                <div className="w-[200px] lg:w-[10vw] hidden md:flex h-[200px] lg:h-[10vw] bg-theme_blue text-theme_yellow flex-col justify-center items-center rotate-[-10deg] border border-white rounded-lg">
                    <p className="text-[12px] lg:text-[0.7vw]">Drag files here to upload.</p>
                    <img src={uploadImg} alt="" className="object-cover max-w-[100px] lg:max-w-[5vw] mt-4" />
                </div>
                <div className="w-[200px] lg:w-[10vw] h-[200px] lg:h-[10vw] bg-theme_blue text-theme_yellow flex flex-col justify-center items-center md:ml-[-20px] border border-white rounded-lg">
                    <img src={uploadImg} alt="" className="object-cover max-w-[100px] lg:max-w-[5vw] mt-4" />
                </div>
            </div>
            <div className="p-4">
                <p className="ant-upload-text">or</p>
                <Button title="Select files" className="py-2 px-8 rounded-full mt-4" />
            </div>
        </Dragger>
    );
};

export default Uploader;
