import React, { useState } from 'react';
import { useData } from '../../context/DataContext';
const AuthModal = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('Sign in')
    const { setKeyData, data } = useData()

    const showModal = () => {
        setKeyData('isLoginModalOpen', true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div onClick={showModal}>{children}</div>
        </>
    );
};
export default AuthModal;