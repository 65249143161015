import { toast } from "react-toastify"

export const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    bodyClassName: "toastStyle z-[10000]",
}


export function errorAlert(err) {
    if (err?.response && err?.response?.data && err?.response?.data?.data) {
        return Object.values(err?.response?.data?.data).forEach(errmsg => toast.error(errmsg[0]))
    } else {
        return toast.error(err?.response?.data?.message)
    }
}
export const formatDateYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function groupBy(array, key) {
    return array.reduce((result, item) => {
        const groupKey = item[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(item);
        return result;
    }, {});
}


export const makeQueryFromObj = (filterValue) => {
    const filterQuery = Object.values(filterValue).filter(v => v)
    return (filterQuery.join('&'))
}

export const isLoginHandle = () => {
    // Retrieve user data
    let loginData = localStorage.getItem('user_data');
    let listingIds = JSON.parse(localStorage.getItem('listingIds')) || []; // Retrieve the IDs array

    try {
     
            if (listingIds.length >= 3) {
              return loginData = JSON.parse(loginData); 
            } else {
                return true;
            }
        }

        // If no login data exists, return false
      catch (error) {
        console.error("Error parsing data:", error);
        return false; // Return false in case of an error
    }
};
