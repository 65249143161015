import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Spin, Upload } from 'antd';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
// import baseUrl from "../../lib/endpoint.json";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Header from '../ui/header/Header'
import logo from '../../assets/images/sec4_logo.svg';
import Button from '../buttons/Button';
import SocialLogin from './SocialLogin';
import { postApi } from '../../api/POST';
import { API_BASE_URL } from '../../config';
import ForgotPassword from './ForgetPassword';
import { errorAlert } from '../../lib/helper';
import { useData } from '../../context/DataContext';
export const LoginSignUp = ({ title, setTitle, handleClose }) => {
    const [fileList, setFileList] = useState([]);
    const [isOTP, setisOTP] = useState(null);
    const [otp, setOtp] = useState('');
    const [isSpinning, setisSpinning] = useState(false);
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const navigate = useNavigate();
    const { setKeyData, data } = useData()

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    useEffect(() => {
        setisOTP(localStorage.getItem('otp'));
    }, []);

    useEffect(() => {
        let interval;
        if (isOTP) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isOTP]);

    const onFinish = async (values) => {
        setisSpinning(true);
        if (title === 'Sign up with email') {
            if (values.password !== values.c_password) {
                setisSpinning(false);
            } else if (!values.password?.trim() || !values.c_password?.trim()) {
                setisSpinning(false);
            } else {
                const formData = new FormData();
                formData.append('first_name', values.first_name);
                formData.append('last_name', values.last_name);
                formData.append('email', values.email);
                formData.append('password', values.password);
                formData.append('c_password', values.c_password);
                formData.append('user_type', 'user');

                axios.post(`${API_BASE_URL}register`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        setisSpinning(false);
                        localStorage.setItem('signup_email', res.data.data.email);
                        setTitle('Sign in')
                    })
                    .catch((err) => {
                        errorAlert(err);
                        setisSpinning(false);
                    });
            }
        } else if (title === 'Sign in') {
            const formData = new FormData();
            formData.append('email', values.email);
            formData.append('password', values.password);

            axios.post(`${API_BASE_URL}login`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    localStorage.setItem('user_data', JSON.stringify(res?.data?.data));
                    setKeyData('isLogin', res?.data?.data)
                    setisSpinning(false);
                    toast.success('Logged in Successfully');
                    handleClose()
                })
                .catch((err) => {
                    toast.error(err.response?.data?.data?.error);
                    setisSpinning(false);
                });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const email = localStorage.getItem('signup_email') ? localStorage.getItem('signup_email') : '';
    let email_length = email.length;
    let first = email.substring(0, 5);
    let last = email.substring(email_length - 4, email_length);
    let final = first + '*********' + last;

    const onOTPChange = (val) => {
 
    };

    const resendOTP = () => {
        setCanResend(false);
        setTimer(60);
        const email = localStorage.getItem('signup_email');
        const formData = new FormData();
        formData.append('email', email);

    };

    return (
        <>
            {
                isOTP ? (
                    <div className='mt-11'>
                        <h2 className='poppins-bold text-[40px]'>Verify OTP</h2>
                        <div className='max-w-[600px] text-center border-theme_yellow2 border shadow-2xl mx-auto my-10 rounded-lg p-4'>
                            <h5 className="mt-5 mb-3">Please enter the one-time password to verify your account</h5>
                            <div> <span>A code has been sent to</span> <small className='onlyColor' style={{ fontWeight: 700 }}>{final}</small></div>
                            <div id="otp" className="inputs d-flex flex-row justify-content-center mt-4">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                        width: "3rem",
                                        height: "3rem",
                                        margin: "0 1rem",
                                        fontSize: "1.2rem",
                                        borderRadius: 4,
                                        fontWeight: 400,
                                        outline: "none",
                                        boxShadow: "0px 2px 20px rgba(0,0,0,0.07)",
                                        border: "1px solid #bd1e2e9c"
                                    }}
                                    separator={<span>-</span>}
                                    renderInput={(props) => <input className='p-3 placeholder:text-gray-700' {...props} />}
                                />
                            </div>
                            <div className="mt-4">
                                <button className="bg-theme_yellow2 text-white text-lg py-2 px-4 rounded-lg focus:outline-none w-full" disabled={otp.length !== 4} onClick={() => onOTPChange(otp)}>Validate</button>
                            </div>
                            <div className="mt-4">
                                <span>{timer > 0 ? `Resend OTP in ${timer}s` : ''}</span>
                                <button style={{ color: '#FCB527' }} className="px-3" disabled={!canResend} onClick={resendOTP}>Resend OTP</button>
                            </div>
                        </div>
                    </div>
                ) : title === 'forgot_password' ? (
                    <ForgotPassword setTitle={setTitle} data={data} />
                ) : (
                    <Form
                        name="basic"
                        style={{ maxWidth: 500 }}
                        labelAlign={'left'}
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='mx-auto '
                    >
                        <div className='max-w-[600px] px-2 lg:px-10 mx-auto  rounded-lg  pb-4'>
                            <div className='mt-4  my-4 text-center text-theme_yellow'>
                                <img src={logo} alt="" className='mx-auto' />
                                {title === 'Sign in' ? <h2 className='mt-4 creato-f-b text-[32px]'>Sign into LuxLife</h2> : title === 'Sign up' ? <h2 className='mt-4 creato-f-b text-[32px]'>Sign up</h2> : ""}
                            </div>
                            <SocialLogin title={title} setTitle={setTitle} handleClose={handleClose} />
                            <>{title === 'Sign in' ? <>
                                <Form.Item
                                    label={false}
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your Email!' }]}
                                >
                                    <Input className='p-3 placeholder:text-gray-700' placeholder='Email' />
                                </Form.Item>
                                <Form.Item
                                    label={false}
                                    name="password"
                                    rules={[
                                        { required: true, message: 'Please input your password!' },
                                    ]}
                                >
                                    <Input.Password className='p-3 placeholder:text-gray-700' />
                                </Form.Item>
                                <Form.Item>
                                    <Spin spinning={isSpinning}>
                                        <div className='flex justify-center mt-4'>
                                            <button type='submit' className='w-full btn-primary py-3 bg-theme_yellow text-white rounded-lg text-nowrap px-14 font-extrabold tracking-widest creato-f-l'>Login</button>

                                        </div>
                                    </Spin>
                                </Form.Item>
                                {title === 'Sign in' && (
                                    <div className=' text-center text-[#707070]'>
                                        <p className='mb-4 creato-f-m cursor-pointer' onClick={() => setTitle('forgot_password')}>Forgot Password?</p>
                                        <p >Don't have an account? </p>
                                        <p className='text-theme_yellow creato-f-m tracking-wider md:text-[1.2vw] cursor-pointer' onClick={() => setTitle('Sign up')} >Sign up </p></div>
                                )}</> : title === 'Sign up' ? <>
                                    <div className=' text-center text-[#707070]'>
                                        <p >Already have an account? </p>
                                        <p className='text-theme_yellow creato-f-m tracking-wider md:text-[1.2vw] cursor-pointer' onClick={() => setTitle('Sign in')} >Sign in </p></div>

                                </> : title === 'Sign up with email' ? <>
                                    <div className="grid grid-cols-2 gap-4">
                                        <Form.Item
                                            name="first_name"
                                            rules={[{ required: true, message: 'Please input your First Name!' }]}
                                        >
                                            <Input className='p-3 placeholder:text-gray-700' placeholder="First Name" />
                                        </Form.Item>
                                        <Form.Item
                                            name="last_name"
                                            rules={[{ required: true, message: 'Please input your Last Name!' }]}
                                        >
                                            <Input className='p-3 placeholder:text-gray-700' placeholder="Last Name" />
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input className='p-3 placeholder:text-gray-700' placeholder="Email" />
                                    </Form.Item>
                                    <div className="grid grid-cols-2 gap-4">
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password className='p-3 placeholder:text-gray-700' placeholder="Password" />
                                        </Form.Item>
                                        <Form.Item
                                            name="c_password"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password className='p-3 placeholder:text-gray-700' placeholder="Confirm Password" />
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        name="agreement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                            },
                                        ]}
                                    >
                                        <Checkbox>
                                            I agree to the <Link to='/terms-of-service' className="text-blue-600">Terms and Conditions</Link>
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Spin spinning={isSpinning}>
                                            <div className='flex justify-center mt-4'>
                                                <button type='submit' className='w-full btn-primary py-3 bg-theme_yellow text-white rounded-lg text-nowrap px-14 font-extrabold tracking-widest creato-f-l'>Sign up</button>

                                            </div>
                                        </Spin>
                                    </Form.Item>
                                    <div className=' text-center text-[#707070]'>
                                        <p >Already have an account? </p>
                                        <p className='text-theme_yellow creato-f-m tracking-wider md:text-[1.2vw] cursor-pointer' onClick={() => setTitle('Sign in')} >Sign in </p></div>
                                </> : <></>}</>
                        </div>
                    </Form>
                )
            }

        </>
    );
};
