import axios from "axios"
import { API_BASE_URL } from "../../config"

const token = JSON.parse(localStorage.getItem('user_data'))?.token;

export const getHomeProperties = async () => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?limit=15&sortBy=OriginalListPrice&order=desc&OriginalListPrice.gte=1000000&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?limit=15&sortBy=OriginalListPrice&order=desc&OriginalListPrice.gte=1000000&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getProperties = async () => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?sortBy=OnMarketDate&order=desc&OriginalListPrice.gte=1000000`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&OriginalListPrice.gte=1000000`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getCondos = async () => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?sortBy=OnMarketDate&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);
        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getHome = async () => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?sortBy=OnMarketDate&order=desc&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);
        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}

export const getListingPropertyData = async (query) => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?${query}&sortBy=OnMarketDate&order=desc&ListingAgreement=Exclusive Right To Sell&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&ListingAgreement=Exclusive Right To Sell&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingReferencePropertyData = async (query) => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?${query}&sortBy=OnMarketDate&order=desc&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingData = async (query, onlyQuery) => {
    try {
        const response = token ? await axios.get(onlyQuery ? `${API_BASE_URL}login_listings?${query}` : `${API_BASE_URL}login_listings?PropertySubType.in=Condominium${query}&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(onlyQuery ? `${API_BASE_URL}listings?${query}` : `${API_BASE_URL}listings?PropertySubType.in=Condominium${query}&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getExclusiveListingData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}Exclusiveslistings?StandardStatus=Active${query}&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getListingExpensiveData = async (query) => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?${query}&limit=100&sortBy=OriginalListPrice&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?${query}&limit=100&sortBy=OriginalListPrice&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingHomeData = async (query) => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingCondosData = async (query) => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingVillaData = async (query) => {
    try {
        const response = token ? await axios.get(`${API_BASE_URL}login_listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Townhouse&ListingAgreement=Exclusive Right To Sell`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }) : await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Townhouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getListingByID = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings/${id}`);

        return response

    } catch (error) {

    }

}

export const getPropertyTypes = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}get-property-sub`);

        return response

    } catch (error) {

    }

}
export const getAllCities = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}get-cities`);

        return response

    } catch (error) {

    }
}
export const getSinglePreConstruction = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}getPreConstruction/${id}`);

        return response

    } catch (error) {

    }
}
export const getDefaultSearchData = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}Searchlistings`);

        return response

    } catch (error) {

    }
}