import React from 'react'

const ButtonChatBox = ({ className, title, ...props }) => {
    return (
        <button className={`p-4 bg-theme_yellow text-white rounded-2xl text-nowrap ${className}`}

            {...props}>
            {title}
        </button>
    )
}

export default ButtonChatBox