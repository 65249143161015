import React, { useEffect, useState } from 'react';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import Button from '../components/buttons/Button';
import { IoIosArrowForward } from 'react-icons/io';
import AnimationDiv, { slideLeft } from '../components/animation/AnimationDiv';
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs';
import Footer from '../components/ui/sections/FooterSection/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { Helmet } from 'react-helmet';

const BlogPost = ({ image, title, date, content }) => {
    const truncateTitle = (text) => (text.length > 45 ? text.substring(0, 45) + '...' : text);

    return (
        <div className="rounded-lg overflow-hidden shadow-lg group cursor-pointer">
            <img
                src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${image}`}
                alt={title}
                className="w-full max-h-48 object-cover group-hover:scale-[1.06] transition duration-500"
            />
            <div className="p-4">
                <h3 className="creato-f-black lg:text-[1.2vw] lg:leading-[1.4vw] font-bold text-theme_yellow mb-2">
                    {truncateTitle(title)}
                </h3>
                <div className="flex items-center text-gray-500">
                    <span className="text-sm">{new Date(date).toLocaleDateString()}</span>
                </div>
            </div>
        </div>
    );
};


const BlogPostsUI = ({ blogPosts, error, loading }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 7;

    const totalPages = Math.ceil(blogPosts.length / postsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (loading) return <Spin spinning={true} className="h-[100vh] w-[100vw] flex justify-center items-center"></Spin>;
    if (error) return <div>{error}</div>;

    const currentPosts = blogPosts.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage
    );

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl lg:text-[1.5vw] tracking-wide font-bold creato-f-black text-theme_yellow">
                    Latest Blog Posts
                </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {currentPosts.map((post) => (
                    <Link to={`/blog/${encodeURIComponent(post.link)}`} state={post} key={post.id}>
                        <BlogPost
                            image={post.image1}
                            title={post.title}
                            date={post.created_at}
                            content={post.content}
                        />
                    </Link>
                ))}
            </div>
            {/* Pagination */}
            <div className="flex justify-center mt-8">
                <div className="pagination-container">
                    <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="px-5 py-3 creato-f-r border border-gray-300 rounded-full ml-2 mr-2"
                    >
                        «
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`px-5 py-3 creato-f-r border border-gray-300 rounded-full ml-2 ${
                                currentPage === index + 1 ? 'bg-theme_yellow text-white' : ''
                            }`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="px-5 py-3 creato-f-r border border-gray-300 rounded-full ml-4"
                    >
                        »
                    </button>
                </div>
            </div>
        </div>
    );
};




const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await axios.get('https://www.luxlifemiamirealestate.com/api/api/blog/active');
                const sortedBlogPosts = await response?.data?.data.sort((a, b) => b.id - a.id);

                setBlogPosts(sortedBlogPosts);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch blog posts');
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    return (
        <>
 <Helmet>
                    <title>Blogs - Luxlife Miami Real Estate LLC</title>
                </Helmet>
            <div className="relative w-full min-h-[500px]  md:h-[50vh] overflow-hidden">
                <div className='fixed top-0 z-[10000] w-full'>
                    <Header />
                </div>
                {/* Image with full width and object cover to maintain aspect ratio */}
                <img src={sec5logo} alt="Hero Image" className=" w-full absolute h-full object-cover" />

                {/* Overlay with semi-transparent black background */}
                <div className="absolute inset-0 gradient-background "></div>

                {/* Centered text block */}
                <div className="absolute bottom-[calc(50%-80px)] text-center md:left-6 text-white px-4 md:px-8 w-full">
                    <div>
                        {/* Animated Heading */}
                        <h1 className="creato-f-black text-[48px] lg:text-[4.6vw] mb-6">Blog</h1>

                    </div>

                </div>
            </div >


            <div className="  mx-auto  rounded-t-xl relative">
                <div className='mt-[-16%] md:mt-[-8%] w-full rounded-t-[70px] bg-white p-4 md:p-12'>

                    <div className=" container lg:max-w-[75%] mx-auto p-6 ">

                        {blogPosts[0] && <div className="flex flex-col justify-center md:flex-row gap-6 lg:gap-16 mt-16 text-theme_blue">
                            {/* Left column with image and title */}
                            <AnimationDiv variants={slideLeft}
                                initial="hidden"
                                animate="visible" className="md:w-1/2 md:max-w-[25.5vw]  group  ">
                                    <Link to={`/blog/${encodeURIComponent(blogPosts[0]?.link)}`} state={blogPosts[0]}>
                                <img
                                    src={blogPosts[0]?.image1 ? `https://www.luxlifemiamirealestate.com/api/storage/app/public/${blogPosts[0]?.image1}` : sec5logo}
                                    alt="Mercedes-Benz Places Building"
                                    className="w-full rounded-lg shadow-md md:max-w-[35.5vw] h-[600px] lg:h-[30vw] object-cover group-hover:scale-[1.06] transition duration-500"
                                    />
                                    </Link>
                            </AnimationDiv>

                            {/* Right column with description and features */}
                            <AnimationDiv variants={slideLeft}
                                initial="hidden"
                                animate="visible" className="md:w-1/2 pt- creato-f-m mb-8 md:text-[1vw]">
                                <p className='mb-2'><span className=''>{new Date(blogPosts[0]?.created_at).getFullYear()}</span></p>
                                <Link to={`/blog/${encodeURIComponent(blogPosts[0]?.link)}`} state={blogPosts[0]}><h2 className='creato-f-black text-[2.4] md:leading-[2.4vw] md:text-[2vw]'>{blogPosts[0]?.title}</h2></Link>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: (blogPosts[0]?.content.substring(0, 1110) + ' ...') }}>
                                </p>

                                <Link to={`/blog/${encodeURIComponent(blogPosts[0]?.link)}`} state={blogPosts[0]}> <Button title={<div className='flex items-center gap-4'><span>Read More</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_yellow px-6 text-white rounded-full py-2 creato-f-m  tracking-wide mt-6'} />
                                </Link>
                            </AnimationDiv>
                        </div>}
                        <BlogPostsUI blogPosts={blogPosts.slice(1, blogPosts.length)} loading={loading} error={error} />
                    </div>


                </div>
                <div className='bg-[#FEE8E8] mt-10'>

                </div>
            </div >
            <section>
                <ContactUs className="mt-0" />
            </section>
            <Footer />
        </>
    );
};

export default Blog;
