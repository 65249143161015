import React, { useEffect, useState } from 'react';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs';
import Footer from '../components/ui/sections/FooterSection/Footer';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState({});
    const [allBlogs, setAllBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { state } = useLocation();
    const { id } = useParams();

    useEffect(() => {
        // Fetch the main blog post
        const fetchBlogPost = async () => {
            try {
                if (!state) {
                    const response = await axios.get(`https://www.luxlifemiamirealestate.com/api/api/blog/show/${id}`);
                    setBlogPosts(response.data.data);
                } else {
                    setBlogPosts(state);
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch the blog post');
                setLoading(false);
            }
        };

        fetchBlogPost();

        // Fetch all active blog posts for the sidebar
        const fetchAllBlogs = async () => {
            try {
                const response = await axios.get('https://www.luxlifemiamirealestate.com/api/api/blog/active');
                setAllBlogs(response.data.data || []);
            } catch (err) {
                console.error('Failed to fetch all blogs:', err);
            }
        };

        fetchAllBlogs();
    }, [id, state]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const { title, meta_description, meta_tags } = blogPosts;
    const metaTagsArray = meta_tags ? meta_tags.split(",").map((tag) => tag.trim()) : [];








    return (
        <>
            <div className="relative w-full min-h-[500px] md:h-[50vh] overflow-hidden">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={meta_description} />
                    <meta name="keywords" content={metaTagsArray.join(", ")} />
                </Helmet>
                <div className="fixed top-0 z-[10000] w-full">
                    <Header />
                </div>
                <img src={blogPosts.image1 ? `https://www.luxlifemiamirealestate.com/api/storage/app/public/${blogPosts.image1}` : sec5logo} alt="Hero Image" className="w-full absolute h-full object-cover" />
                <div className="absolute inset-0 gradient-background"></div>
                <div className="absolute bottom-[calc(50%-80px)] text-center md:left-6 text-white px-4 md:px-8 w-full">
                    <h1 className="creato-f-black text-[48px] lg:text-[4.6vw] mb-6">
                        {blogPosts.title ?? 'Blog'}
                    </h1>
                </div>
            </div>

            <div className="mx-auto rounded-t-xl relative " style={{
                fontFamily: 'Poppins',
                color: ' #2c3e50',

            }}>
                <div className="mt-[-16%] md:mt-[-8%] w-full rounded-t-[70px] bg-white p-4 md:p-12">
                    <div className="container lg:max-w-[95%] mx-auto p-6">
                        <div className="flex flex-col md:flex-row px-6 py-10 max-w-6xl mx-auto">
                            {/* Main content */}
                            <div className="w-full">
                                <p className="text-gray-500 text-sm">{new Date(blogPosts?.updated_at ? blogPosts?.updated_at : blogPosts?.created_at)?.toLocaleString('en-US', {
                                    timeZone: 'UTC',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric'
                                })}</p>
                                <div
                                    className="text-rich-content prose"
                                    dangerouslySetInnerHTML={{ __html: blogPosts?.content }}
                                ></div>
                            </div>

                            {/* Sidebar */}
                            <div className="w-full md:w-1/3 md:pl-8 mt-10 md:mt-0">
                                <h3 className="text-xl font-bold mb-6">More From Our Blog</h3>
                                <div className="space-y-6">
                                    {allBlogs.map((blog) => (
                                        <div key={blog.id} className="flex items-start space-x-4">
                                            <img
                                                src={blog.image1 ? `https://www.luxlifemiamirealestate.com/api/storage/app/public/${blog.image1}` : "https://via.placeholder.com/150"}
                                                alt={blog.title}
                                                className="w-20 h-20 object-cover"
                                            />
                                            <div>
                                                <p className="text-sm text-gray-500">{new Date(blog.created_at).toLocaleDateString()}</p>
                                                <h4 className="font-bold">
                                                    <a href={`/blog/${encodeURIComponent(blog?.link)}`} className="hover:text-blue-500">
                                                        {blog.title}
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#FEE8E8] mt-10"></div>
            </div>
            <section>
                <ContactUs className="mt-0" />
            </section>
            <Footer />
        </>
    );
};

export default Blog;
