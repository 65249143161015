import React, { useEffect, useState } from 'react';
import AnimationDiv from '../../../../components/animation/AnimationDiv';
import sec5Logo from '../../../../assets/images/sec5_logo.svg';
import card1 from '../../../../assets/images/card1.png';
import card2 from '../../../../assets/images/card2.png';
import card3 from '../../../../assets/images/card3.png';
import arrowRight from '../../../../assets/images/arrowRight.svg';
import crown from '../../../../assets/images/crown.svg';
import { LocationCard } from '../../../../pages/MapPage';
import { getAllCities } from '../../../../api/GET';
import { LoadingOutlined } from '@ant-design/icons';

import { Spin } from 'antd';
const locations = [
    'Aventura', 'Bal Harbour', 'Bay Harbor Islands', 'Boca Raton', 'Coral Gables',
    'Fisher Island', 'Fort Lauderdale', 'Golden Beach', 'Hallandale Beach', 'Hollywood',
    'Key Biscayne', 'Miami', 'North Bay Village', 'North Miami', 'North Miami Beach',
    'Pompano Beach', 'Sunny Isles Beach', 'Surfside', 'West Palm Beach',
    'Coconut Grove', 'Design District', 'Downtown Miami', 'Edgewater', 'Upper East Side',
    'Wynwood and Midtown', 'Allison Island', 'Biscayne Point', 'Central',
    'Flamingo - Lummus', 'Historic District', 'Indian Creek Island', 'La Gorce Country Club',
    'La Gorce Island', 'Mid-Beach', 'Nautilus', 'Normandy Isles', 'North Bay Road',
    'North Beach', 'Pinetree Drive (Waterfront)', 'South Beach'
];
const images = [card1, card2, card3];

const BlueBgListView = ({ title }) => {
    const [areas, setAreas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCities = async () => {
            const data = await getAllCities();

            if (Array.isArray(data?.data?.uniqueCities)) {
                setAreas(data.data?.uniqueCities.map(v => (v)))
                setLoading(false)
            }
        }
        fetchCities()
    }, [])

    return (
        <div className='my-24 min-h-96 bg-theme_blue md:p-10  md:rounded-tr-[250px] md:rounded-bl-[250px] rounded-tr-[220px] rounded-bl-[220px] md:sec_5_bg ' >
            <div className='py-8 pb-24 md:px-4 mx-[5%]'>
                <div className='flex justify-between flex-wrap-reverse mx-[3%]'>
                    <div>
                        <AnimationDiv
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}
                        >
                            <h1 className='text-[32px] md:text-[55px] lg:text-[63px] leading-[30px] md:leading-[62px] lg:leading-[64px] text-white font-bold my-2 creato-f-b'>
                                Explore {title}
                            </h1>
                        </AnimationDiv>
                        <p className='text-white text-[16px] leading-[27px] md:text-[18px] creato-f-r max-w-[774px]'>
                            Discover Your Dream Home with Luxlife Properties
                        </p>
                    </div>
                    <div>
                        <AnimationDiv
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}
                        >
                            <img src={sec5Logo} alt="" className='max-w-[100px] md:max-w-[225px]' />
                        </AnimationDiv>
                    </div>
                </div>
                <Spin size="large" spinning={loading} indicator={<LoadingOutlined spin />}>

                <div className='max-w-[90%] gap-x-6 lg:gap-x-[100px] mx-auto mt-14 flex flex-wrap justify-between  grid grid-cols-1 lg:grid-cols-2 '>

                    {areas && areas.length !== 0 && areas.map((location, index) => (
                        <LocationCard
                            key={location}
                            locationName={location}
                            imageSrc={images[index % images.length]} // Cycle through images
                            arrowSrc={arrowRight}
                            title={title}
                            index={index}
                            />
                        ))}
                </div>
                        </Spin>

                <div className='flex justify-center mt-32'>
                    <img src={crown} alt='' />
                </div>
            </div>
        </div>
    )
}

export default BlueBgListView