import { TypeAnimation } from 'react-type-animation';

const TypeTextAnimation = ({ text, style, speed }) => {
    return (
        <TypeAnimation
            sequence={text}
            wrapper="span"
            speed={speed ?? 50}
            style={style}
            cursor={false}
        />
    );
};
export default TypeTextAnimation