import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { PopupModal } from 'react-calendly';
import Button from '../buttons/Button';
import { useData } from '../../context/DataContext';
import { toast } from 'react-toastify';

const DatePicker = ({ allData }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const { data } = useData();

    console.log(allData);
    
    const timeSlots = [
        "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30",
        "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
        "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
        "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
        "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
        "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
    ];

    const handleBookAppointment = () => {
        if (!data?.isLogin) return toast.error('Please Login to Continue');
        if (!selectedDate) return toast.warning('Please Select an Appointment Date');
        if (!selectedTime) return toast.warning('Please Select Time');

        // Open Calendly Popup
        setShowPopup(true);
    };

    const formatDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return {
            day: days[date.getDay()],
            date: date.getDate().toString().padStart(2, '0'),
            month: months[date.getMonth()],
            fullDate: date.toDateString(), // To uniquely identify each date
        };
    };

    const getDates = () => {
        const dates = [];
        for (let i = 0; i < 3; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            dates.push(formatDate(date));
        }
        return dates;
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date.fullDate);
    };

    return (
        <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-theme_blue text-white p-6 rounded-2xl mx-auto"
        >
            <h2 className="creato-f-b lg:text-[1.8vw] mb-[5%] text-center">Request a Tour</h2>
            <motion.div
                className="flex justify-between gap-2 mb-[5%]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, staggerChildren: 0.1 }}
            >
                {getDates().map((date, index) => (
                    <motion.div
                        key={index}
                        className={`text-center border-[3px] rounded-2xl creato-f-b p-[3%] min-w-[90px] lg:min-w-[6.2vw] cursor-pointer 
                                    ${selectedDate === date.fullDate ? 'bg-theme_yellow text-theme_blue' : ''}`}
                        onClick={() => handleDateSelect(date)}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'tween', stiffness: 300 }}
                    >
                        <div className="text-sm">{date.day}</div>
                        <div className="creato-f-black lg:text-[2.5vw] py-2">{date.date}</div>
                        <div className="text-sm">{date.month}</div>
                    </motion.div>
                ))}
            </motion.div>
            <div className="mb-[3%] mt-[8%]">
                <label className="block text-sm font-medium mb-1 creato-f-b">Choose a time</label>
                <select
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    className="block appearance-none w-full bg-white border border-gray-700 text-theme_blue py-[4%] px-4 pr-8 rounded-full leading-tight focus:outline-none"
                >
                    <option value="">Select</option>
                    {timeSlots.map((time, index) => (
                        <option key={index} value={time}>
                            {time}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mt-8">
                {data?.isLogin ? (
                    <Button
                        onClick={handleBookAppointment}
                        title={'Book Now'}
                        className="mx-auto py-2 px-8 text-theme_blue btn2 bg-white creato-f-b"
                    />
                ) : (
                    <div>Please login to book an appointment</div>
                )}
            </div>

            {showPopup && (
                <PopupModal
                    url="https://calendly.com/gamemaster8564"
                    prefill={{
                        name: (data?.isLogin?.first_name + ' ' + data?.isLogin?.last_name)  || '',
                        email: data?.isLogin?.email || '',
                        customAnswers: {
                            a1: `Property Link: ${window.location.href} \n Property Key: ${allData?.ListingID} \n MLS Property ID: ${allData?.ListingInfo.MLSDetails.ListingId}`,
                        },
                    }}
                    pageSettings={{
                        backgroundColor: 'ffffff',
                        textColor: '000000',
                        hideEventTypeDetails: false,
                    }}
                    onModalClose={() => setShowPopup(false)}
                    rootElement={document.getElementById('root')}
                    open={true}
                />
            )}
        </motion.div>
    );
};

export default DatePicker;
