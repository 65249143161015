import React, { useEffect, useState } from "react";
import Footer from "../components/ui/sections/FooterSection/Footer";
import Header from "../components/ui/header/Header";
import sec5logo from "../assets/images/singlePreConstruction.svg";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useData } from "../context/DataContext";
import { LoginSignUp } from "../components/auth/LoginSignup";
import { Modal } from "antd";

const MyProfile = () => {

    const { setKeyData, data } = useData()


    const [userData, setUserData] = useState(null);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        // Retrieve user data from local storage
        const data = localStorage.getItem("user_data");
        if (data) {
            setUserData(JSON.parse(data));
        }
    }, []);

    const handleChangePassword = async () => {
        if (!newPassword || !confirmNewPassword) {
            setError("Both fields are required.");
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(
                "https://www.luxlifemiamirealestate.com/api/api/User/updatePassword",
                {
                    email: userData.email,
                    current_password: currentPassword,
                    password: newPassword,
                    c_password: confirmNewPassword
                },  
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );

            if (response.data.success) {
                setSuccess("Password updated successfully.");
                setError("");
                setNewPassword("");
                setConfirmNewPassword("");
            } else {
                setError("Failed to update password. Please try again.");
            }
        } catch (error) {
            console.log(error)
            setError(error?.response.data.message || "An error occurred while updating the password.");
        }
    };

    if (!userData) {
        return <div>Loading...</div>;
    }


    const handleCancel = () => {
        setKeyData('forgetPassword', false);
      };
      const handleSetLoginModalTitle = (value) => {
        setKeyData('loginModalTitle', value);
    
      }

      
      const showModal = () => {
        setKeyData('forgetPassword', true);
    };
    return (
        <>
            <Helmet>
                <title>Profile - Luxlife Miami Real Estate LLC</title>
            </Helmet>
            <div className="relative w-full min-h-[500px] md:h-[50vh] overflow-hidden">
                <div className="fixed top-0 z-[10000] w-full">
                    <Header />
                </div>
                <img
                    src={sec5logo}
                    alt="Hero Image"
                    className="w-full absolute h-full object-cover"
                />
                <div className="absolute inset-0 gradient-background"></div>
                <div className="absolute bottom-[calc(50%-80px)] text-center md:left-6 text-white px-4 md:px-8 w-full">
                    <h1 className="text-[48px] lg:text-[4.6vw] font-extrabold mb-6">
                        Profile
                    </h1>
                </div>
            </div>

            <Modal maskClosable={false} className='rounded-[30px] p- overflow-hidden' zIndex={99999999} centered footer={false} open={data?.forgetPassword} onOk={handleCancel} onCancel={handleCancel}>
          <LoginSignUp title="forgot_password" setTitle={handleSetLoginModalTitle} handleClose={handleCancel} />
        </Modal>

            <div className="flex items-center justify-center my-6 bg-gray-50 px-4 py-12">
                <div className="bg-white shadow-lg rounded-lg w-full max-w-md p-6">
                    <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6 creato-f-r">
                        Welcome, {userData.first_name} {userData.last_name}
                    </h2>
                    <p className="text-gray-600 text-center mb-6 creato-f-r">
                        Email: <span className="font-medium">{userData.email}</span>
                    </p>

                    {userData.user_type !== "social" && (
                        <>
                            <div className="mb-4">
                                <label
                                    htmlFor="currentPassword"
                                    className="block text-sm font-medium creato-f-r text-gray-700"
                                >
                                    Current Password:
                                </label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    value={currentPassword}
                                    autoComplete="current-password"

                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-2 w-full rounded-lg'
                                    />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="newPassword"
                                    className="block text-sm font-medium creato-f-r text-gray-700"
                                >
                                    New Password:
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    autoComplete="new-password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-2 w-full rounded-lg'
                                    />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="confirmNewPassword"
                                    className="block text-sm creato-f-r font-medium text-gray-700"
                                >
                                    Confirm New Password:
                                </label>
                                <input
                                    type="password"
                                    id="confirmNewPassword"
                                    autoComplete="confirm-new-password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-2 w-full rounded-lg'
                                    />
                            </div>
                            <button
                                onClick={handleChangePassword}
                                className='w-full btn-primary py-3 bg-theme_yellow text-white rounded-lg text-nowrap px-14 font-extrabold tracking-widest' style={{ fontSize: 14 }}
                            >
                                Change Password
                            </button>
                        </>
                    )}

                    {error && (
                        <p className="text-red-500 text-sm mt-4 text-center">{error}</p>
                    )}
                    {success && (
                        <p className="text-green-500 text-sm mt-4 text-center creato-f-r">
                            {success}
                        </p>
                    )}
<div className='my-4 text-center text-[#707070]'>
                                        <p className="creato-f-r text-[15px]">Forgot your password?
                                        <span className='text-theme_yellow creato-f-m tracking-wider text-[16px] cursor-pointer' onClick={showModal} > Reset Now</span> </p></div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default MyProfile;
