import React from 'react'
import { cn } from '../../lib/utils'

const Button = ({ className, title, ...props }) => {
    return (
        <button className={cn(` btn-primary  p-4 bg-theme_yellow text-white rounded-2xl text-nowrap `, className)}

            {...props}>
            {title}
        </button>
    )
}

export default Button