import React from 'react'
import sec5Logo from '../../../../assets/images/sec5_logo.svg'
import card1 from '../../../../assets/images/card1.png'
import card2 from '../../../../assets/images/card2.png'
import card3 from '../../../../assets/images/card3.png'
import Card from '../../../cards/Card'
import Button from '../../../buttons/Button'
import AnimationDiv from '../../../animation/AnimationDiv'
import BlueBgCardView from '../blueBgSection/card-view-section'
import { Link } from 'react-router-dom'


const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Section5 = ({ propertyData,loading, toggleWishlist }) => {

    return (
        <BlueBgCardView title={'Listings'} propertyData={propertyData} loading={loading} toggleWishlist={toggleWishlist} >
            <div className='bg-white p-10 lg:px-16 flex flex-col items-center justify-center rounded-3xl mt-8 md:mt-24 text-center mx-[8%] mb-[20px]'>
                <h2 className='text-[22px] md:text-[32px] poppins-bold'>Why Choose Us?</h2>
                <p className='text-[12px] poppins-medium md:text-[20px] mt-2 creato-f-l tracking-wide xl:mx-[10%]'>At Luxlife Estates, we pride ourselves on our curated selection of properties, personalized service, and deep local knowledge. Let us guide you to your dream home
                </p>
                <Link to={'/new-100/listing'}>
                    <Button title={'View All Listings'} className={' px-8 md:px-12 mt-7 md:mt-14 md:w-[322px] poppins-semibold tracking-wider text-[16px] md:text-[23px]'} />
                </Link>
            </div>
        </BlueBgCardView>
    )
}

export default Section5