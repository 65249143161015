import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactLeft from '../../../../assets/images/contatct_bg_left.png';
import owner from '../../../../assets/images/owner_contatct.png';
import AnimationDiv from '../../../animation/AnimationDiv';
import { cn } from '../../../../lib/utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaArrowRightLong } from 'react-icons/fa6';
import { API_BASE_URL } from '../../../../config';
import axios from 'axios';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import SingleSelect from '../../../forms/select/SingleSelect';
import { MdOutlineDoneOutline } from 'react-icons/md';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';

const ContactUs = ({ className }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        topic: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [loadingS, setLoadingS] = useState(false);
    const [capValue, setCapValue] = useState(null)
    const [successfullySubmit, setSuccessfullySubmit] = useState(false)
    const [errorSubmit, setErrorSubmit] = useState(null)

    const handleSelectChange = (value) => {
        setFormData((prev) => ({
            ...prev,
            topic: value,
        }));
    };

    const options = [
        { value: 'Buying', label: 'Buying' },
        { value: 'Selling', label: 'Selling' },
        { value: 'Renting', label: 'Renting' },
        { value: 'Investing', label: 'Investing' },
        { value: 'Area Information', label: 'Area Information' },
        { value: 'Other', label: 'Other' },
    ];
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        const { name, email, phone, topic, message } = formData;
        if (!name || !email || !phone || !topic || !message) {
            handleErrorRes('All fields are required ');
            return false;
        }
        return true;
    };
    const handleSuccessRes = () => {
        setSuccessfullySubmit(true)
        setTimeout(() => {
            setSuccessfullySubmit(false)
        }, 3000)
    }
    const handleErrorRes = (error) => {
        setErrorSubmit(error)
        setTimeout(() => {
            setErrorSubmit(null)
        }, 3000)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/contactus/store', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                handleSuccessRes()
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    topic: '',
                    message: '',
                });
                setCapValue(null)
            } else {
                handleErrorRes('Failed to submit contact request. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting contact request:', error);
            handleErrorRes('An error occurred. Please try again later.');
        } finally {
            setLoading(false); // Hide loader
        }
    };
    const handleSubmitSubscribe = async () => {
        setLoadingS(true); // Hide loader
        try {
            const response = await axios.post(`${API_BASE_URL}newsletter/store`, {
                email: email, // Send the email from your state
            });

            if (response.data) {
                toast.success('Newsletter Subscribed Successfully!');
                setEmail('')
                setCheck(false)
            } else {
                console.error("Unexpected response:", response.data);
            }
        } catch (error) {
            console.error("Error storing email:", error.response ? error.response.data : error.message);
        } finally {
            setLoadingS(false); // Hide loader
        }
    };

    const [email, setEmail] = useState('')
    const [check, setCheck] = useState(false)
    const [dummyEmail, setDummyEmail] = useState('')
    return (
        <>
            {/* <ToastContainer containerId={"friendRequest"} position="bottom-right" autoClose={1000} hideProgressBar={false} /> */}
            <div className={cn(`flex mt-24 flex-wrap flex-col lg:flex-row  `, className)}>
                <div className='flex-1 hidden md:block relative bg-theme_yellow'>
                    <img src={ContactLeft} alt="" width={'100%'} height={'70%'} className='h-[100%] object-cover' />
                    <div className='absolute my-auto top-[47%] right-[40px] '>
                        <h2 className='text-6xl font-sans font-extrabold text-white'>Get in Touch.</h2>
                    </div>
                </div>
                <div className='flex-1 md:bg-theme_yellow contact_right_bg   relative'>
                    <div className='md:hidden text-start py-6 mt-12 mx-auto w-[90%]'>
                        <h2 className='text-[45px] md:text-6xl font-sans font-extrabold text-white'>Get in Touch.</h2>
                    </div>
                    <form
                        className='p-2 py-4 md:p-8 bg-white mx-auto w-[90%] lg:max-w-[30vw] md:w-[70%] lg:w-[90%] mt-11 mb-16 md:my-16 rounded-3xl'
                        onSubmit={handleSubmit}
                    >
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <input
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder='Name *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <input
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder='Email *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <input
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder='Phone *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            {/* <input
                                name="topic"
                                value={formData.topic}
                                onChange={handleChange}
                                placeholder='Topic *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            /> */}
                            {/* <select
                                name="topic"
                                value={formData.topic}
                                onChange={handleChange}
                                className='focus:outline-none placeholder:text-gray-200 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            >
                                <option value="" disabled>Select a Topic *</option>
                                <option value="Buying">Buying</option>
                                <option value="Selling">Selling</option>
                                <option value="Renting">Renting</option>
                                <option value="Investing">Investing</option>
                                <option value="Area Information">Area Information</option>
                                <option value="Other">Other</option>
                            </select> */}
                            <SingleSelect options={options} value={formData.topic}
                                onChange={handleSelectChange} clsMain={'w-full '} selectStyle={'text-black rounded-xl'} placeholder={'Select a Topic *'} />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder='Your Message *'
                                className='focus:outline-none placeholder:text-gray-400 tracking-widest border-gray-300 border p-4 w-full md:w-[60%] rounded-xl'
                                rows={10}
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <div className='flex w-full my-4 mt-[26px] gap-1 '>
                                <input id='terms2' placeholder='Topic *' type='checkbox' className='focus:outline-none tracking-widest border-gray-200 border p-4 align-top self-start rounded-xl' />
                                <label htmlFor='terms2' className='text-xs text-[#022541] creato-f-l text-[15px] tracking-wider md:w-[50%]' style={{ textAlign: 'justify' }}>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'><Link to="/privacy-policy">Privacy Policy</Link></span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices.
                                </label></div>
                        </div>
                        <div className='absolute bottom-0 right-0 hidden 1630px:block '>
                            <AnimationDiv
                                initial={{ x: '50%' }} // Start off-screen to the right
                                whileInView={{ x: '0%' }} // Slide in to the original position
                                transition={{ type: 'spring', stiffness: 260, damping: 20, duration: 1, delay: 0.1 }}
                            >
                                <img src={owner} alt="" className='max-w-[26vw] md:w-auto' />
                            </AnimationDiv>
                        </div>
                        <ReCAPTCHA sitekey='6LclkogqAAAAADEtLccey7Z9cy01VPNHtmhGvJMK' onChange={(val) => setCapValue(val)} />

                        <div className='flex w-full px-4 md:px-8 my-4 mt-[20px]'>
                            <button disabled={!capValue || loading} type="submit" className='btn-primary py-3 bg-theme_yellow text-white rounded-full text-nowrap px-14 font-extrabold tracking-widest' style={{ fontSize: 14 }}>
                                {loading ? <Spin size="small" className="text-white" /> : successfullySubmit ? <span className='flex items-center gap-2' ><IoCheckmarkDoneCircle className='text-xl' /> Submitted</span> : 'Submit'}

                            </button>
                        </div>
                        <div className='h-6 w-full md:w-[60%] '>
                            {errorSubmit ? <p className='text-red-600 text-sm tracking-wider font-bold'>{errorSubmit}</p> : <></>}
                        </div>
                    </form>
                </div>
            </div>
            <div className='flex flex-col md:flex-row p-6 flex-wrap lg:pb-[46px] lg:pt-[60px] max-w-[1500px] mx-auto'>
                <div className='flex-1 flex items-center'>
                    <p className='creato-f-black text-[22px] max-w-[537px] md:text-[30px] leading-[30px] tracking-wide text-theme_blue'>
                        Top Real Estate Insights Delivered,
                        Straight to Your Inbox.
                    </p>
                </div>
                <div className='flex-1 mt-10'>
                    <div className='max-w-[594px] mx-auto'>
                        <div className=''>

                            <div className="relative w-full">
                                <input
                                    placeholder="Enter Your Email *"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="placeholder:text-gray-500 placeholder:opacity-80 text-[19px] border border-gray-500 px-8 py-5 w-full rounded-xl placeholder:tracking-wider pr-16"
                                />
                                <button
                                    onClick={handleSubmitSubscribe}
                                    disabled={!((email !== '') && (dummyEmail == '') && (check !== false)) || loading}
                                    className="absolute inset-y-0 right-4 flex items-center px-4 my-3 rounded-full border border-gray-500"
                                >
                                    {loadingS ? <Spin size="small" className="text-white" /> : <FaArrowRightLong />}
                                </button>
                            </div>

                            {/* <input placeholder='Enter Your Email *' className='placeholder:text-gray-500 placeholder:opacity-80 text-[19px] border border-gray-500 px-8 py-5 w-full rounded-xl placeholder:tracking-wider' /> */}
                            <input style={{ display: 'none' }} placeholder='Enter Your Email *' className='placeholder:text-gray-500 placeholder:opacity-80 text-[19px] border border-gray-500 px-8 py-5 w-full rounded-xl placeholder:tracking-wider' value={dummyEmail}
                                onChange={(e) => setDummyEmail(e.target.value)} />
                        </div>

                        <div className='flex w-full my-4 mt-[26px] gap-1 '>
                            <input value={check}
                                onChange={(e) => setCheck(e.target.checked)} id='terms22' placeholder='Topic *' type='checkbox' className='focus:outline-none tracking-widest border-gray-200 border p-4 align-top self-start rounded-xl' />
                            <label htmlFor='terms22' className='text-xs text-[#022541] creato-f-l text-[15px] tracking-wider'>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'><Link to="/privacy-policy">Privacy Policy</Link></span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices. This consent isn’t necessary for purchasing any products or services and you may opt out at any time. To opt out from texts, you can reply, ‘stop’ at any time. To opt out from emails, you can click on the unsubscribe link in the emails. Message and data rates may apply.
                            </label></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
