import React from 'react';
import Footer from '../components/ui/sections/FooterSection/Footer';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import { Helmet } from 'react-helmet';

const TermsOfService = () => {
    return (
        <>
            <Helmet>
                <title>Terms of Service - Luxlife Miami Real Estate LLC</title>
            </Helmet>
            {/* Hero Section */}
            <div className="relative w-full min-h-[500px] md:h-[50vh] overflow-hidden">
                <div className="fixed top-0 z-[10000] w-full">
                    <Header />
                </div>
                <img
                    src={sec5logo}
                    alt="Hero Image"
                    className="w-full absolute h-full object-cover"
                />
                <div className="absolute inset-0 gradient-background"></div>
                <div className="absolute bottom-[calc(50%-80px)] text-center md:left-6 text-white px-4 md:px-8 w-full">
                    <div>
                        <h1 className="creato-f-m text-[48px] lg:text-[4.6vw] mb-6">
                            Terms of Service
                        </h1>
                    </div>
                </div>
            </div>

            {/* Terms of Service Content */}
            <div className="container mx-auto px-4 py-8 lg:py-12">
                <div className="rounded-lg p-6 lg:p-10 text-gray-800 mx-[5%]">
                    <h2 className="creato-f-m text-xl lg:text-2xl font-bold mb-6">
                        Effective Date: November 27, 2024
                    </h2>
                    <p className="creato-f-l mb-6">
                        Welcome to the website of Luxlife Miami Real Estate LLC (“Company,” “we,” “us,” or “our”). By accessing or using our website luxlifemiamirealestate.com (the “Site”), you agree to comply with and be bound by these Terms of Service (the “Terms”). If you do not agree with these Terms, please do not use the Site.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">1. Acceptance of Terms</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        By using this Site, you affirm that you are at least 18 years old and capable of entering into a binding agreement. If you are accessing the Site on behalf of a company or organization, you represent that you have the authority to bind that entity to these Terms.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">2. Use of the Site</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        You agree to use this Site solely for lawful purposes and in a manner consistent with these Terms and all applicable laws and regulations. Prohibited activities include, but are not limited to:
                    </p>
                    <ul className="creato-f-l list-disc pl-5 mb-6">
                        <li>Engaging in fraudulent or misleading activities.</li>
                        <li>Interfering with the security or operation of the Site.</li>
                        <li>Using automated means (e.g., bots, spiders) to access the Site without prior written consent.</li>
                    </ul>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">3. Real Estate Listings and Content</strong>
                    </p>
                    <ul className="creato-f-l list-disc pl-5 mb-6">
                        <li><strong>No Guarantee:</strong> Prices, availability, and other details are subject to change.</li>
                        <li><strong>Not a Binding Offer:</strong> The Site does not constitute an offer to sell or rent real estate.</li>
                        <li><strong>Developer Information:</strong> Certain information provided on the Site is supplied by property developers, and we are not responsible for its accuracy.</li>
                    </ul>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">4. Intellectual Property</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        The content on this Site, including text, graphics, logos, and images, is the property of Luxlife Miami Real Estate LLC or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. Unauthorized use may result in legal action.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">5. Third-Party Links</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        The Site may contain links to third-party websites for your convenience. We do not endorse or assume responsibility for their content or policies. Use them at your own risk.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">6. Disclaimer of Warranties</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        The Site and all information provided are offered “as is” and “as available” without warranties of any kind, express or implied, including but not limited to implied warranties of merchantability or fitness for a particular purpose.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">7. Limitation of Liability</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        Luxlife Miami Real Estate LLC is not liable for any damages arising from your use or inability to use the Site, or for any errors or omissions in its content.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">8. Indemnification</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        You agree to indemnify and hold harmless Luxlife Miami Real Estate LLC from any claims arising from your use of the Site or violation of these Terms.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">9. Modifications to the Terms</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        We reserve the right to modify these Terms at any time. Continued use of the Site after updates constitutes your acceptance.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">10. Governing Law</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        These Terms are governed by the laws of the State of Florida. Disputes will be resolved exclusively in Miami-Dade County, Florida.
                    </p>

                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">11. Contact Us</strong>
                    </p>
                    <address className="creato-f-r not-italic mb-6">
                        Luxlife Miami Real Estate LLC<br />
                        Email: <a href="mailto:info@luxlifemiamirealestate.com" className="text-blue-500 underline">info@luxlifemiamirealestate.com</a><br />
                        Website: <a href="https://luxlifemiamirealestate.com" className="text-blue-500 underline">luxlifemiamirealestate.com</a>
                    </address>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default TermsOfService;
