import React, { useEffect, useState } from 'react';
import Footer from '../components/ui/sections/FooterSection/Footer';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import Card from '../components/cards/Card'; // Assuming you have a Card component for displaying property data
import { API_BASE_URL } from '../config';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const SavedProperties = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProperties = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('user_data'))?.token;
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await fetch(
                'https://www.luxlifemiamirealestate.com/api/api/saved-listings',
                { headers }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch properties');
            }

            const data = await response.json();
            setProperties(data?.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchProperties();
    }, []);


    const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
      if (loading) return;
  
      setLoading(true);
  
      const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
      const headers = {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      };
  
      try {
          if (!isSaved) {
              // API call to add to saved
              setIsSaved(true); // Mark as saved
              await axios.post(
                  `${API_BASE_URL}domainparmeter/saved-property/store`,
                  { listing_id: ListingID },
                  { headers } // Pass headers with the request
              );
          } else {
              setIsSaved(false); // Mark as unsaved
              // API call to remove from saved
              await axios.delete(
                  `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
                  { headers } // Pass headers with the request
              );
          }
      } catch (error) {
          console.error('Error toggling wishlist:', error);
      } finally {
          setLoading(false);
      }
  };
  

    return (
        <>
         <Helmet>
                    <title>My Saved Properties - Luxlife Miami Real Estate LLC</title>
                </Helmet>
            <div className="relative w-full min-h-[500px] md:h-[50vh] overflow-hidden">
                <div className="fixed top-0 z-[10000] w-full">
                    <Header />
                </div>
                <img
                    src={sec5logo}
                    alt="Hero Image"
                    className="w-full absolute h-full object-cover"
                />
                <div className="absolute inset-0 gradient-background"></div>
                <div className="absolute bottom-[calc(50%-80px)] text-center md:left-6 text-white px-4 md:px-8 w-full">
                    <div>
                        <h1 className="creato-f-black text-[48px] lg:text-[4.6vw] mb-6">
                            My Saved Properties
                        </h1>
                    </div>
                </div>
            </div>

            <div className="justify-center flex-wrap xl:max-w-[80%] grid lg:grid-cols-2 min-h-[200px] xl:grid-cols-3 gap-x-4 mx-auto overflow-y-auto my-12">
                {loading && <p>Loading properties...</p>}
                {error && <p className="text-red-500">{error}</p>}
                {!loading && !error && properties.length === 0 && (
                    <p>No saved properties found.</p>
                )}
                {properties && properties.length > 0 && properties.map((property, index) =>
                    property?.ListingID ? (
                        <div key={index} className="lg:w-full">
                            <Card toggleWishlist={toggleWishlist}
                                className="lg:w-full shadow-lg"
                                property_url={true}
                                data={property}
                            />
                        </div>
                    ) : null
                )}
            </div>

            <Footer />
        </>
    );
};

export default SavedProperties;
