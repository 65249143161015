import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        propertyType: [],
        isLogin: null,
        isLoginModalOpen: false,
        loginModalTitle: 'Sign in',
        forgetPassword:false,
        autoSearchList: []
    });

    const setKeyData = (key, value) => {
        setData(prevData => ({ ...prevData, [key]: value }));
    };

    return (
        <DataContext.Provider value={{ data, setKeyData, }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};