import { Slider } from 'antd';
import React, { useState } from 'react';
import Button from '../buttons/Button';
import { useNavigate } from 'react-router-dom';

// Custom Tabs component
const Tabs = ({ tabs, activeTab, setActiveTab }) => {
    const navigate = useNavigate();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`/new-100/${tab?.tab}`, { replace: true })

    }
    return (
        <div className="flex  mb-4 overflow-x-auto">
            {tabs.map((tab, index) => (
                <button
                    key={index}
                    onClick={() => handleTabChange(tab)}
                    className={`relative px-4 text-nowrap py-2 flex-1 lg:text-[1vw] text-theme_blue md:tracking-wide leading-[34px] creato-f-m transition-all duration-300 ${activeTab?.tab === tab?.tab
                        ? 'before:content-[""] before:absolute before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:h-[4px] before:bg-yellow-500 before:transition-all before:duration-300 before:ease-in-out before:w-full'
                        : 'before:content-[""] before:absolute before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-[4px] before:bg-yellow-500 before:transition-all before:duration-300 before:ease-in-out'
                        }`}
                >
                    {tab.label}
                </button>

            ))}
        </div>
    )
};

// Custom Price Range Slider component
const PriceRangeSlider = ({ onChange, value }) => {
    const formatter = (value) => {
        return value === 5000001 ? Number(value - 1).toLocaleString() + '+' : value.toLocaleString();
    };


    return (
        <div className="px-4 flex-1 py-2">
            <div className="w-full min-w-[200px] max-w-[500px]">
                <h3 className="text-center creato-f-r mx-auto text-theme_grey2 text-sm md:text-[18px]">Price Range:</h3>
                <Slider
                    range={{
                        draggableTrack: true,
                    }}
                    handleColor='#E4A231'
                    tooltip={{
                        formatter,
                    }}
                    value={value}
                    min={1000000}
                    max={5000001}
                    step={250000}
                    styles={{
                        track: {
                            background: 'transparent',
                        },
                        tracks: {
                            background: `#E4A231`,
                            height: '5px'
                        },
                    }}
                    onChange={onChange}
                />
                <p className="text-center creato-f-b text-theme_blue text-sm md:text-[18px] mx-auto">
                    ${value[0]?.toLocaleString()} - ${value[1] === 5000001 ? (Number(value[1]) - 1).toLocaleString() : value[1].toLocaleString()}{value[1] === 5000001 && '+'}
                </p>
            </div>
        </div>
    );
};

// Custom Area Selection component
const AreaSelection = ({ areas, onSelectArea }) => (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-2 mb-4 lg:mr-8">
        {areas.map((area, index) => (
            <button
                key={index}
                onClick={() => onSelectArea(index)}
                className={`text-left ${area.selected ? 'text-yellow-500' : 'text-theme_grey2'
                    } hover:text-yellow-500 transition duration-200`}
            >
                <p className="segoeui font-semibold lg:text-[1vw] text-nowrap">
                    {area.selected ? (
                        <span className="flex items-center  h-[1.2%]">
                            <span className="text-bold text-4xl pb-[10px] leading-[20px] mr-1 min-w-[15px]">-</span>
                            <span> {area.name}</span>
                        </span>
                    ) : (
                        <span className="flex items-center   h-[1.2%] ">
                            <span className="text-bold text-lg  mr-1 min-w-[15px]">+</span>
                            <span>{area.name}</span>
                        </span>
                    )}
                </p>
            </button>
        ))}
    </div>
);

// Custom Search Section component
const SearchSection = ({ onSearch }) => (
    <div className="flex justify-center py-4">
        <button
            className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded"
            onClick={onSearch}
        >
            Search
        </button>
    </div>
);

const ListingsUI = ({ setAreas, areas, tabs, setActiveTab, activeTab, handleSearchFilter, priceRange, setPriceRange, paramsTab }) => {


    const handlePriceChange = (values) => {
        setPriceRange(values);
    };

    const handleSelectArea = (index) => {
        const updatedAreas = areas.map((area, idx) =>
            idx === index ? { ...area, selected: !area.selected } : area
        );
        setAreas(updatedAreas);
    };

    const handleSearch = () => {
        const selectedAreas = areas.filter((area) => area.selected).map((area) => area.name);
        console.log('Search with Price Range:', priceRange, 'Selected Areas:', selectedAreas);
    };

    return (
        <div className="p-7 xl:max-w-[80%] mx-auto">
            {paramsTab != 'map-filter' && <>
                <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className="p-4 lg:max-w-[47vw] text-theme_grey2 text-center mx-auto md:text-[1vw] segoeui md:leading-[1.2vw]">{activeTab?.content}</div>
            </>}
            <div className=" p-[5%] shadow_c_1 mt-8">
                <p className='mx-auto text-theme_grey2 segoeui text-[17px] text-center'>Select one or more areas below:</p>
                <div className='flex justify-between mt-6 flex-col md:flex-row'>

                    <PriceRangeSlider min={priceRange[0]} max={priceRange[1]} value={priceRange} onChange={handlePriceChange} />
                    <div className='mt-8 md:mt-0 ml-[2%]'>
                        <AreaSelection areas={areas} onSelectArea={handleSelectArea} />
                        <Button onClick={handleSearchFilter} title={'Search'} className={'px-14 rounded-md tracking-wide py-3 creato-f-r text-[18px]'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingsUI;
