import React, { useEffect, useRef } from 'react';

const GoogleLogin = ({ onSuccess, onError }) => {
    const googleButton = useRef(null);

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            script.onload = initializeGoogleButton;
        };

        const initializeGoogleButton = () => {
            if (window.google && googleButton.current) {
                window.google.accounts.id.initialize({
                    client_id: '851059041028-5iqlqneh9qom6cd0cd7r80d5oilit1fd.apps.googleusercontent.com',
                    callback: handleCredentialResponse,
                });

                window.google.accounts.id.renderButton(googleButton.current, {
                    theme: 'outline',
                    size: 'large',
                    type:'standard',
                });

                // const button = googleButton.current.querySelector('div');
                // if (button) {
                //     button.style.width = '100%';
                // }

            }
        };

        loadGoogleScript();

        return () => {
            // Cleanup: remove the script when the component unmounts
            const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    const handleCredentialResponse = (response) => {
        if (response.credential) {
            // Send the token to your server for verification
            // You can use the onSuccess callback to handle this in the parent component
            onSuccess(response.credential);
        } else {
            onError('Google login failed');
        }
    };

    return (

        <div className='google-login-button' style={{ display: "flex", justifyContent: "center" }} ref={googleButton}></div>

    );
};

export default GoogleLogin;